import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RegularSchedule from "./RegularSchedule";
import UIContext, { setRegularScheduleVisible } from "../../../context/ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import MainContext from "../../../context/main";

const Container = styled.div<{ isMobile: boolean }>`
  overflow-y: ${(props) => (props.isMobile ? "auto" : "inherit")};
  overflow-x: ${(props) => (props.isMobile ? "hidden" : "inherit")};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  height: 100%;
  .MuiInput-root {
    width: 100%;
    border: 1px solid #d3d9de;
    border-radius: 4px;
  }
  .MuiNativeSelect-root {
    padding: 0.6em 1em;
  }
  .MuiInputBase-root:before {
    border-bottom: none;
  }
  padding: ${(props) => (props.isMobile ? "0 1em" : "0 2em")};
  color: ${(props) => props.theme.fontColors.primary};
`;

const ContainerSeccion = styled.div<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? "27px 0 27px 0" : "27px 0 40px 0")};
`;

const Title = styled.div<{ isMobile: boolean }>`
  font-weight: bold;
  font-size: ${(props) => (props.isMobile ? "initial" : "20px")};
  color: ${props => props.theme.fontColors.primary};  
`;

const ContainerDesc = styled.div<{isMobile: boolean}>`
    margin-left: ${props => props.isMobile ? "0": "40%"}; 
    margin-top:  ${props => props.isMobile ? "5px": "8px"}; 
`;

type MenuScheduleProps = {showFrecuent : boolean}

MenuSchedule.defaultProps = {};

export default function MenuSchedule(props: MenuScheduleProps) {
  const { t } = useTranslation();
  const main = useContext(MainContext)!;
  const [state, dispatch] = useContext(UIContext)!;
  const [showFrequent, setShowFrequent] = useState<boolean>(
    state.regularScheduleVisible
  );

  const checkFrequent = function () {
    main.setNeedRefresh(true);

    if (showFrequent) {
      main.weekPointService.setWeekPoints([], 0);
    }
    setShowFrequent(!showFrequent);
    dispatch(setRegularScheduleVisible(!showFrequent));
  };

  useEffect(() => {
    setShowFrequent(props.showFrecuent);
  }, [props.showFrecuent]);
    return (
        <Container isMobile={state.isMobile}>
            <ContainerSeccion isMobile={state.isMobile}>
                <Title isMobile={state.isMobile}>{t('calendar_schedule_3_title', 'Disponibilidad')}</Title>
                <ContainerDesc isMobile={state.isMobile}>
                    {showFrequent ?
                            <RegularSchedule  />
                        : null
                    }
                </ContainerDesc>
            </ContainerSeccion>
        </Container>
    )
}

