import ParentService, {ParentServiceListener} from "../parent";
import 'moment/locale/es';
import API from "../api/api";

export class OnBoardingService extends ParentService{

    getShowModal(){
        let that = this;
        API.getShowModalOnboarding().then((json: any) => {
            that.publishOnGetShowModal(json);
            that.setSeenModal();
        }).catch(error => {
            //todo
        })
    }

    setSeenModal(){
        let that = this;
        API.setSeenModalOnboarding().then((json: any) => {
            that.publishOnSetSeenModal();
        }).catch(error => {
            //todo
        })
    }

    /** @ignore Publish documents loaded event to all elements subscribed. */
    protected publishOnGetShowModal(canShow: boolean){
        this.subscribers.forEach(function (listener) { (listener as OnBoardingEventListener).onGetShowModal?.(canShow); })
    }

    /** @ignore Publish documents loaded event to all elements subscribed. */
    protected publishOnSetSeenModal(){
        this.subscribers.forEach(function (listener) { (listener as OnBoardingEventListener).onSetSeenModal?.(); })
    }
}

export interface OnBoardingEventListener extends ParentServiceListener{
    /** Notifies when absences has been loaded. */
    onGetShowModal?(canShow: boolean): void;
    /** Notifies when absence has been added. */
    onSetSeenModal?(): void;
}
