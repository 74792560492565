import ParentService, {ParentServiceListener} from "../parent";
import 'moment/locale/es';
import API from "../api/api";

export class BookingService extends ParentService{

    addBooking(tzo: number, tutorId: number, ticks: number){
        let that = this;

        API.addBooking(tzo, tutorId, ticks).then((json: any) => {
            let id: number = json["data"];
            that.publishOnBookingAdded(id);
        }).catch(error => {
            //todo
        })
    }

    /** @ignore Publish documents loaded event to all elements subscribed. */
    protected publishOnBookingAdded(id: number){
        this.subscribers.forEach(function (listener) { (listener as BookingEventListener).onBookingAdded?.(id); })
    }
}

export interface BookingEventListener extends ParentServiceListener{
    
    /** Notifies when absence has been added. */
    onBookingAdded?(id: number): void;
}
