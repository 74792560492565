import React, {useCallback, useEffect, useState} from "react";
import styled from 'styled-components';
import Icon from "../icons/Icon";
import UIContext from "../../../context/ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import SimpleBtn from "../buttons/SimpleBtn";
import MainContext from "../../../context/main";
import DialogInfo from "../dialog/Dialog";
import { AbsencesEventListener } from "../../../services/absence/absence";
import { Absence } from "../../../types/absence";
import ModalAddAbsence from "../modals/absence/AddAbsenceModal";
import ModalDeleteAbsence from "../modals/delete/EventDeleteAbsence";
import {Config} from "../../../constants/config";

const Container = styled.div<{isMobile: boolean}>`
    overflow-y:  ${props => props.isMobile ? "auto" : "inherit"};
    ::-webkit-scrollbar {
        display: none;
      }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    padding: 0 1em;  
    color: ${props => props.theme.fontColors.primary};
`;

const ContainerBody = styled.div`
    margin-top: 3em;
`;

const IconWrapper = styled.div`
    width: 48px;
    margin: 2.5em auto 0;
    > svg{
    fill: ${props => props.theme.iconWrapper.absence.svg.color + " !important"};
    stroke: ${props => props.theme.iconWrapper.absence.svg.color  + " !important"};
    }

  :hover > svg{
    fill: ${props => props.theme.iconWrapper.absence.svg.hoverColor + " !important"};
    stroke: ${props => props.theme.iconWrapper.absence.svg.hoverColor  + " !important"};
    }
`;

const IconWrapperActiveAbsence = styled.div`
    position:relative;
    margin-left: auto;
    width: 16px;
    display: inline-block;
    cursor:pointer;
`;

const IconWrapperInfo = styled.div`
    position:relative;
    margin-left: auto;
    display: inline-block;
    cursor:pointer;    
`;

const IconWrapperCalendar = styled.div`
    width: 48px;
    margin-right: 1.5em;
    > svg{
        fill: ${props => props.theme.iconWrapper.absence.svg.color + " !important"};
        stroke: ${props => props.theme.iconWrapper.absence.svg.color  + " !important"};
    }

    :hover > svg{
        fill: ${props => props.theme.iconWrapper.absence.svg.hoverColor + " !important"};
        stroke: ${props => props.theme.iconWrapper.absence.svg.hoverColor  + " !important"};
    }
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
`;

const TitleAnyAbsence = styled.div`
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    text-align: left;
    margin-bottom: 0.75em;    
`;

const Desc = styled.div <{isMobile: boolean}>`
    font-size: ${props=>props.theme.fontSizes.s};
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-top: ${props=>props.isMobile?'1em':'2em'};
`;

const BtnWrapper = styled.div`
    margin: 3em auto 0;
    width: max-content;
    > div{
        padding: 0.25em 0;
        > div{
            padding: 0 1.5em;
        }
    }
    p{
        padding-bottom: 0 !important;
        padding-right: 1.5em !important;
    }
`;

const HeaderAbsences = styled.div`
    padding: 1em 1.5em;
    border: 1px solid #D3D9DE;
    display: flex;
    vertical-align: middle;
`;

const Counter  = styled.div`
    font-size: 20px;
    font-weight: bold;
`;

const WrapperAny  = styled.div`
    margin-top: 1em;
`;

const WrapperLeft  = styled.div`
`;

const WrapperBtn  = styled.div<{isMobile: boolean}>`
    height: 2.5em;
    p{
        padding-left: ${props=>props.isMobile?'1.5em':'inherit'} !important;
        padding-bottom: 0 !important;
        padding-right: 1.5em !important;
    }
`;
const WrapperRight  = styled.div`
    width: max-content;
    height: 2.5em;
    margin: auto 0 auto auto;
`;

const ActiveAbsences  = styled.div<{isMobile: boolean}>`
    margin-top: ${props=>props.isMobile?'0.5em':'2.5em'};     
    border: 1px solid #D3D9DE;
    border-radius: 4px;
`;

const ActiveAbsencesHeader  = styled.div`
    display:flex;
    background-color: ${props => props.theme.activeAbsencesHeader.background};
    padding: 1em;
    font-weight: bold;     
`;

const ActiveAbsenceLeft  = styled.div`
`;

const ActiveAbsenceTitle  = styled.div`
    font-weight:bold;
    font-size: 1rem;

`;

const ActiveAbsenceDate  = styled.div`
    font-size: 0.875rem;
    margin-top: 0.4em;
`;

const ActiveAbsenceRight  = styled.div`
    display: inline-flex;
    margin-left: auto;

    > div:first-child{
        margin-right: 1.5em;
    }
    > div{
        margin-right: 1em;
    }
`;

const ActiveAbsence  = styled.div`
    padding: 1em;
    display: flex;
`;

const Separator   = styled.div`
    border-top: 1px solid #D3D9DE;
    margin: 0 auto;
    width: calc(100% - 1.5em);
`;

const CountAbsences   = styled.div`
    font-size:0.875rem
`;

type MenuAbsenceProps = {}

MenuAbsence.defaultProps = {};

export default function MenuAbsence(props: MenuAbsenceProps){
    const main = useContext(MainContext)!;
    const {t} = useTranslation();
    const [state] = useContext(UIContext)!;
    const [theme] = useState(Config.get().THEME);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openDeleteModal, setDeleteModal] = useState<boolean>(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [lastTitle, setLastTitle] = useState<string>('false');
    const [lastId, setLastId] = useState<string>('');
    const [lastDate, setLastDate] = useState<string>('false');

    const [lastRangeEdit, setLastRangeEdit] = useState<Date[]>([new Date(), new Date()]);
    const [absences, setAbsences] = useState<Absence[]>([]);
    const [timeZoneOffset] = useState<number>(new Date().getTimezoneOffset());

    const _load = useCallback(() => {
        main.absenceService.getAbsences(false);
    }, [main.absenceService]);

    useEffect(() => {
        let listener = new class implements AbsencesEventListener {
            onAbsencesLoaded(_absences: Absence[]): void {
                //setLoading(false);
                setAbsences([..._absences]);
            }
        }();
        main.absenceService.subscribeToEvents(listener);
        _load();
        // Unbind the listener when the component unmounts
        return () => {
            main.absenceService.unSubscribeToEvents(listener);
        }
    }, [main.absenceService, _load]);

    const _openModal = function () {
        setOpenModal(true);
    };
    const _editAbsence = function (initDate:Date, endDate:Date, title:string, id:string) {
        setLastRangeEdit([new Date(initDate), new Date(endDate)]);
        setLastTitle(title);
        setEditModal(true);
        setLastId(id);
    };

    const _deleteAbsence = function (title:string, date:string, id:string) {
        setLastTitle(title);
        setLastDate(date);
        setDeleteModal(true);
        setLastId(id);
    };
    const renderAbsences = function () {

        return(absences.map(function(absence, i){
            let endDate = new Date(absence.endDate);
            endDate.setMinutes(endDate.getMinutes() + timeZoneOffset);
            return(
                <div key={i}><ActiveAbsence>
                    <ActiveAbsenceLeft>
                        <ActiveAbsenceTitle>{absence.title}</ActiveAbsenceTitle>
                        <ActiveAbsenceDate>{main.calendarService.getDateFormatted(absence.initDate,'DD[-]MM[-]YY')} al  {main.calendarService.getDateFormatted(endDate,'DD[-]MM[-]YY')}</ActiveAbsenceDate>
                    </ActiveAbsenceLeft>
                    <ActiveAbsenceRight>
                        <IconWrapperActiveAbsence onClick={()=>_editAbsence(absence.initDate, absence.endDate, absence.title,
                            absence.id)}>
                            <Icon  name={'edit'} disableFill={false}/>
                        </IconWrapperActiveAbsence>
                        <IconWrapperActiveAbsence onClick={()=>_deleteAbsence(absence.title,
                            main.calendarService.getDateFormatted(absence.initDate,'DD[-]MM[-]YY') + ' al ' + main.calendarService.getDateFormatted(new Date(absence.endDate),'DD[-]MM[-]YY'),
                            absence.id)}>
                            <Icon  name={'delete'} disableFill={false}/>
                        </IconWrapperActiveAbsence>
                    </ActiveAbsenceRight>
                </ActiveAbsence>
                    {absences[i + 1] ?
                        <Separator/>
                        : null
                    }
                </div>)
        }))
    };
    const _renderModal = function () {
        return(<ModalAddAbsence open={true} handleClose={()=>{setOpenModal(false);}} handleAdded={_load} />)
    };

    const _renderEditModal = function () {
        return(<ModalAddAbsence open={true} handleClose={()=>{setEditModal(false);}} handleAdded={_load} date={lastRangeEdit} title={lastTitle} id={lastId} />)
    };

    const _renderDeleteModal = function () {
        return(<ModalDeleteAbsence title={lastTitle} dateStr={lastDate} open={true}
                                        id={lastId}
                                        handleClose={()=>setDeleteModal(false)}
                                        handleDelete={_load} /> )
    };

    return (
        <Container isMobile={state.isMobile}>
            {absences?.length > 0 ?
                <WrapperAny>
                    {!state.isMobile
                        ? <TitleAnyAbsence>{t("calendar_absence_active","Ausencias activas")}</TitleAnyAbsence> : null}

                    <HeaderAbsences>
                        {!state.isMobile ?
                            <IconWrapperCalendar>
                                <Icon  name={'calendar_empty_state'} disableFill={theme.name ==="tus"}/>
                            </IconWrapperCalendar>
                            : null
                        }
                        <WrapperLeft>
                            <CountAbsences>{t("calendar_absences","Ausencias")}</CountAbsences>
                            <Counter>{absences.length}</Counter>
                        </WrapperLeft>
                        <WrapperRight>
                            <WrapperBtn isMobile={state.isMobile}>
                                <SimpleBtn style={theme.buttonColors.primary.selected}
                                           title={state.isMobile ? t("calendar_add","Añadir") : t("calendar_add_absence","Añadir ausencia")}
                                           collapsible={false}
                                           smallIcon={true}
                                           clicked={_openModal}
                                           icon={state.isMobile ? undefined : "white_calendar"}/>
                            </WrapperBtn>
                        </WrapperRight>
                    </HeaderAbsences>
                    <ActiveAbsences isMobile={state.isMobile}>
                        <ActiveAbsencesHeader>
                            <span>{t("calendar_absence_active","Ausencias activas")}</span>
                            <IconWrapperInfo >
                                <DialogInfo  open={true} text={'Añade ausencias en tu calendario y aparecerá ocupado durante el tiempo indicado. Recuerda que durante esos días tu perfil no aparecerá en el listado de profesores.'} />
                            </IconWrapperInfo>
                        </ActiveAbsencesHeader>
                        {renderAbsences()}
                    </ActiveAbsences>
                </WrapperAny>
                :
                <div>
                    <IconWrapper>
                        <Icon  name={'calendar_empty_state'} disableFill={theme.name ==="tus"}/>
                    </IconWrapper>
                    <ContainerBody>
                        <Title>{t("calendar_no_absence_title","No tienes ausencias programadas")}</Title>
                        <Desc isMobile={state.isMobile}>
                            <div>{t("calendar_no_absence_desc_1","Añade ausencias en tu calendario y aparecerá ocupado durante el tiempo indicado.")}</div>
                            <div>{t("calendar_no_absence_desc_2","Recuerda que durante esos días tu perfil no aparecerá en el listado de profesores.")}</div>
                        </Desc>

                        <BtnWrapper>
                            <SimpleBtn style={theme.buttonColors.primary.selected}
                                       title={t("calendar_no_absence_add","Añadir una ausencia")}
                                       collapsible={false}
                                       smallIcon={true}
                                       clicked={_openModal}
                                       icon={"white_calendar"}/>
                        </BtnWrapper>
                    </ContainerBody>
                </div>
            }
            {openModal ? _renderModal() : null}
            {openDeleteModal ? _renderDeleteModal(): null}
            {editModal ? _renderEditModal(): null}
        </Container>
    )
}