import React, {useContext, useEffect, useState} from "react";
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import UIContext from "../../../../context/ui";
import SimpleBtn from "../../buttons/SimpleBtn";
import {OnBoardingEventListener} from "../../../../services/onboarding/onboarding";
import MainContext from "../../../../context/main";
import { Modal } from "@mui/material";
import {Config} from "../../../../constants/config";

const Content = styled.div<{visible: boolean, isMobile: boolean}>`
    bottom: ${props => props.visible ? "0" : "-500px"};
    left: 50%;
    width: ${props => props.isMobile ? "calc(100% - 2em)" : "auto"};
    top: 50%;
    transform:translate(-50%, -50%);
    height: fit-content;
    position: absolute;
    border: none !important;
    background-color: white;
    border-radius: 5px;

    outline: none;
    padding:${props => props.isMobile ? "0 0 1em" : "0 0 2em"};
    transition: bottom 250ms;
    color: ${props => props.theme.fontColors.primary};
    min-width:${props => props.isMobile ? "initial" : "360px"};
    max-width:570px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    background: ${props => props.theme.fontColors.accent};
    border-radius: 5px 5px 0px 0px;
    height:0.5em;
`;

const Inner = styled.div<{isMobile: boolean}>`
    display: flex;
    flex-direction: column;
    padding-left:${props=>props.isMobile?"3em":"6.38rem"};
    padding-right:${props=>props.isMobile?"1em":"5em"};
    margin-top: 2em;
`;

const Title = styled.div`
    font-size: 1.25rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    text-align: left;
`;

const TitleSection = styled.div`
    font-weight:bold;
    text-align: left;
    letter-spacing: 0;
    line-height: 24px;
`;

const BtnDiv = styled.div<{isMobile: boolean}>`
    display:${props=>props.isMobile?"block":"inline-flex"};
    margin:${props=>props.isMobile?"1em 1em":"0 0.5em"};
    >div{
        width:${props=>props.isMobile?"calc(100% - 3em)":"fit-content"};
        padding: 0.75em 1.5em;
    }
`;

const Info = styled.div<{isMobile: boolean}>`
    text-align: left;
    margin: inherit;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 2em;
    position: relative;
    font-size:${props=>props.isMobile?"0.875rem":"1rem"};
`;

const Footer = styled.div<{isMobile: boolean}>`
    margin-top:${props=>props.isMobile?"2em":"3em"};
    padding-left: ${props=>props.isMobile?"0":"4.06rem"};
`;

const Icon = styled.div`
    position: absolute;
    left: -30px;
`;

const Desc = styled.div`
`;

type ModalOnBoardingProps = {open: boolean, handleClose?: any, changeTab?: any }

ModalOnBoarding.defaultProps = {open: false, handleClose: null, changeTab:null};

export default function ModalOnBoarding(props: ModalOnBoardingProps){
    const {t} = useTranslation();
    const [state] = useContext(UIContext)!;
    const [theme] = useState(Config.get().THEME);
    const main = useContext(MainContext)!;
    const [open, setOpen] = useState(props.open);
    const [contentVisible, setContentVisible] = useState(false);

    useEffect(()=>{
        let listener = new class implements OnBoardingEventListener{
            onGetShowModal(show: boolean) {
                if(show){
                    setOpen(true)
                }
            }
        }();
        main.onboardingService.getShowModal();
        main.onboardingService.subscribeToEvents(listener);
        return () => {
            main.onboardingService.unSubscribeToEvents(listener);
        }
    }, [main.onboardingService]);


    const goToRegularSchedule = function () {
        setContentVisible(false);
        setOpen(false);
        if (props.changeTab) {
            props.changeTab(); // Llama a changeTab solo si está definida
          }
        props.handleClose();
    };

    const _close = function () {
        setContentVisible(false);
        setOpen(false);
        props.handleClose();
    };

    useEffect(()=>{
        if(!open) {return}
        setTimeout(()=>{
            setContentVisible(open);
        }, 10)
    }, [open]);

    return (
        <Modal open={open} onClose={_close}>
            <Content visible={contentVisible} isMobile={state.isMobile}>
                <Header>

                </Header>
                <Inner isMobile={state.isMobile}>
                    <Title>{t('calendar_onboarding_modal_title', '¡Novedades en el calendario! ')}</Title>
                    <Info isMobile={state.isMobile}>
                        <Icon>⏱️</Icon>
                        <TitleSection>{t('calendar_onboarding_modal_info_1', 'Habilita o confirma tu horario frecuente')}</TitleSection>
                        <Desc>{t('calendar_onboarding_modal_info_desc_1', 'Así los estudiantes sabrán los días y las horas en las que sueles estar disponible.')}</Desc>
                    </Info>
                    <Info isMobile={state.isMobile}>
                        <Icon>✏️</Icon>
                        <TitleSection>{t('calendar_onboarding_modal_info_2', 'Crea, edita o elimina eventos de tu día a día')}</TitleSection>
                        <Desc>{t('calendar_onboarding_modal_info_desc_2', 'Tu calendario actualizado. Ahora puedes añadir o eliminar disponibilidad en días sueltos.')}</Desc>
                    </Info>
                    <Info isMobile={state.isMobile}>
                        <Icon>⛱️</Icon>
                        <TitleSection>{t('calendar_onboarding_modal_info_3', '¿Vacaciones o unos días fuera?')}</TitleSection>
                        <Desc>{t('calendar_onboarding_modal_info_desc_3', 'Podrás crear y editar ausencias de uno o más días que bloquearán tu disponibilidad de cara a tus alumnos.')}</Desc>
                    </Info>
                </Inner>

                <Footer isMobile={state.isMobile}>

                    <BtnDiv isMobile={state.isMobile}>
                        <SimpleBtn style={theme.buttonColors.accent.unselected}
                                   title={t('calendar_onboarding_modal_add', 'Añadir mi horario frecuente')}
                                   collapsible={false}
                                   smallIcon={false}
                                   clicked={goToRegularSchedule} />
                    </BtnDiv>
                </Footer>
            </Content>
        </Modal>
    )
}