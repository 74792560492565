import React, { useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MainContext from "../../../context/main";
import UIContext, { setMenuVisible } from "../../../context/ui";
import Icon from "../icons/Icon";
import { TimeZone } from "../../../types/timezone";
import { TutorEventListener } from "../../../services/tutor/tutor";
import Checkbox from "../checkbox/Checkbox";
import { NativeSelect } from "@mui/material";

const Container = styled.div<{ open: boolean, isMobile: boolean }>`
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: ${(props) => (props.open ? (props.isMobile ? "930px" : "900px") : "0")};
  width: ${(props) => (props.open ? "100%" : "0")};
  display: ${(props) => (props.open ? "block" : "none")};
`;

const Content = styled.div<{ visible: boolean }>`
  top: 0;
  bottom: 0;
  right: ${(props) => (props.visible ? "0" : "-2000px")};
  width: 100%;
  position: absolute;
  border: none !important;
  background-color: white;
  border-radius: 5px 5px 0 0;
  outline: none;
  padding: 0 0 5em;
  transition: right 150ms;
`;

const ContainerBody = styled.div`
  width: 100%;
  color: ${(props) => props.theme.fontColors.primary};
`;

const Back = styled.div<{ isMobile: boolean }>`
  display: inline-block;
  vertical-align: middle;
  width: 28px;
  position: ${(props) => (props.isMobile ? "absolute" : "inherit")};
  left: 13px;
  cursor: pointer;
`;

const TitleHeader = styled.div<{ isMobile: boolean }>`
  margin-left: ${(props) => (props.isMobile ? "18px" : "0.75em")};
  display: inline-block;
  font-size: ${(props) => (props.isMobile ? "16px" : "24px")};
  font-weight: ${(props) => (props.isMobile ? "inherit" : "bold")};
  letter-spacing: 0;
  line-height: 24px;
`;

const Header = styled.div<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? "15px" : "1.5em")};
  width: ${(props) =>
    props.isMobile ? "calc(100% - 30px)" : "calc(100% - 3em)"};
  border-bottom: 1px solid ${(props) => props.theme.fontColors.soft};
  text-align: ${(props) => (props.isMobile ? "center" : "initial")};
`;

const ContainerSections = styled.div<{isMobile: boolean}>`
    overflow-y:  ${props => props.isMobile ? "auto" : "inherit"};
    overflow-x:  ${props => props.isMobile ? "hidden" : "inherit"};
    ::-webkit-scrollbar {
        display: none;
      }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    height: 100%;
    .MuiInput-root{
        width:100%;
        border: 1px solid #D3D9DE;
        border-radius: 4px;
    }
    .MuiNativeSelect-root {
        padding: 0.6em 1em;
    }
    .MuiInputBase-root:before{
        border-bottom:none
    }
    padding:  ${props => props.isMobile ? "0 1em" : "0 2em"};
    color: ${props => props.theme.fontColors.primary};
`;

const ContainerSeccion = styled.div<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? "27px 0 27px 0" : "27px 0 40px 0")};
`;

const Title = styled.div<{ isMobile: boolean }>`
  font-weight: bold;
  font-size: ${(props) => (props.isMobile ? "initial" : "20px")};
  color: ${props => props.theme.fontColors.primary};
`;

const Desc = styled.div`
  font-size: 16px;
`;

const Tip = styled.div`
  font-size: 14px;
  color: #49545f;
  line-height: 19px;
  width: calc(100% - 19px);
  margin-left: 19px;
`;

const ContainerDesc = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.isMobile ? "0" : "40%")};
  margin-top: ${(props) => (props.isMobile ? "5px" : "8px")};
  gap: 1em;
`;

const Comment = styled.div`
  font-size: 14px;
  color: #49545f;
  line-height: 19px;
`;

const SelectContainer = styled.div`
  margin-top: 16px;

  select {
    letter-spacing: 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border-bottom: none;
  }

  .MuiInputBase-root.Mui-focused {
    border: 1px solid ${(props) => props.theme.fontColors.primary};
    outline: none;
  }
`;

const IconWrapperDropDown = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  right: 6px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  width: 14px;
  position: absolute;
`;

const TipWrapper = styled.div`
  position: relative;
  margin-top: 20px;
`;

const Separator = styled.div`
  border-top: 1px solid #ececec;
`;

const Option = styled.option``;

class minMinutes {
  public minutes: number;
  public display: string;
  constructor() {
    this.minutes = 0;
    this.display = "0";
  }
}

type _MenuProps = {
  showRegularSchedule: boolean;
  open: boolean;
  dispatch: any;
};

_Menu.defaultProps = {};

function _Menu(props: _MenuProps) {
  const main = useContext(MainContext)!;
  const { t } = useTranslation();
  const [contentVisible, setContentVisible] = useState(false);
  const [timeZoneOffset] = useState<Number>(new Date().getTimezoneOffset());
  const [state] = useContext(UIContext)!;
  const [minMinutes, setMinMinutes] = useState<number>(0);
  const [timeZone, setTimeZone] = useState<string>();
  const [timeZones, setTimeZones] = useState<TimeZone[]>();
  const [noNewStudents, setNoNewStudents] = useState<boolean>(false);
  const [minMinRows] = useState<minMinutes[]>([
    { minutes: 30, display: "30" },
    { minutes: 60, display: "1" },
    { minutes: 120, display: "2" },
    { minutes: 360, display: "6" },
    { minutes: 720, display: "12" },
    { minutes: 1440, display: "24" },
    { minutes: 2880, display: "48" },
    { minutes: 4320, display: "72" },
  ]);

  const _close = function () {
    //aqui volvemos a cargar el calendario en  el caso de que el horario frecuente cambie
    main.calendarService.refresh();
    if (main.needRefresh) {
      main.setNeedRefresh(false);
      main.weekPointService.getWeekPointsSelectHour(timeZoneOffset, true);
    }
    setContentVisible(false);
    setTimeout(() => {
      props.dispatch(setMenuVisible(false));
    }, 150);
  };

  useEffect(() => {
    if (!props.open) {
      return;
    }
    setTimeout(() => {
      setContentVisible(true);
    }, 10);
  }, [props.open]);

  const back = function () {
    _close();
  };

  const saveMinMinutes = function (minutes: number) {
    main.tutorService.setTutorMinTime(minutes);
    setMinMinutes(minutes);
  };

  const saveTimeZone = function (timeZone: string) {
    main.tutorService.setTimeZone(timeZone);
    setTimeZone(timeZone);
  };
  const saveNoNewStudents = function (noNewStudents: boolean) {
    main.tutorService.setNoNewStudents(noNewStudents);
    setNoNewStudents(noNewStudents);
  };
  const renderMinutesOptions = function () {
    return minMinRows.map((value) => {
      return (
        <Option key={value.minutes} value={value.minutes}>
          {value.display}{" "}
          {value.minutes > 60
            ? t("calendar_schedule_hours", "horas")
            : value.minutes === 60
            ? t("calendar_schedule_hour", "hora")
            : t("calendar_schedule_minutes", "minutos")}
        </Option>
      );
    });
  };

  const renderTimeZones = function () {
    return timeZones?.map((value) => {
      return (
        <Option key={value.Id} value={value.Id}>
          {value.DisplayName}
        </Option>
      );
    });
  };

  const _load = useCallback(() => {
    main.tutorService.getTutorMinTime();
    main.tutorService.getTimeZones();
    main.tutorService.getTimeZone();
    main.tutorService.getTutor();
  }, [main.tutorService]);

  useEffect(() => {
    let listener = new (class implements TutorEventListener {
      onTutorMinTimeGet(minutes: number): void {
        setMinMinutes(minutes);
      }

      onTimeZoneLoad(timeZone: string): void {
        setTimeZone(timeZone);
      }

      onTimeZonesLoad(timeZones: TimeZone[]): void {
        setTimeZones(timeZones);
      }
      
      onNoNewStudents(noNewStudents: boolean): void {
        setNoNewStudents(noNewStudents);
      }
    })();
    main.tutorService.subscribeToEvents(listener);
    _load();
    return () => {
      main.tutorService.unSubscribeToEvents(listener);
    };
  }, [main.tutorService, _load]);
  
  return (
    <Container open={props.open} isMobile={state.isMobile}>
      <Content visible={contentVisible}>
        {/*<Menu handleClose={_close} showRegularSchedule={props.showRegularSchedule}/>*/}
        <ContainerBody>
          <Header isMobile={state.isMobile}>
            <Back isMobile={state.isMobile} onClick={back}>
              <Icon name={"arrow-left-blue-nav"} disableFill={false} />
            </Back>
            <TitleHeader isMobile={state.isMobile}>
              {t("calendar_menu_title_header", "Configurar mi calendario")}
            </TitleHeader>
          </Header>

          <ContainerSections isMobile={state.isMobile}>
          <ContainerSeccion isMobile={state.isMobile}>
                <Title isMobile={state.isMobile}>{t('calendar_schedule_1_title', 'Antelación solicitudes')}</Title>
                <ContainerDesc isMobile={state.isMobile}>
                    <Desc>{t('calendar_schedule_1_desc', 'Selecciona la antelación mínima para que puedan solicitarte una clase:')}</Desc>
                    <SelectContainer>
                        <NativeSelect IconComponent={() =><IconWrapperDropDown><Icon disableFill={false} name={"chevon-blue-down"}/></IconWrapperDropDown>}
                            value={minMinutes}
                            onChange={x => saveMinMinutes(+x.target.value)}>
                            <Option key={0} hidden disabled value={0} >{t('calendar_schedule_1_select_default', 'Selecciona antelacion mínima')}</Option>
                            {renderMinutesOptions()}
                        </NativeSelect>
                    </SelectContainer>
                    <TipWrapper>
                        <IconWrapper><Icon name={'sand_clock'}/></IconWrapper>
                        <Tip>{t('calendar_schedule_1_tip', 'Normalmente, cuando los estudiantes buscan un nuevo profesor, esperan que este pueda dar la primera clase en máximo 24 horas.')}</Tip>
                    </TipWrapper>
                </ContainerDesc>
            </ContainerSeccion>
            <Separator />

            <ContainerSeccion isMobile={state.isMobile}>
                <Title isMobile={state.isMobile}>{t('calendar_schedule_2_title', 'Franja Horaria')}</Title>
                <ContainerDesc isMobile={state.isMobile}>
                  <Desc dangerouslySetInnerHTML={{__html: t('calendar_schedule_2_desc', 'La franja horaria de tu calendario de Classgap <b>corresponde con la fecha y hora que tienes configurada en tu dispositivo.</b>')}}></Desc>
                  <Desc>{t('calendar_schedule_2_desc_2', 'Si tu alumno es de una franja horaria distinta, Classgap realizará la conversión automáticamente sin que tengas que hacer nada.')}</Desc>
                  <Comment>{t('calendar_schedule_2_tip', 'ℹ️ Si viajas a un país con franja horaria diferente a la tuya, una vez allí, cambia la franja horaria del dispositivo con el que accedas.')}</Comment>
                </ContainerDesc>
            </ContainerSeccion>
            <Separator />
            <ContainerSeccion isMobile={state.isMobile}>
              <Title isMobile={state.isMobile}>{t('calendar_configuration_newstudents', 'Disponibilidad nuevos estudiantes')}</Title>
              <ContainerDesc isMobile={state.isMobile}>
                <Desc>{t('calendar_schedule_3_desc', 'Si ya tienes suficientes alumnos y quieres seguir dando clases sin recibir nuevos, modifica tu estado. Si estarás ausente por un tiempo indefinido, activa esta opción para no recibir nuevos hasta que vuelvas.')}</Desc>
                <TipWrapper>
                  <Checkbox checked={noNewStudents} onChange={(noNewStudents) => {saveNoNewStudents(noNewStudents)}} text={t('calendar_schedule_3_checkbox', 'No quiero recibir nuevos estudiantes por ahora')}/>
                </TipWrapper>
              </ContainerDesc>
            </ContainerSeccion>
          </ContainerSections>
        </ContainerBody>
      </Content>
    </Container>
  );
}

type MenuProps = {};

const MenuMemo = React.memo(_Menu);

export default function MenuWrapper(props: MenuProps) {
  const [state, dispatch] = useContext(UIContext)!;
  return (
    <MenuMemo
      open={state.menuVisible}
      showRegularSchedule={state.regularScheduleVisible}
      dispatch={dispatch}
    />
  );
}
