import { CircularProgress } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Icon from "../icons/Icon";

const Container = styled.div<{collapsible: boolean, enabled: boolean, style: Style, containerHeight: string, containerWidth: string}>`
    width: ${props => props.containerWidth};
    height: ${props => props.containerHeight};
    text-align: center;
    cursor: ${props => props.enabled ? "pointer" : "inherit"};
    background-color: ${props => props.style.main.background};
    opacity: ${props => props.enabled ? 1 : 0.6};
    border-color: ${props => props.style.main.border};
    border-radius: ${props => props.theme.borderRadius.div};
    transition: width 300ms;
    display: flex;
    align-items: center; /* align vertical */
    justify-content: center;
    font-weight: bold;
    border: ${props => props.enabled && props.enabled  ? "1px solid " + (props.style.main.border) : "none"};
    & > p{
       color: ${props => props.style.main.text};
       display: ${props => props.collapsible ? "none": "block"};
    }
    & > div > svg{
       fill: ${props => (props.style.main.icon) + "!important"};
    }
    &:hover{
       border: ${props => props.enabled ? "1px solid " + (props.style.hover.border) : "none"};
       background-color: ${props => props.enabled ? props.style.hover.background + "!important": props.style.main.background};
       & > p{
          color: ${props => props.enabled ? props.style.hover.text : props.style.main.text};
          display: block;
       }
       & > div > svg{
          fill: ${props => props.enabled ? (props.style.hover.icon) + "!important" : props.style.main.icon};
       }
    }
   }
`;

const IconWrapper = styled.div<{small: boolean}>`
  height: 2.25em;
  width:  ${props => (props.small ? "1.5em" : "2.25em")};
  text-align: center;
  padding:  ${props => (props.small ? "0.75em 1.5em" : "0.75em 0.75em 0.75em 0.75em")};
  float: left;
`;

const Title = styled.p<{center: boolean}>`
   font-size: ${props => props.theme.fontSizes.s};
   line-height: ${props => props.theme.fontSizes.s};
   margin: 0 0 0 0;
   float: ${props => !props.center ? "left": "none"};
   padding-right: ${props => !props.center ? "1.5em": "0"};
 `;

const LoadingWrapper = styled.div`
  width: 1.3em;
  height: 1.3em;
  
  .MuiCircularProgress-colorPrimary{
    color: ${props => props.theme.fontColors.quaternary};
    height: 100% !important;
    width: 100% !important;
  }
`;

interface Style  {
    main: {
        text: string,
        icon: string,
        background: string,
        border: string,
    }
    hover: {
        text: string,
        icon: string,
        background: string,
        border: string,
    }
}

type SimpleBtnProps = {
    icon?: string,
    clicked: (event:any) => void,
    style: object,

    smallIcon?: boolean,
    collapsible?: boolean,
    title?: string | null,

    enabled?: boolean,
    loading?: boolean,

    containerHeight: string,
    containerWidth: string,
}

SimpleBtn.defaultProps = { 
    icon: null, 
    title: null, 
    collapsible: true, 
    smallIcon: false, 
    enabled: true, 
    loading: false,
    containerHeight: "100%",
    containerWidth: "auto"
};

export default function SimpleBtn(props: SimpleBtnProps) {

    const onClick = function (event: any) {
        if(!props.enabled) { return }
        props.clicked(event);
    };

    return (
        <Container onClick={onClick} collapsible={props.collapsible!} enabled={props.enabled!} style={props.style as Style} containerHeight={props.containerHeight} containerWidth={props.containerWidth}>
            {props.loading ?
                <LoadingWrapper>
                    <CircularProgress />
                </LoadingWrapper>: null}

            {props.icon && !props.loading ?
                <IconWrapper small={props.smallIcon!}>
                    <Icon name={ props.icon }/>
                </IconWrapper> : null
            }
            {props.title && !props.loading ? <Title center={props.icon === null}>{props.title}</Title> : ''}
        </Container>
    )
}
