import {createElement} from 'react';
const iconSet = require("../../../assets/icons/selection.json");

const style = {
  width: "",
  height: "",
  display: "inline-block",
  stroke: "currentColor",
  fill: "currentColor",
};

type IconParams = {
  name: string,
  size?: string,
  color?: string,
  disableFill?: boolean,
  removeInlineStyle?: boolean,
  style?: any,
};

Icon.defaultProps = {
  size: "100%",
  color: null,
  disableFill: true,
  removeInlineStyle: false,
  style: {}
};

export default function Icon(props: IconParams) {
  if (!iconSet) {
    throw new Error('The "iconSet" and "icon" props are required.');
  }

  const currentIcon = iconSet.icons.find(
      (item: any) => item.properties.name === props.name
  );

  if (!currentIcon) {
    throw new Error('Icon not found: ' + props.name);
  }

  if (props.size) {
    style.width = props.size;
    style.height = props.size;
  }

  if (props.color){
    style.fill = props.color;
    style.stroke = props.color;
  }

  let finalProps: {[key: string] : any} = {};
  finalProps['style'] = {
    ...(props.removeInlineStyle ? {} : style),
    ...(props.style || {}),
  };

  const { width = "1024" } = currentIcon.icon;
  finalProps['viewBox'] = `0 0 ${width} 1024`;

  const paths = currentIcon.icon.paths.map((path: any, index: number) =>
      createElement("path", {
        d: path,
        key: props.name + index,
        ...(!props.disableFill ? currentIcon.icon.attrs[index] : {}),
      })
  );

  return createElement("svg", finalProps, paths);
}