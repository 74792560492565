// NO PONER DATOS SENSIBLES EN ESTE ARCHIVO DE CONFIGURACION COMO KEYS, ETC...
import {classgapTheme, tusTheme} from "../themes";

export class Config {

    private static PRODUCTION = {
        API_URL: process.env.REACT_APP_PROJECT === 'classgap' ? 'https://calendar-api.classgap.com' : 'https://calendar-api.tusclasesparticulares.com',
        LOG_EVENTS_ENABLED: false,
        FAKE_LOGIN_ENABLED: false,
        THEME: process.env.REACT_APP_PROJECT === 'classgap' ? classgapTheme : tusTheme,
        I18N_URL: process.env.REACT_APP_PROJECT === 'classgap' ? 'https://calendar-api.classgap.com/resources/get_by_language?language={{lng}}' : '',
    };

    private static DEVELOPMENT_PRE = {
        API_URL: process.env.REACT_APP_PROJECT === 'classgap' ? 'https://pre-calendar-api.classgap.com' : 'https://pre-calendar-api.tusclasesparticulares.com',
        LOG_EVENTS_ENABLED: true,
        FAKE_LOGIN_ENABLED: true,
        THEME: process.env.REACT_APP_PROJECT === 'classgap' ? classgapTheme : tusTheme,
        I18N_URL: process.env.REACT_APP_PROJECT === 'classgap' ? 'https://pre-calendar-api.classgap.com/resources/get_by_language?language={{lng}}' : '',
    };

    private static DEVELOPMENT_LOCAL = {
        API_URL: 'http://localhost:9000',
        LOG_EVENTS_ENABLED: true,
        FAKE_LOGIN_ENABLED: true,
        THEME: process.env.REACT_APP_PROJECT === 'classgap' ? classgapTheme : tusTheme,
        I18N_URL: process.env.REACT_APP_PROJECT === 'classgap' ? 'http://localhost:9000/resources/get_by_language?language={{lng}}' : '',
    };

    static get(){
        switch (process.env.NODE_ENV) {
            case 'production':
                if(process.env.REACT_APP_USE_PRE){
                    return Config.DEVELOPMENT_PRE;
                }
                if(process.env.REACT_APP_USE_DEVEL){
                    return Config.DEVELOPMENT_LOCAL;
                }
                return Config.PRODUCTION;
            case 'development':
                return Config.DEVELOPMENT_LOCAL;
        }
        return Config.DEVELOPMENT_LOCAL;
    }
}