import { NativeSelect } from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import MainContext from "../../../context/main";
import { WeekDay } from "../../../types/enums/weekday";
import { SelectHour } from "../../../types/select_hour";
import Icon from "../icons/Icon";
import {Config} from "../../../constants/config";

const Container = styled.div`
    height: 100%;
    max-width:320px;
    color: ${props => props.theme.fontColors.primary};
`;

const ContainerSelects = styled.div<{addMore: boolean}>`
    margin-top: ${props=>props.addMore ? '12px' : 'inherit'};
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
`;

const Separator = styled.span`
    display: inline-flex;
    align-items: flex-end;
    padding-bottom: 0.4em;
`;

const IconWrapper = styled.div`
    margin-left: 12px;
    width: 18px;
    display: inline-block;
    cursor:pointer;
    vertical-align: middle;
`;

const IconWrapperAdd = styled.div<{hidden: boolean}>`
    margin-left: 12px;
    width: 40px;
    display: inline-block;
    vertical-align: middle;
    cursor:pointer;
    visibility:${props=>props.hidden ? 'hidden' : 'initial'}   
`;

const IconWrapperDropDown = styled.div`
    width: 15px;
    height: 15px;
    position: absolute;
    right: 6px;
    cursor: pointer;
    pointer-events: none;
`;

const WrapperButtons = styled.div`
    display:flex;   
`;

const SelectContainer = styled.div<{addMore: boolean}>`
    width: auto;
    display: inline-block;
    
    .error{
        border: 2px solid red; 
    }

    .MuiInputBase-root{
        margin-top:0;
    }

    .MuiInput-underline:after{
        border-bottom: none;
    }

    .MuiNativeSelect-select.MuiNativeSelect-select{
        padding-right:2em;
    }

    .MuiInput-root{
        width:100%;
        border: 1px solid #D3D9DE;
        border-radius: 4px;
    }

    .MuiNativeSelect-root {
        padding: 0.6em 1em;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:before, .MuiInput-underline:after{
        border-bottom: none;
    }

`;
class test{
    text : string;
    value : number;

    constructor(){
        this.text= "";
        this.value= 0;

    }
}

type SelectHoursProps = {
    day: WeekDay,
    emptyHandler?: any,
    editHandler?: any,
    addMoreRanges:boolean,
    hour1?:number,
    hour2?:number,
    hourLimitBefore?:number | undefined,
    hourLimit?:number | undefined,
    points?:number[]
    //6points son 30min 
}

SelectHours.defaultProps = {};

export default function SelectHours(props: SelectHoursProps){
    const main = useContext(MainContext)!;
    const [theme] = useState(Config.get().THEME);
    const {t} = useTranslation();
    const [hours] = useState<test[]>(main.calendarService.getHalfHours());
    const [daysNumberRows, setDaysNumberRows] = useState<SelectHour[]>([{hour1: props.hour1 != null ? props.hour1 : undefined, hour2: props.hour2 != null ? props.hour2 : undefined, error: false, id:1, points:[], date1: new Date(), date2: new Date(), day : WeekDay.MONDAY}]);

    useEffect(() => {
        if(props.points == null)
          return;
        
        let temp = main.weekPointService.getSelectHourFromWeekPoints(props.points, props.day)
        if(temp == null)
            return;

        setDaysNumberRows(temp);

    }, [props.points, main.weekPointService, props.day]);

    //por cada dia necesitamos saber cuantos selects hemos abierto
    const addRow = function(id : number){
        let temp = daysNumberRows;
        let a = new SelectHour();
        let b = temp.sort(x=> x.id);

        a.id = b[b.length - 1].id + 1;
        temp.push(a);

        setDaysNumberRows([...temp]);

        /*if(ref.current){
            ref!.current!.scrollIntoView() 
        }*/
    };

    const deleteRow = function(id : number){
        let temp = daysNumberRows;
        temp.findIndex(x=>x.id === id);
        let temp2 = temp;
        temp2 = temp.filter(x=>x.id!== id);

        if(temp2.length === 0)
            props.emptyHandler(props.day);

        setDaysNumberRows([...temp2]);

        let arrPoints : number[]= [];
        temp2.forEach(function(curr){
            if(curr.points !== null)
                arrPoints = arrPoints.concat(curr.points);
        });

        if(props.addMoreRanges)
            props.editHandler(arrPoints, props.day);
    };

    const getOptionsHours = function (otherHour: number | undefined, biggerDisabled: boolean, id: number){

        let hours2 : test[] = [];
        hours2 = hours2.concat(hours);
        if(!biggerDisabled)
        {
            hours2.push(hours2[0]);
            hours2 = hours2.slice(1)
        }

        return hours2?.filter(function(value){
            let val = value.value;
            if(!biggerDisabled && value.value===0){
                val = 24;
            }
            let disabled = false;

            if(props.hourLimitBefore && val < props.hourLimitBefore && biggerDisabled)
                return false;

            if(props.hourLimit && val > props.hourLimit && !biggerDisabled)
                return false;

            if(val === otherHour && val !== 0)
                return false;

            if(otherHour && !disabled){
                if(biggerDisabled)
                    disabled = val >= otherHour;
                else if(val!==0)
                    disabled = val <= otherHour;
            }

            return !disabled;
        })
            .map(value => {
                return <option key={value.value} value={value.value}>
                    {value.text}
                </option>
            })
    };

    function compare( a:any, b:any ) {

        if ( a.id < b.id )
            return -1;

        if ( a.id > b.id )
            return 1;

        return 0;
    }

    const refresh = function(value:number, id:number, first:number){
        let temp = daysNumberRows;
        let a = temp.find(x=>x.id === id);
        if(a == null)
            return;

        if(first===1)
            a.hour1 = value;
        else
            a.hour2 = value;

        a.error = false;
        //si el hour 1 es mas grande que el hour 2
        //o los dos tienen el mismo valor devolvemos un error
        if(a.hour1 !=null && a.hour2 !=null){

            if(a.hour1 >= a.hour2 && a.hour2 !== 0)
                a.error = true;
            //primero sacar un array de fechas Date
            let arrPoints : number[] = [];
            a.hour2 = a.hour2 === 0 ? 24 : a.hour2;
            let recorrido = (a.hour2 - a.hour1) * 2;
            let hour = a.hour1;

            for(let x = 0; x < recorrido; x++)
            {
                var string = String(hour).split('.');

                arrPoints.push(main.calendarService.dateToPoint(parseInt(string[0]), string.length > 1 ? 30 : 0, props.day));
                hour += 0.5;
            }

            a.points = arrPoints;
        }

        let temp2 = temp;
        temp2 = temp.filter(x=>x.id!== id);
        temp2.push(a);
        temp2.sort(compare);

        setDaysNumberRows([...temp2]);

        if(a.hour1 !=null && a.hour2 !=null){
            let arrPoints : number[] = [];
            daysNumberRows.forEach(function(curr){
                if(curr.points !== null)
                    arrPoints = arrPoints.concat(curr.points);
            });
            a.hour2 = a.hour2 === 0 ? 24 : a.hour2;
            if(props.addMoreRanges)
                props.editHandler(arrPoints, props.day);
            else
                props.editHandler(a.hour1, a.hour2 - a.hour1);
        }
    };

    const generateRandomKey = function(){
        let result = [];
        let keyLength = 10;
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for ( let i = 0; i < keyLength; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() * characters.length)));
        }
        return result.join('');
    }

    const renderSelectsHours = function (){
        let x = 0;
        let last= false;
        return daysNumberRows.map(value => {
            x++;
            last = daysNumberRows.length === x;
            return(
                <ContainerSelects key={generateRandomKey()} addMore={props.addMoreRanges} >
                    <SelectContainer addMore={true}>
                        <NativeSelect IconComponent={() =><IconWrapperDropDown><Icon disableFill={false} name={"chevon-blue-down"}/></IconWrapperDropDown>}
                                      defaultValue={value.hour1 == null ? '' : value.hour1}  className={value.error ? "error" : ""} onChange={x => refresh(+x.target.value,value.id, 1)}>
                            <option hidden disabled value={""}>{t('calendar_hour_placeholder', 'Hora')}</option>
                            {getOptionsHours(value.hour2, true, value.id) }
                        </NativeSelect>
                    </SelectContainer>
                    <Separator>{t('calendar_container_selects_separator', 'a')}</Separator>
                    <SelectContainer addMore={props.addMoreRanges}>
                        <NativeSelect IconComponent={() =><IconWrapperDropDown><Icon disableFill={false} name={"chevon-blue-down"}/></IconWrapperDropDown>}
                                      defaultValue={value.hour2 == null ? '' : (value.hour2 === 24 ? 0 : value.hour2)} className={value.error ? "error" : ""} onChange={x => refresh(+x.target.value,value.id, 2)}>
                            <option hidden disabled value={""}>{t('calendar_hour_placeholder', 'Hora')}</option>
                            {getOptionsHours(value.hour1, false, value.id) }
                        </NativeSelect>
                    </SelectContainer>
                    {props.addMoreRanges ?
                        <WrapperButtons>
                            <IconWrapper onClick={ () => deleteRow(value.id)} ><Icon name={'delete'} disableFill={false}/></IconWrapper>
                            <IconWrapperAdd hidden={!last} onClick={() => addRow(value.id)}><Icon  name={'add_plus_circle'} color={theme.iconAdd.color} disableFill={theme.name ==="tus"}/></IconWrapperAdd>
                        </WrapperButtons>
                        : null
                    }
                </ContainerSelects>
            )
        })
    };
    return (
        <Container>
            {renderSelectsHours()}
        </Container>
    )
}