import {DayEventStatus, DayEventType} from "./enums/day_event";

export class DayEventResponse {

    public Id: string = "";
    public Status: DayEventStatus = DayEventStatus.ACTIVE;
    public Type: DayEventType = DayEventType.NOT_DEFINED;
    public Ticks : number = 0; // Hora en utc
    public Duration : number = 0; // Hora en utc
    public Title : string = "";


    constructor(Type: DayEventType, Ticks: number, Id: string = "") {
        this.Id = Id;
        this.Type = Type;
        this.Ticks = Ticks;
    }

}

export class DayEvent {

    public id: string = "";
    public eventType: DayEventType = DayEventType.NOT_DEFINED;
    public initTimestamp : number | null = null; // Hora en utc
    public endTimestamp : number | null = null; // Hora en utc
    public days : number = 1; // dias que dura
    public title : string | null = null; 

    constructor(eventType: DayEventType, initTimestamp: number, endTimestamp: number, id: string = "", days:number = 1, title: string = "") {
        this.id = id.length === 0 ? this.makeId(20): id;
        this.eventType = eventType;
        this.initTimestamp = initTimestamp;
        this.endTimestamp = endTimestamp;
        this.days = days;
        this.title = title;
    }

    makeId(length: number) {
        let result           = [];
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() *
                charactersLength)));
        }
        return result.join('');
    }

    getInitialHourFormatted(){
        let date = new Date(0);
        date.setSeconds(this.initTimestamp! * 60 * 60);
        return  date.toISOString().substr(11, 5);
    }

    getEndHourFormatted(){
        let date = new Date(0);
        date.setSeconds(this.endTimestamp! * 60 * 60);
        return  date.toISOString().substr(11, 5);
    }
}