import React from "react";

type CheckboxProps = {
    checked: boolean,
    text: string
    onChange:(value: boolean) => void
}

export default function Checkbox(props: CheckboxProps ){
    const handleOnChange = () => {
        props.onChange(!props.checked);
    };
    
    return(
        <label>
            <input type="checkbox" checked={props.checked} onChange={handleOnChange}/>
            <p>{props.text}</p>
        </label>
    )
}