import Backend from 'i18next-xhr-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import i18next, {i18n} from "i18next";
import { Config } from './constants/config';

export interface i18nCustom extends i18n {}

(i18next as i18nCustom)
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        lowerCaseLng: true,
        react: {
            useSuspense: true
        },
        backend: {
            crossDomain: true,
            loadPath: Config.get().I18N_URL
        }
    })
    .then(()=>{/**/});

export default i18next as i18nCustom;