

export default class ParentService {

    protected subscribers: ParentServiceListener[] = [];

     /** Subscribe element to class events. */
    subscribeToEvents = (callback: ParentServiceListener) => {
        this.subscribers.push(callback);
    };

    /** Unsubscribe element to class events. */
    unSubscribeToEvents = (callback: ParentServiceListener) => {
        const index = this.subscribers.indexOf(callback);
        if (index !== -1) { this.subscribers.splice(index, 1); }
    };

}

export interface ParentServiceListener {

}