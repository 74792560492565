import React, {ReactNode, useContext, useEffect, useReducer} from "react";
import {isMobile, isTablet} from "react-device-detect";
import MainContext from "../context/main";
import UIContext, {reducer, setIsMobile, UICONTEXT_DEFAULT} from "../context/ui";



type Props = {
    children: ReactNode;
};

export default function UIProvider(props: Props) {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, UICONTEXT_DEFAULT);
    const main = useContext(MainContext)!;

    useEffect(()=>{
        dispatch(setIsMobile((isMobile && !isTablet)));
    }, [dispatch]);

    useEffect(function () {
        main.initialize();
        // return () => {main.disconnect()}
    }, [main]);

    return (
        <UIContext.Provider value={[state, dispatch]}>
            {children}
        </UIContext.Provider>
    );
}