import axios from 'axios'
import {Config} from "../../constants/config";
import {DayEventType} from '../../types/enums/day_event';

axios.defaults.baseURL = Config.get().API_URL;
axios.defaults.withCredentials = true;

if(process.env.REACT_APP_EMBED && process.env.REACT_APP_USE_PRE){
    const COOKIE_NAME = 'CG_UI';
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${COOKIE_NAME}=`);
    const reg = /i=(.*)/;
    const match = parts.length > 0 ? parts[1].split(";")[0].match(reg) : null;
    const user_id = match != null && match.length > 0 ? match[1] : null;
    if(user_id !== null){
      axios.defaults.headers.common['user-id'] = user_id;
    }
}


/**
 * ClassRoom API Helper
 */

export default class API {
    /** Verify api status .
     * @category Api Endpoints */
    public static API_STATUS: string = "/loadbalancer/status";

    /** Absence endpoint.
     * @category Absence Endpoints */
    public static ABSENCE_GET: string = "/absence/";

    /** Absence endpoint.
     * @category Absence Endpoints */
    public static ABSENCE_ADD: string = "/absence/add";

    /** Absence endpoint.
     * @category Absence Endpoints */
    public static ABSENCE_EDIT: string = "/absence/";

    /** Absence endpoint.
     * @category Absence Endpoints */
    public static ABSENCE_DELETE: string = "/absence/delete";

    /** Week Points endpoint.
     * @category WeekPoints Endpoints */
    public static WEEK_POINTS_GET: string = "/calendar/week-points";

    /** Week Points endpoint.
     * @category WeekPoints Endpoints */
    public static WEEK_POINTS_SET: string = "/calendar/week-points";

    /** Tutor endpoint.
     * @category Tutor Endpoints */
    public static TUTOR_MINIMUM_MINUTES_GET: string = "/tutor/min-time";

    /** Tutor endpoint.
     * @category Tutor Endpoints */
    public static TUTOR_MINIMUM_MINUTES_SET: string = "/tutor/min-time";

    /** Tutor endpoint.
     * @category Tutor Endpoints */
     public static TUTOR_GET_TIMEZONE: string = "/tutor/time-zone";

    /** Tutor endpoint.
     * @category Tutor Endpoints */
     public static TUTOR_GET_TIMEZONES: string = "/tutor/time-zones";

    /** Tutor endpoint.
     * @category Tutor Endpoints */
     public static TUTOR_SET_TIMEZONE: string = "/tutor/time-zone";

    /** Point endpoint.
     * @category Point Endpoints */
    public static POINTS_ADD_MANY: string = "/point/add-many";

    /** Point endpoint.
     * @category Point Endpoints */
    public static POINTS_GET: string = "/point/";

    /** Point endpoint.
    * @category Point Endpoints */
    public static POINTS_DELETE_MANY: string = "/point/delete-many";   

        /** Point endpoint.
    * @category Point Endpoints */
         public static POINTS_SET_TYPE: string = "/point/set";  

    /** Onboarding endpoint.
    *  @category Onboarding Endpoints */
    public static ONBOARDING_GET_SHOW: string = "/onboarding/show";

    /** Onboarding endpoint.
    *  @category Onboarding Endpoints */
    public static ONBOARDING_SET_SEEN: string = "/onboarding/seen"; 

    /** Booking endpoint.
    *  @category Booking Endpoints */
     public static BOOKING_ADD: string = "/booking/add";

    /** NoNewStudent endpoint.
     *  @category NoNewStudents Endpoints */
    public static NoNewStudents_Update: string = "/tutor/no-new-students";
    public static Tutor_Get: string = "/tutor/tutor";

    private static wrapper(method: any) {
        return new Promise((resolve, reject) => {
            method
                .then((response: any) => {
                    if (response.status < 300) {
                        return resolve(response.data.Data);
                    }
                    return reject(response.data);
                })
                .catch((error: any) => {
                    if (error.response && error.response.data) {
                        return reject(error.response.data)
                    }
                    return reject(error);
                });
        });
    }

    /** Gets Abscenes (holidays) by TutorId
     * Response: Returns a json array containing Document
     * */
    static getAbsences() {
        return API.wrapper(axios.get(API.ABSENCE_GET));
    }

    /** Adds Abscene
     * Response: Returns a string (id)
     * */
    static addAbsence(title: string, startTicks: number, endTicks: number, tzo: number) {
        return API.wrapper(axios.post(API.ABSENCE_ADD, {
            Title: title,
            StartTicks: startTicks,
            EndTicks: endTicks,
            Tzo: tzo
        }));
    }

    /** Edits Abscene
     * Response: Returns nothing
     * */
    static editAbsence(id: string, title: string, startTicks: number, endTicks: number) {
        return API.wrapper(axios.post(API.ABSENCE_EDIT, {
            Id: id,
            Title: title,
            StartTicks: startTicks,
            EndTicks: endTicks
        }));
    }

    /** Deletes Abscene
     * Response: Returns nothing
     * */
    static deleteAbsence(id: string) {
        return API.wrapper(axios.post(API.ABSENCE_DELETE, {'Id': id}));
    }

    /** gets week Points
     * Response: Returns Number[]
     * */
    static getWeekPoints(timeZoneOffset: Number) {
        return API.wrapper(axios.get(API.WEEK_POINTS_GET + "?" + new URLSearchParams({
            TimeZoneOffset: timeZoneOffset.toString()
        })));
    }

    /** sets week Points
     * Response: Returns nothing
     * */
    static setWeekPoints(points: Number[], timeZoneOffset: Number) {
        return API.wrapper(axios.post(API.WEEK_POINTS_SET, {
            'Points': points,
            'TimeZoneOffset': timeZoneOffset
        }));
    }

    /** gets tutor minimumTimeBeforeBook
     * Response: Returns number (minutes)
     * */
    static getTutorMinTime() {
        return API.wrapper(axios.get(API.TUTOR_MINIMUM_MINUTES_GET));
    }

    /** sets tutor minimumTimeBeforeBook
     * Response: Returns nothing
     * */
    static setTutorMinTime(minutes: number) {
        return API.wrapper(axios.post(API.TUTOR_MINIMUM_MINUTES_SET, {Minutes: minutes}));
    }

    /** gets all timezones
     * Response: Returns all timezones
     * */
     static getTimeZones() {
        return API.wrapper(axios.get(API.TUTOR_GET_TIMEZONES));
    }

    /** gets tutor timezone
     * Response: Returns string (timezoneId)
     * */
     static getTimeZone() {
        return API.wrapper(axios.get(API.TUTOR_GET_TIMEZONE));
    }

    /** set timezone
     * Response: Returns nothing
     * */
     static setTimeZone(timeZone: string) {
        return API.wrapper(axios.post(API.TUTOR_SET_TIMEZONE, {
            TimeZone: timeZone
        }));
    }

    /** adds range of points (events)
     * Response: Returns nothing
     * */
    static addPoints(tzo: number, ticksInit: number, ticksEnd: number, type: DayEventType) {
        return API.wrapper(axios.post(API.POINTS_ADD_MANY, {
            Tzo: tzo,
            TicksInit: ticksInit,
            TicksEnd: ticksEnd,
            Type: type
        }));
    }

    /** gets points (events)
     * Response: Returns day event
     * */
    static getPoints(ticksInit: number, ticksEnd: number) {
        return API.wrapper(axios.get(API.POINTS_GET + "?" + new URLSearchParams({
            TicksInit: ticksInit.toString(),
            TicksEnd: ticksEnd.toString()
        })));
    }

    /** deletes points (events)
     * Response: Returns nothing
     * */
     static deletePoints(ticksInit: number, ticksEnd: number) {
        return API.wrapper(axios.post(API.POINTS_DELETE_MANY, {
            TicksInit: ticksInit,
            TicksEnd: ticksEnd,
        }));
    }

    /** update point (events)
     * Response: Returns nothing
     * */
     static setPoint(ticksInit: number, type: DayEventType, duration: number, newTicks: number) {
        return API.wrapper(axios.post(API.POINTS_SET_TYPE, {
            Ticks: ticksInit,
            Type: type,
            Duration: duration,
            NewTicks: newTicks,
        }));
    }

    /** getShowModalOnboarding
     * Response: Returns if show onboarding modal
     * */
     static getShowModalOnboarding() {
        return API.wrapper(axios.get(API.ONBOARDING_GET_SHOW));
    }

    /** Sets the modal as seen
     * Response: Returns nothing
     * */
     static setSeenModalOnboarding() {
        return API.wrapper(axios.post(API.ONBOARDING_SET_SEEN));
    }

    /** Adds Abscene
     * Response: Returns a string (id)
     * */
     static addBooking(tzo: number, tutorId: number, ticks: number) {
        return API.wrapper(axios.post(API.BOOKING_ADD, {
            Tzo: tzo,
            TutorId: tutorId,
            Ticks: ticks
        }));
    }

    // todo remove, solo para test
    static login(tutorId: number){
        return API.wrapper(axios.post("/session/login", {TutorId : tutorId}));
    }

    static getTutor() {
        return API.wrapper(axios.get(API.Tutor_Get));
    }

    /** set NoNewStudents
     * Response: Returns nothing
     * */
    static setNoNewStudents(noNewStudents: boolean) {
        return API.wrapper(axios.post(API.NoNewStudents_Update, {
            NoNewStudents: noNewStudents
        }));
    }
}