import React, {ReactNode, useEffect, useState} from "react";
import {MainService} from "../services/main";
import MainContext from "../context/main";

type Props = {
  children: ReactNode;
};

export default function MainProvider(props: Props) {
  const { children } = props;
  const [mainService, setMainService] = useState<MainService | null>(null);
  useEffect(function () {
    setMainService(new MainService());
  }, []);
  
  return (
    <MainContext.Provider value={mainService}>
      {mainService !== null ?
        children: null
      }
    </MainContext.Provider>
  );
}