import {DayEvent} from "./day_event";
import { DayEventType } from "./enums/day_event";

export class DayEvents {
    public date: Date | null = null;
    public current: boolean = false;
    public selected: boolean = false;
    public events:  DayEvent[] = [];

    constructor(date: Date, events: DayEvent[] = []) {
        this.date = date;
        this.events = events;
    }

    setCurrent(current: boolean){
        this.current = current;
    }

    setSelected(selected: boolean){
        this.selected = selected;
    }

    addEvent(index: number, event: DayEvent){
        this.events.push(event);
    }

    deleteEvent(id: string){
        this.events = this.events.filter(function(el) { return el.id !== id; }); 
    }

    existsEvent(eventType: DayEventType, initTimestamp: number, endTimestamp: number) {
        return this.events.filter((e) => {
            return e.eventType === eventType &&
                e.initTimestamp === initTimestamp &&
                e.endTimestamp === endTimestamp;
        }).length > 0;
    }
}