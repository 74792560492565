
export enum DayEventType {
    NOT_DEFINED = 0,
    AVAILABLE = 1,
    NOT_AVAILABLE= 2,
    BLOCKED = 3,
    BOOKING = 4,
    ABSENCE = 5,
    BOOKING_ENTERPRISE = 6
}


export enum DayEventStatus {
    NOT_DEFINED = 0,
    ACTIVE = 1,
    DELETED= 2,
}