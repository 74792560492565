import { WeekDay } from "./enums/weekday";

export class SelectHour {
    public hour1?:number;
    public hour2?:number;
    public points:number[];
    public date1:Date;
    public date2:Date;
    public id:number;
    public day:WeekDay;
    public error:boolean;
    constructor() {
        this.id = 1;
        this.date1 = new Date();
        this.date2 = new Date();
        this.error = false;
        this.points = [];
        this.day = WeekDay.MONDAY;
    }
}
