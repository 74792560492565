import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {AutoSizer, Index, MultiGrid, ScrollParams} from "react-virtualized";
import styled from "styled-components";
import MainContext from "../../../context/main";
import {CalendarListener} from "../../../services/calendar/calendar";
import {DayEvent} from "../../../types/day_event";
import {DayEventType} from "../../../types/enums/day_event";
import {WeekDay} from "../../../types/enums/weekday";
import UIContext, { setRegularScheduleVisible } from "../../../context/ui";
import {WeekPointsEventListener} from "../../../services/weekPoints/weekPoints";
import {SelectHour} from "../../../types/select_hour";
import {useTranslation} from "react-i18next";


const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const CellParent = styled.div`
   padding: 2px;
   > div{
       cursor: pointer;
       display: flex;
       align-items: center;
       justify-content: center;
       border: 1px solid #eee;
       width: calc(100% - 2px);
       height: calc(100% - 2px);
       font-size: 14px;
       background-color:  ${props => props.theme.calendar.cellColors.base.background};
   }
`;

const Cell = styled(CellParent) <{ current: boolean }>`
  div{
    position:relative;
    border-radius: 4px;
    background-color:  ${props => props.theme.calendar.cellColors.base.background};
    border:  ${props => props.theme.calendar.cellColors.base.border};
    color: ${props => props.theme.calendar.cellColors.base.text};
    :hover{
        background-color: ${props => props.theme.calendar.cellColors.availableHover.background};
        color: ${props => props.theme.calendar.cellColors.availableHover.text};

        > .hour{
            display: block;         
          }
    }
    > .hour{
        display: none;         
      }
  }
`;

const CellHour = styled(CellParent)`
  background-color:  ${props => props.theme.calendar.cellColors.hour.background};
  div{
    flex-direction: column;
    font-size: 12px;
    background-color:  ${props => props.theme.calendar.cellColors.hour.background};
    border:  ${props => props.theme.calendar.cellColors.hour.border};
    color: ${props => props.theme.calendar.cellColors.hour.text};
  }
`;

const CellDay = styled(CellParent) <{ current: boolean }>`
  background-color:  ${props => props.theme.calendar.cellColors.hour.background};

  div{
    font-size: 12px;
    flex-direction: column;
    border: 1px solid transparent;
    cursor: initial;
    background-color:  ${props => props.theme.calendar.cellColors.day.background};
    border:  ${props => props.theme.calendar.cellColors.day.border};
    color: ${props => props.theme.calendar.cellColors.day.text};
    
    .dayName{
      padding: 0;
      margin: 0;
      color:  ${props => props.current ? props.theme.calendar.currentDayColors.title_text : "inherit"};
      font-size: 13px;
      text-transform: uppercase;
    }
    
    .dayNumber{
      margin: 2px 0 0;
      border-radius: 18px;
      text-align: center;
      line-height: 18px;
      font-size: 16px;
      font-weight: bold;
      width: 28px;
      padding: 0;
      height: 28px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color:  ${props => props.current ? props.theme.calendar.currentDayColors.circle_text : "inherit"};
      background-color:  ${props => props.current ? props.theme.calendar.currentDayColors.circle_background : "transparent"};
    }
  }
`;

const CellAvailable = styled(CellParent)`
  div{
      border-radius: 4px;
      background-color:  ${props => props.theme.calendar.cellColors.available.background};
      border:  ${props => props.theme.calendar.cellColors.available.border};
      color: ${props => props.theme.calendar.cellColors.available.text};
      &:hover{
        background-color: ${props => props.theme.calendar.cellColors.availableHover.background};
        color: ${props => props.theme.calendar.cellColors.availableHover.text};

        > .hour{
            display: block;         
          }
          > .text{
            display: none;         
          }
      }

      > .hour{
        display: none;      
      }   
      > .text{
        display: block;         
      }   
  }
`;

const CellNotAvailable = styled(CellParent)`
   > div{
      border-radius: 4px;
      background-color:  ${props => props.theme.calendar.cellColors.notAvailable.background};
      border:  ${props => props.theme.calendar.cellColors.notAvailable.border};
      color: ${props => props.theme.calendar.cellColors.notAvailable.text};
      &:hover{
        background-color: #A7B3BE;
      }
  }
`;

const CellAbsence = styled(CellParent)`
   > div{
      border-radius: 4px;
      text-align: center;
      background-color:  ${props => props.theme.calendar.cellColors.notAvailable.background};
      border:  ${props => props.theme.calendar.cellColors.notAvailable.border};
      color: ${props => props.theme.calendar.cellColors.notAvailable.text};
      cursor: default;

      > div{
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        padding: 0.5em;
      }
  }
`;

const CellBlocked = styled(CellParent)`
  div{
      border-radius: 4px;
      background-image: linear-gradient(45deg, #ffffff 35.71%, #D7DBE0 35.71%, #D7DBE0 50%, #ffffff 50%, #ffffff 85.71%, #D7DBE0 85.71%, #D7DBE0 100%);
      background-size: 9.90px 9.90px;
      background-color:  ${props => props.theme.calendar.cellColors.blocked.background};
      border:  ${props => props.theme.calendar.cellColors.blocked.border};
      color: ${props => props.theme.calendar.cellColors.blocked.text};
      cursor: pointer;
      :hover{
         background-image: linear-gradient(45deg, #A7B3BE 35.71%, #8E9EAA  35.71%, #8E9EAA  50%, #A7B3BE 50%, #A7B3BE 85.71%, #8E9EAA  85.71%, #8E9EAA  100%);
         background: #fff;
         > .hour{
            display: block;         
          }
      }
      > .hour{
        display: none;         
      }
  }
`;

const CellBooking = styled(CellParent)`
  div{
      border-radius: 4px;
      background-color:  ${props => props.theme.calendar.cellColors.booking.background};
      border:  ${props => props.theme.calendar.cellColors.booking.border};
      color: ${props => props.theme.calendar.cellColors.booking.text};
      cursor: default;
      height: 100%;
      text-align:center;

      > span{
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        max-height: -webkit-fill-available;
        line-height: 1;
        margin: 0.5em;
      }
  }
`;

const Timeline = styled.div`
   padding: 2px;
   > div{
       width: calc(100% - 2px);
       height: calc(100% - 2px);
       background-color: red;
   }
   .time-line{
        border-top:1px red solid;
        position:absolute;
        width: 100%;
        top: -1px;
   }
   .time-circle{
        width: 10px;
        height: 10px;
        border-radius: 6px;
        position: absolute;
        top: -6px;
        background: red;
        left: 0;
   }
`;

type _CalendarGridProps = {
    rowHeight?: number
    firstRowHeight?: number,
    firstColumnWidth?: number,
    isMobile: boolean,
    isTutor?: boolean,
    tutorId?: number
}

function _CalendarGrid(props: _CalendarGridProps) {

    const {t} = useTranslation();
    const main = useContext(MainContext)!;
    const gridRef = useRef<any>();
    const [rowHeight] = useState(props.rowHeight ? props.rowHeight : (props.isMobile ? 43 : 43));
    const [firstRowHeight] = useState(props.firstRowHeight ? props.firstRowHeight : (props.isMobile ? 68 : 68));
    const [firstColumnWidth] = useState(props.firstColumnWidth ? props.firstColumnWidth : (props.isMobile ? 80 : 80));

    const [ready, setReady] = useState(false);
    const [columnsCount, setColumnsCount] = useState(main.calendarService.getDaysCount());
    const [rowsCount, setRowsCount] = useState(main.calendarService.getRowsCount());

    const [currentColumn, setCurrentColumn] = useState<number | undefined>(main.calendarService.getIndexCurrentDay() + (props.isMobile ? 1 : 3));
    const [lastScrollLeft, setLastScrollLeft] = useState(0);
    const [loading, setLoading] = useState(false);
    const [timeZoneOffset] = useState<number>(new Date().getTimezoneOffset());
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, dispatch] = useContext(UIContext)!;

    const startingRow = 13; // 6:00 am

    const modifyCurrentColumn = (column: number) => {
        setCurrentColumn(column);
        setTimeout(() => {
            setCurrentColumn(undefined);
        }, 10)
    };

    useEffect(() => {
        let listener = new class implements CalendarListener {

            onRefreshNeeded(): void{
                if (gridRef && gridRef.current)
                    gridRef.current.forceUpdateGrids();
            }

            onDataLoadedAfter(daysAdded: number | null): void {
                let daysCount = main.calendarService.getDaysCount();
                setColumnsCount(daysCount);
                if (daysAdded)
                    modifyCurrentColumn(daysCount - daysAdded - (props.isMobile ? 2:6));
                setLoading(false)
            }

            onDataLoadedBefore(daysAdded: number | null): void {
                let daysCount = main.calendarService.getDaysCount();
                setColumnsCount(daysCount);
                if (daysAdded) {
                    setLastScrollLeft(0);
                    modifyCurrentColumn(daysAdded);
                }
                setLoading(false)
            }

            onReady(): void {
                setColumnsCount(main.calendarService.getDaysCount());
                let index = main.calendarService.getIndexTargetDay();
                modifyCurrentColumn(index ? index : 15);
                setRowsCount(main.calendarService.getRowsCount());
                setReady(true);
                if (gridRef && gridRef.current)
                    gridRef.current.forceUpdateGrids();
            }
        }();
        main.calendarService.subscribeToEvents(listener);
        main.calendarService.initializeCalendar();
        return () => {
            main.calendarService.unSubscribeToEvents(listener);
        }
    }, [main.calendarService, props.isMobile, timeZoneOffset]);

    useEffect(() => {

        let listener = new class implements WeekPointsEventListener {
            onWeekPointsGetSelectHour(events: SelectHour[]) : void{
                if(events.length){
                    dispatch(setRegularScheduleVisible(true));
                } else {
                    dispatch(setRegularScheduleVisible(false));
                }

                main.calendarService.removeWeekEvents();

                events.forEach(function(c){
                    if(c.hour1 != null && c.hour2 != null)
                        main.calendarService.addWeekEvent(c.day, c.hour1, c.hour2);
                 })
            }
        }();
        main.weekPointService.subscribeToEvents(listener);
        main.weekPointService.getWeekPointsSelectHour(timeZoneOffset, false);

        return () => {
            main.weekPointService.unSubscribeToEvents(listener);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [main.weekPointService, timeZoneOffset, main.calendarService]);

    const _onScroll = (params: ScrollParams) => {
        if (loading) {
            // Si ya estamos cargando, descartamos el scroll
            return;
        }
        if (params.clientWidth === undefined) {
            // Si el tamaño del cliente no viene definido, descartamos el scroll
            return
        }

        //Guardamos el ultimo scroll leido
        if (lastScrollLeft === 0) {
            setLastScrollLeft(params.scrollLeft);
            return;
        }
        // Si la diferencia de scoll es 0, descartamos el evento de scroll
        if (lastScrollLeft - params.scrollLeft === 0) {
            setLastScrollLeft(params.scrollLeft);
            return;
        }

// Calculamos el primer dia visible
        /*let index = ((lastScrollLeft && lastScrollLeft - params.scrollLeft > 0) ? Math.floor(params.scrollLeft / columnWidth) + 1 : Math.ceil(params.scrollLeft / columnWidth));
        let day = main.calendarService.getDayByIndex(index);
        if(day && day.date)
          main.calendarService.setVisibleDate(day.date);*/

        setLastScrollLeft(params.scrollLeft);

        if (params.scrollLeft >= (params.scrollWidth - params.clientWidth) - 50) {
            // Scroll hacia la derecha
            setLoading(true);
            main.calendarService.loadDataAfter();
        }
        if (params.scrollLeft - 10 < 0) {
            // Scroll hacia la izquierda
            setLoading(true);
            main.calendarService.loadDataBefore();
        }
    };

    // Dibuja la base de la grid
    // @ts-ignore
    const _cellRenderer = ({ columnIndex, key, rowIndex, style, isScrolling, isVisible }) => {
        if (columnIndex === 0 && rowIndex === 0) {
            // La casilla 0 esta en blanco
            return
        }

        if (columnIndex === 1 || columnIndex === columnsCount) {
            // La segunda y ultima columna la usamos como indicadores de carga
            return
        }

        let day = main.calendarService.getDayByIndex(columnIndex);
        if (!day) {
            return
        }
        if (columnIndex === 0) {
            // La primera columa la usamos para mostrar las horas
            style['zIndex'] = 4;
            let timeFormatted = main.calendarService.getHourByIndex(rowIndex);
            if (rowIndex % 2 !== 0) {
                return <CellHour key={key} style={style}><div><span>{timeFormatted}</span></div></CellHour>
            } else {
                return <CellHour key={key} style={style}/>
            }
        }
        let today = new Date();
        let isSameDate = day.date?.getDate() === today.getDate()
         && day.date?.getMonth() === today.getMonth()
         && day.date?.getFullYear() === today.getFullYear()
        if (rowIndex === 0) {
            // La primera row la usamos para mostrar los dias
            style['zIndex'] = 4;
            return <CellDay key={key} current={isSameDate} style={style}>
                <div>
                    <p className={'dayName'}>{_getWeekDayName(day.date!.getDay())}</p>
                    <p className={'dayNumber'}><span>{day.date!.getDate()}</span></p>
                </div>
            </CellDay>
        }

        let hourString = main.calendarService.getHourByIndex(rowIndex);
        if(props?.isTutor)
            return <Cell key={key} style={style} current={day.selected} onClick={() => { _cellClicked(columnIndex, rowIndex) }}>
                        <div>
                            <span className={"hour"}>{hourString}</span>
                        </div>
                    </Cell>
        return <Cell key={key} style={style} current={day.selected} onClick={() => { _dayEventClickedStudent(columnIndex, rowIndex) }}>
                    <div>
                        <span className={"hour"}>{hourString}</span>
                    </div>
                </Cell>
    };

    // Dibuja los eventos
    const _renderCellByEventType = (key: string, date: Date, event: DayEvent, isWeekEvent: boolean, style: any, step?: number ) => {
        if (!event) {
            return null
        }
        let onClickMethod = !isWeekEvent ? _dayEventClicked : _weekDayEventClicked;
        switch (event.eventType) {
            case DayEventType.AVAILABLE:
                let hourString = "";
                if(event.initTimestamp)
                    hourString = Math.trunc(event.initTimestamp)+ ":" + ((event.initTimestamp % 1).toFixed(1) === '0.5' ? "30" : "00");

                if(isWeekEvent && props?.isTutor){
                    return <CellAvailable key={key} style={style} onClick={() => { onClickMethod(date, event, step) }}>
                                <div>
                                    <span className={"hour"}>{hourString}</span>
                                </div>
                            </CellAvailable>;
                }
                else if(props?.isTutor){
                    return <CellAvailable key={key} style={style} onClick={() => { onClickMethod(date, event) }}>
                                <div>
                                    <span className={"text"}>{t("calendar_cell_available","Disponible")}</span>
                                    <span className={"hour"}>{hourString}</span>
                                </div>
                            </CellAvailable>;
                }
                else{
                    return <CellAvailable key={key} style={style} onClick={() => { _dayEventClickedStudent(date, event) }}>
                                <div>
                                    <span className={"hour"}>{hourString}</span>
                                </div>
                            </CellAvailable>;
                }
                //cell en caso del profe, cellavailable student
            case DayEventType.NOT_AVAILABLE:
                if(props?.isTutor){
                    return <CellNotAvailable key={key} style={style} onClick={() => { onClickMethod(date, event) }}>
                            <div>
                                <span>{t("calendar_cell_not_available","N/D")}</span>
                            </div>
                        </CellNotAvailable>;
                }
                else{
                    return <CellBlocked key={key} style={style}><div/></CellBlocked>;
                }
            case DayEventType.BLOCKED:
                return <CellBlocked key={key} style={style} onClick={() => { onClickMethod(date, event) }}><div/></CellBlocked>;
            case DayEventType.BOOKING_ENTERPRISE:
            case DayEventType.BOOKING:
                return <CellBooking key={key} style={style} onClick={() => { onClickMethod(date, event) }}>
                    <div>
                        <span title={event.title != null ? event.title : ""}>{event.title}</span>
                    </div>
                </CellBooking>;
            case DayEventType.ABSENCE:
                if(props?.isTutor){
                    return <CellAbsence key={key} style={style}>
                            <div>
                                <div>{event.title}</div>
                            </div>
                        </CellAbsence>;
                } else {
                    return <CellBlocked key={key} style={style}><div/></CellBlocked>;
                }
        }
        return null;
    };

    const _renderTimeline = (key: string, style: any, )=>{
        return <Timeline key={key} style={style}>
            <span className={"time-line"}/><span className={"time-circle"}/>
        </Timeline>
    };

    // @ts-ignore
    const _cellRangeRenderer = ({ cellCache, cellRenderer, columnSizeAndPositionManager, columnStartIndex, columnStopIndex, horizontalOffsetAdjustment, isScrolling, rowSizeAndPositionManager, rowStartIndex, rowStopIndex, scrollLeft, scrollTop, styleCache, verticalOffsetAdjustment }) => {
        // @ts-ignore
        const renderedCells = [];

        // Dibuja la base de la grid
        for (let rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
            // This contains :offset (top) and :size (height) information for the cell
            let rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex);
            if (!rowDatum) { continue }

            for (let columnIndex = columnStartIndex; columnIndex <= columnStopIndex; columnIndex++) {
                // This contains :offset (left) and :size (width) information for the cell
                let columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(
                    columnIndex,
                );
                if (!columnDatum) { continue }

                // Be sure to adjust cell position in case the total set of cells is too large to be supported by the browser natively.
                // In this case, Grid will shift cells as a user scrolls to increase cell density.
                let left = columnDatum.offset + horizontalOffsetAdjustment;
                let top = rowDatum.offset + verticalOffsetAdjustment;

                // The rest of the information you need to render the cell are contained in the data.
                // Be sure to provide unique :key attributes.
                let key = `${rowIndex}-${columnIndex}`;
                let height = rowDatum.size;
                let width = columnDatum.size;

                let style = { left: left, height: height, position: 'absolute', top: top, width: width, zIndex: 1 };
                let cell = cellRenderer({ columnIndex, key, rowIndex, style, isScrolling, isVisible: true });
                renderedCells.push(cell);
            }
        }

        let multiplier = main.calendarService.getMultiplier() * rowHeight!;
        let startHour = main.calendarService.getStartHour();

        // Añadimos la linea del timeline
        for (let columnIndex = columnStartIndex; columnIndex <= columnStopIndex; columnIndex++) {
            let day = main.calendarService.getDayByIndex(columnIndex);
            if(day === undefined || day.date === undefined) continue;
            let today = new Date();
            let isSameDate = day.date?.getDate() === today.getDate() && day.date?.getMonth() === today.getMonth() && day.date?.getFullYear() === today.getFullYear();
            if (isSameDate) {
                 let columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(
                    columnIndex,
                 );
                 if (!columnDatum) { continue }

                let hoursOffset = today.getHours() + today.getMinutes() / 60 - startHour;
                let top = hoursOffset * multiplier + 5;
                let left = columnDatum.offset - columnDatum.size - 3 ;
                let width = columnDatum.size;
                let style = { left: left, height: 2, position: 'absolute', top: top, width: width, zIndex: 5 };
                let cell = _renderTimeline('timeline', style);
                renderedCells.push(cell);
            }
        }

        // Dibuja los eventos
        for (let columnIndex = columnStartIndex; columnIndex <= columnStopIndex; columnIndex++) {
            let columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(columnIndex);
            if (!columnDatum) { continue }
            let width = columnDatum.size;
            let day = main.calendarService.getDayByIndex(columnIndex);

            // Eventos semanales
            let weekevents = main.calendarService.getWeekDayEvents(columnIndex);
            if (weekevents) {
                for (let i = 0; i < weekevents.events.length; i++) {
                    let event = weekevents.events[i];
                    let left = columnDatum.offset + horizontalOffsetAdjustment - columnDatum.size;
                    let top = (event.initTimestamp! - startHour) * multiplier;
                    //let height = (event.endTimestamp! - event.initTimestamp!) * multiplier;
                    let baseHeight = 0.5 * multiplier;
                    let steps =  (event.endTimestamp! - event.initTimestamp!) / 0.5;
                    for(let step=0; step<steps; step++){
                        let e = new DayEvent(event.eventType, event.initTimestamp! + 0.5 * step, event.endTimestamp!, event.id, event.days);
                        let timeOffset = step * baseHeight;
                        let t = top + timeOffset;
                        let style = {left: left, height: baseHeight, position: 'absolute', top: t, width: width, zIndex: 2};
                        let cellRendered = _renderCellByEventType(event.id + columnIndex.toString() + step.toString(), day.date!, e, true, style, step);
                        renderedCells.push(cellRendered);
                    }
                }
            }

            // Eventos diarios
            if (day) {
                for (let i = 0; i < day.events.length; i++) {
                    let event = day.events[i];
                    let left = columnDatum.offset + horizontalOffsetAdjustment - columnDatum.size;
                    let top = (event.initTimestamp! - startHour) * multiplier;
                    let dif = event.endTimestamp! - event.initTimestamp!;
                    //esto significa que el evento es una free, necesitamos el 0.5 para que calcule bien el height por eso hacemos el recaluclo
                    if(dif === 0.25)
                        dif = 0.5;
                    let height = dif * multiplier;
                    if(!props?.isTutor && dif >= 1){
                        height = 0.5 * multiplier;
                        let init = event.initTimestamp!;
                        for(let x = 1; x <= dif / 0.5 ; x++){

                            let e = new DayEvent(event.eventType, event.initTimestamp! + 0.5 * (x - 1), event.endTimestamp!, event.id, event.days);
                            top = (init! + 0.5 * (x - 1) - startHour) * multiplier;

                            let style = { left: left, height: height, position: 'absolute', top: top, width: width, zIndex: 3 };
                            let cellRendered = _renderCellByEventType(event.id + x.toString(), day.date!, e, false, style);
                            renderedCells.push(cellRendered);
                        }
                    }
                    else{

                        let style = { left: left, height: height, position: 'absolute', top: top, width: width, zIndex: 3 };
                        let cellRendered = _renderCellByEventType(event.id + columnIndex.toString(), day.date!, event, false, style);
                        renderedCells.push(cellRendered);
                    }
                }
            }
        }

        // @ts-ignore
        return renderedCells;
    };

    const _cellClicked = (columnIndex: number, rowIndex: number) => {
        main.calendarService.selectedDate(columnIndex, rowIndex);
    };

    const _dayEventClicked = (date: Date, event: DayEvent, step?: number) => {
        if(event.eventType === DayEventType.BOOKING || event.eventType === DayEventType.BOOKING_ENTERPRISE){
            return
        }
        main.calendarService.selectEvent(date, event);
    };

    const _weekDayEventClicked = (date: Date, event: DayEvent, step?: number) => {
        if(event.initTimestamp == null)
            return;
        let initTime = event.initTimestamp;

        date.setHours(Math.trunc(initTime));
        date.setMinutes((initTime % 1).toFixed(1) === (0.5).toString() ? 30 : 0);
        main.calendarService.selectedDateByDate(date);
    };

    const _dayEventClickedStudent = (date: Date, event: DayEvent, step?: number) => {
        if(event.eventType === DayEventType.BOOKING || event.eventType === DayEventType.BLOCKED|| event.eventType === DayEventType.NOT_AVAILABLE ){
            return
        }
        if(event.initTimestamp == null || !props.tutorId)
            return;

        let initTime = event.initTimestamp;

        if(step!== undefined)
            initTime += step * 0.5;

        date.setHours(Math.trunc(initTime));
        date.setMinutes((initTime % 1).toFixed(1) === (0.5).toString() ? 30 : 0);
        let ticks = main.calendarService.dateToTicks(date);

        main.bookingService.addBooking(timeZoneOffset, props.tutorId, ticks);
    };

    const _getWeekDayName = (index: number) => {
        switch (index) {
            case WeekDay.MONDAY:
                return t("calendar_cell_day_1","Lun");
            case WeekDay.TUESDAY:
                return t("calendar_cell_day_2","Mar");
            case WeekDay.WEDNESDAY:
                return t("calendar_cell_day_3","Mié");
            case WeekDay.THURSDAY:
                return t("calendar_cell_day_4","Jue");
            case WeekDay.FRIDAY:
                return t("calendar_cell_day_5","Vie");
            case WeekDay.SATURDAY:
                return t("calendar_cell_day_6","Sáb");
            case WeekDay.SUNDAY:
                return t("calendar_cell_day_0","Dom");
        }
    };

    return (
        <Container>
            {ready ?
                <AutoSizer>
                    {({ height, width }) => (
                        <MultiGrid
                            ref={gridRef}
                            height={height}
                            width={width}
                            fixedColumnCount={1}
                            fixedRowCount={1}
                            enableFixedRowScroll
                            // @ts-ignore
                            cellRenderer={_cellRenderer}
                            cellRangeRenderer={_cellRangeRenderer}
                            onScroll={_onScroll}
                            scrollToRow={startingRow}
                            scrollToColumn={currentColumn}
                            scrollToAlignment={"start"}
                            columnWidth={(params: Index) => {
                                return params.index === 0 ? firstColumnWidth! : width / ((!props.isMobile ? 7 : 3) + 1)
                            }}
                            columnCount={columnsCount}
                            rowHeight={(params: Index) => {
                                return params.index === 0 ? firstRowHeight! : rowHeight!;
                            }}
                            rowCount={rowsCount}
                            hideTopRightGridScrollbar={true}
                            hideBottomLeftGridScrollbar={true}
                            hideBottomRightGridScrollbar={true}
                        />
                    )}
                </AutoSizer> :
                null}
        </Container>
    );
}

type CalendarGridProps = {
    rowHeight?: number
    firstRowHeight?: number,
    firstColumnWidth?: number,
    isTutor?: boolean,
    tutorId?: number
}

CalendarGrid.defaultProps = {};

const CalendarGridMemo = React.memo(_CalendarGrid);

export default function CalendarGrid(props: CalendarGridProps) {
    const [state] = useContext(UIContext)!;
    return (
        <CalendarGridMemo isMobile={state.isMobile} {...props} />
    )
}

