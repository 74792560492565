import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./i18n";
import CalendarPage from "./pages/calendar";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'NunitoSans';
        src: local('Nunito Sans'), url(https://fonts.gstatic.com/s/nunitosans/v6/pe0qMImSLYBIv1o4X1M8cce9I9s.woff2) format('woff2');
    }

    @font-face {
        font-family: 'NunitoSans';
        font-weight: 700;
        src: local('NunitoSans'), url(https://fonts.gstatic.com/s/nunitosans/v6/pe03MImSLYBIv1o4X1M8cc8GBs5tU1E.woff2) format('woff2');
    }
    @font-face {
        font-family: 'OpenSans';
        src: local('Open Sans'), url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1UpcaXcl0Aw.ttf) format('truetype');
    }    

    html{
        height: 100%;
    }

    body {
        margin: 0;
        height: 100%;
        width: 100%;
        position:relative;        
        font-family: ${process.env.REACT_APP_PROJECT === 'tus' ? "'OpenSans'" : "'NunitoSans'"};
    }

    #root{
        height: 100%;
        width: 100%;
    }

    ::-webkit-scrollbar-track {
        background: #fff;
        box-shadow: 0 0 3px #dfdfdf inset;
        border-radius: 2px
    }

    ::-webkit-scrollbar-thumb {
        background: #999;
        border-radius: 2px
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #7d7d7d
    }

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px
    }

    ::-webkit-scrollbar-button {
        width: 7px;
        height: 5px
    }
`;

function renderApp() {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(
    <>
      <GlobalStyle />
      <App />
    </>
  );
}

!process.env.REACT_APP_MICRO_FRONTEND && renderApp();

let rootMicro: any;

(window as any).renderCalendar = (containerId: string) => {
  const element = document.getElementById(containerId);
  if (!element) return;

  let meta = document.createElement("meta");
  meta.name = "version";
  meta.content = `${process.env.REACT_APP_VERSION}`;
  document.getElementsByTagName("head")[0].appendChild(meta);
  rootMicro = createRoot(element!);

  rootMicro.render(
    <>
      <GlobalStyle />
      <CalendarPage tutorId={0} />
    </>
  );
};

(window as any).unmountCalendar = () => {
  rootMicro.unmount();
};
