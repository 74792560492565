import { AbsenceStatus } from "./enums/absence";


export class Absence {
    public id: string;
    public status: AbsenceStatus;
    public creationDate: Date;
    public tutorId: number;
    public title: string;
    public initDate: Date;
    public endDate: Date;

  

    constructor(id: string, status: AbsenceStatus, creationDate: Date, tutorId: number, title: string, initDate: Date, endDate: Date) {
        this.id = id;
        this.status = status;
        this.creationDate = creationDate;
        this.title = title;
        this.tutorId = tutorId;
        this.initDate = initDate;
        this.endDate = endDate;
    }

     /** Deserialize Absence. */
     static parse(dict: any){
        let id = dict['Id'];
        let status = dict['Status'];
        let creationDate = dict['CreationDate'];
        let title = dict['Title'];
        let tutorId = dict['TutorId'];
        let initDate = dict['InitDate'];
        let endDate = dict['EndDate'];

        return new Absence(id, status, creationDate, tutorId, title, initDate, endDate);
    }
}