import {DayEvent} from "./day_event";
import {WeekDay} from "./enums/weekday";

export class WeekdayEvents {
    public weekday: WeekDay | null = null;
    public events: DayEvent [] = [];

    constructor(weekday: WeekDay, events: DayEvent[] = []) {
        this.weekday = weekday;
        this.events = events;
    }

    addEvent(index: number, event: DayEvent){
        this.events[index] = event;
    }
}