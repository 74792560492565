import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from 'styled-components';
import Icon from "../../icons/Icon";
import SimpleBtn from "../../buttons/SimpleBtn";
import {useTranslation} from "react-i18next";
import UIContext from "../../../../context/ui";
import MainContext from "../../../../context/main";
import { AbsencesEventListener } from "../../../../services/absence/absence";
import { Modal } from "@mui/material";
import {Config} from "../../../../constants/config";

const Content = styled.div<{visible: boolean, isMobile: boolean}>`
    left: ${props => props.isMobile ? "0" : "50%"};
    width: ${props => props.isMobile ? "100%" : "auto"};
    top: ${props => props.isMobile ? "initial" : "50%"};
    transform: ${props => props.isMobile ? "initial" : " translate(-50%, -50%);"};
    height: ${props => props.isMobile ? "initial" : "fit-content"};
    bottom: ${props => props.visible ? "0" : "-500px"};
    position: absolute;
    border: none !important;
    background-color: white;
    border-radius:${props => props.isMobile ? "5px 5px 0 0" : "5px 5px 5px 5px"};
    outline: none;
    padding: 0 0 2em;
    transition: bottom 250ms;
    max-width: ${props => props.isMobile ? "initial" : "360px"};
    max-height: ${props => props.isMobile ? "initial" : "300px"};
    color: ${props => props.theme.fontColors.primary};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;

`;

const LeftContainer = styled.div<{ isMobile: boolean}>`
    width: 100%;
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    text-align: left;
    justify-content: ${props => props.isMobile ? "flex-start" : "flex-end"};
    padding: 1em 1em 0;
`;

const IconWrapper = styled.div`
    width: 22px;
    height: 22px;
    cursor:pointer;
    
     > svg{
         fill: ${props => props.theme.fontColors.primary + " !important"};
         stroke: ${props => props.theme.fontColors.primary  + " !important"};
     }
    
     :hover > svg{
         fill: ${props => props.theme.fontColors.accent + " !important"};
         stroke: ${props => props.theme.fontColors.accent  + " !important"};
      }
`;

const Inner = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  padding-left: 2em;
  padding-right: 2em;
  text-align: center;
  padding-top: ${props=>props.isMobile? "initial":"2em"};
`;

const Title = styled.p`
  font-size: ${props => props.theme.fontSizes.m};
  color:  ${props => props.theme.fontColors.primary};
  font-weight: bold;
  margin: 0.5em 0 0;
`;

const Date = styled.p`
    font-size: ${props => props.theme.fontSizes.s};
    color:  ${props => props.theme.fontColors.primary};
    margin: 1em 0 0;

    :first-letter {
    text-transform: uppercase;
    }
`;

const Hour = styled.p`
    font-size: ${props => props.theme.fontSizes.l};
    color:  ${props => props.theme.fontColors.primary};
    font-weight: bold;
    margin-top: 0.2em;
`;

const BtnWrapper = styled.div<{ isMobile: boolean}>`
    height: 2em;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0.5em;

    >div{
    padding: 0.25em 1em;
    }
`;

type ModalDeleteAbsenceProps = {open: boolean, handleClose?: any, handleDelete?: any, title: string, dateStr: string, id:string}

ModalDeleteAbsence.defaultProps = {open: false, handleClose: null, weekDay: null};

export default function ModalDeleteAbsence(props: ModalDeleteAbsenceProps){
    const {t} = useTranslation();
    const [theme] = useState(Config.get().THEME);
    const [open, setOpen] = useState(props.open);
    const [contentVisible, setContentVisible] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [state] = useContext(UIContext)!;
    const main = useContext(MainContext)!;

    const _close = useCallback(() => {
        setContentVisible(false);
        if(!state.isMobile){
            props.handleClose();
            setOpen(false);
        }
        else{
            setTimeout(()=>{
                props.handleClose();
                setOpen(false);
            }, 250);
        }
    }, [state.isMobile, props]);

    useEffect(() => {
        let listener = new class implements AbsencesEventListener {
            onAbsenceDeleted(): void {
                props.handleDelete();
                _close();
            }
        }();
        main.absenceService.subscribeToEvents(listener);
        // Unbind the listener when the component unmounts
        return () => {
            main.absenceService.unSubscribeToEvents(listener);
        }
    }, [main.absenceService, props, _close]);

    const _delete = ()=>{
        main.absenceService.deleteAbsence(props.id);
        setDeleting(true);
    };

    useEffect(()=>{
        if(!open) {return}
        setTimeout(()=>{
            setContentVisible(open);
        }, 10)
    }, [open]);

    return (
        <Modal open={open} onClose={_close}>
            <Content visible={contentVisible} isMobile={state.isMobile}>
                <Header>
                    <LeftContainer isMobile={state.isMobile}>
                        <IconWrapper onClick={_close}>
                            <Icon name={'close'}/>
                        </IconWrapper>
                    </LeftContainer>
                </Header>
                <Inner isMobile={state.isMobile}>
                    <Title>{t('calendar_eliminar_ausencia', 'Eliminar ausencia')}</Title>
                    <Date>{props.title}</Date>
                    <Hour>{props.dateStr}</Hour>
                    <BtnWrapper isMobile={state.isMobile}>
                        <SimpleBtn style={theme.buttonColors.primary.unselected}
                                   title={t('calendar_eliminar_rango de horas', "Eliminar rango de horas")}
                                   collapsible={false}
                                   smallIcon={false}
                                   loading={deleting}
                                   clicked={_delete} />
                    </BtnWrapper>
                </Inner>
            </Content>
        </Modal>
    )
}