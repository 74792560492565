
export enum WeekDay {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
}

export const getOrder = (weekday: WeekDay) =>{
    if(weekday === WeekDay.SUNDAY) {
        return 6;
    }
    return weekday - 1;
};
