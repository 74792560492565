import React, {useContext, useEffect, useState} from "react";
import styled from 'styled-components';
import Icon from "../../icons/Icon";
import {CalendarListener} from "../../../../services/calendar/calendar";
import MainContext from "../../../../context/main";
import {DayEvent} from "../../../../types/day_event";
import SimpleBtn from "../../buttons/SimpleBtn";
import {useTranslation} from "react-i18next";
import UIContext from "../../../../context/ui";
import SelectHours from "../../menu/ContainerSelects";
import { Modal } from "@mui/material";
import {Config} from "../../../../constants/config";

const Content = styled.div<{visible: boolean, isMobile: boolean}>`
  left: ${props => props.isMobile ? "0" : "50%"};
  width: ${props => props.isMobile ? "100%" : "auto"};
  top: ${props => props.isMobile ? "initial" : "50%"};
  transform: ${props => props.isMobile ? "initial" : "translate(-50%, -50%);"};
  height: ${props => props.isMobile ? "initial" : "fit-content"};
  bottom: ${props => props.visible ? "0" : "-500px"};
  position: absolute;
  border: none !important;
  background-color: white;
  border-radius:${props => props.isMobile ? "5px 5px 0 0" : "5px 5px 5px 5px"};
  outline: none;
  padding: 0 0 2em;
  transition: bottom 250ms;
  color: ${props => props.theme.fontColors.primary};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const LeftContainer = styled.div<{isMobile: boolean}>`
    width: 100%;
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    text-align: left;
    justify-content:  ${props => props.isMobile ? "flex-start" : "flex-end"};
    padding: 1em 1em 0;
`;

const IconWrapper = styled.div`
    width: 22px;
    height: 22px;
    cursor:pointer;

    > svg{
        fill: ${props => props.theme.fontColors.primary + " !important"};
        stroke: ${props => props.theme.fontColors.primary  + " !important"};
    }

    :hover > svg{
        fill: ${props => props.theme.fontColors.accent + " !important"};
        stroke: ${props => props.theme.fontColors.accent  + " !important"};
    }
`;

const Inner = styled.div<{isMobile: boolean}>`
    display: flex;
    flex-direction: column;
    padding-left: 2em;
    padding-right: 2em;
    text-align: center;
    padding-top: ${props=>props.isMobile? "initial":"2em"};
`;

const Title = styled.p`
    font-size: ${props => props.theme.fontSizes.m};
    color:  ${props => props.theme.fontColors.primary};
    font-weight: bold;
    margin: 0.5em 0 0;
`;

const DateP = styled.p`
    font-size: ${props => props.theme.fontSizes.s};
    color:  ${props => props.theme.fontColors.primary};
    margin: 0.8em 0 0;

    :first-letter {
      text-transform: uppercase;
    }
`;

const BtnWrapper = styled.div`
  height: 2em;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0.5em;

  >div{
    padding: 0.25em 1em;
  }
`;

const SelectWrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`;

const Select = styled.div`
    max-width: 220px;
    margin: 0 auto;

`;
type ModalEventDeleteProps = {open: boolean, handleClose?: any}

ModalEventDelete.defaultProps = {open: false, handleClose: null};

export default function ModalEventDelete(props: ModalEventDeleteProps){
    const {t} = useTranslation();
    const [theme] = useState(Config.get().THEME);
    const main = useContext(MainContext)!;
    const [open, setOpen] = useState(props.open);
    const [contentVisible, setContentVisible] = useState(false);
    const [date, setDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [oldDate, setOldDate] = useState<Date | null>(null);
    const [oldEndDate, setOldEndDate] = useState<Date | null>(null);
    const [event, setEvent] = useState<DayEvent | null>(null);
    const [oldTicksInit, setOldTicksInit] = useState<number>(0);
    const [oldTicksEnd, setOldTicksEnd] = useState<number>(0);
    const [ticksInit, setTicksInit] = useState<number>(0);
    const [ticksEnd, setTicksEnd] = useState<number>(0);
    const [deleting, setDeleting] = useState(false);
    const [state] = useContext(UIContext)!;

    const _delete = ()=>{
        setDeleting(true);
        if(event?.initTimestamp == null || event?.endTimestamp == null)
            return;

        if(date == null || endDate == null)
            return;

        main.calendarService.deleteEvent(main.calendarService.ticksToDate(oldTicksInit), event.id);
        main.calendarService.publishOnRefreshNeeded();

        if(oldTicksInit === ticksInit && oldTicksEnd === ticksEnd){
            main.calendarService.deleteEvents(ticksInit, ticksEnd);
        } else {
            let timeOld = 0;
            let timeNew = 0;
            let dur = 0;

            if(oldTicksInit !== ticksInit){
                timeOld = event.initTimestamp;
                timeNew = date.getHours() +( date.getMinutes() === 30 ? 0.5 : 0);
                dur = timeNew - timeOld;
                main.calendarService.updateEvent(oldTicksInit, oldTicksInit, event, main.calendarService.ticksToDate(oldTicksInit), dur);
            }

            if(oldTicksEnd !== ticksEnd){
                //creamos evento de newticksEnd a oldTicksEnd
                timeOld = event.endTimestamp;
                timeNew = endDate.getHours() + ( endDate.getMinutes() === 30 ? 0.5 : 0);
                dur = (timeOld - timeNew) * 60;

                main.calendarService.deleteEvents(ticksInit, ticksEnd);
                main.calendarService.addEvents(0, ticksEnd, oldTicksEnd, event.eventType, (timeOld - timeNew) , endDate);
            }
        }
        _close();
    };

    useEffect(() => {
        let listener = new class implements CalendarListener {
            onEventsDeleted(): void {
                setDeleting(false);
            }
        }();
        main.calendarService.subscribeToEvents(listener);
        return () => {
            main.calendarService.unSubscribeToEvents(listener);
        }
    }, [main.calendarService]);

    const _close = function () {
        setDeleting(false);
        setContentVisible(false);
        if(state.isMobile)
            setTimeout(()=>{
                setOpen(false);
            }, 250);
        else
            setOpen(false);

        props.handleClose();
    };

    useEffect(()=>{
        if(!open) {return}
        setTimeout(()=>{
            setContentVisible(open);
        }, 10)
    }, [open]);

    useEffect(()=>{
        let listener = new class implements CalendarListener{
            onSelectDateDelete(date: Date, duration: number, event: DayEvent){
                let dateInit = new Date(date);
                dateInit.setSeconds(0);
                dateInit.setMilliseconds(0);

                setDate(dateInit);
                setOldDate(dateInit);
                let dateEnd = new Date(date);
                dateEnd.setHours(dateEnd.getHours() + duration);
                dateEnd.setMinutes(dateEnd.getMinutes() + ((duration % 1).toFixed(1) === '0.5' ? 30 : 0 ));
                setEndDate(dateEnd);
                setOldEndDate(dateEnd);
                setTicksInit(main.calendarService.dateToTicks(dateInit));
                setOldTicksInit(main.calendarService.dateToTicks(dateInit));
                setTicksEnd(main.calendarService.dateToTicks(dateEnd));
                setOldTicksEnd(main.calendarService.dateToTicks(dateEnd));
                setEvent(event);
                setOpen(true);
            }
        }();
        main.calendarService.subscribeToEvents(listener);
        return () => {
            main.calendarService.unSubscribeToEvents(listener);
        }
    }, [main.calendarService]);

    return (
        <Modal open={open} onClose={_close}>
            <Content visible={contentVisible} isMobile={state.isMobile}>
                <Header>
                    <LeftContainer isMobile={state.isMobile}>
                        <IconWrapper onClick={_close}>
                            <Icon name={'close'} disableFill={false}/>
                        </IconWrapper>
                    </LeftContainer>
                </Header>
                {date ?
                    <Inner isMobile={state.isMobile}>
                        <Title>{t('calendar_quieres_eleminar_la_disponibilidad', '¿Quieres eliminar la disponibilidad?')}</Title>
                        <DateP>{main.calendarService.getDateFormatted(date, 'dddd DD [de] MMMM')}</DateP>
                        <SelectWrapper>
                            <Select>

                                <SelectHours addMoreRanges={false} day={date.getDay()} hour1={main.calendarService.getTimeStampByDate(date)}
                                             hour2={endDate ? main.calendarService.getTimeStampByDate(endDate) : undefined}
                                             editHandler={(hour1: number, hours: number)=>{
                                                 let date1 = new Date(date);
                                                 date1.setHours(hour1, hour1 % 1 === 0.5 ? 30 : 0);
                                                 setDate(date1);
                                                 let date2 = new Date(date1);
                                                 date2.setHours(date2.getHours() + hours, date2.getMinutes() + (hours % 1 === 0.5 ? 30 : 0));
                                                 setEndDate(date2);
                                                 setTicksInit(main.calendarService.dateToTicks(date1));
                                                 setTicksEnd(main.calendarService.dateToTicks(date2));
                                             }}
                                             hourLimitBefore = {oldDate ? main.calendarService.getTimeStampByDate(oldDate) : undefined}
                                             hourLimit = {oldEndDate ? main.calendarService.getTimeStampByDate(oldEndDate) : undefined}/>
                            </Select>
                        </SelectWrapper>
                        <BtnWrapper>
                            <SimpleBtn style={theme.buttonColors.primary.unselected}
                                       title={t('calendar_eliminar_disponibilidad', "Eliminar disponibilidad")}
                                       collapsible={false}
                                       smallIcon={false}
                                       loading={deleting}
                                       clicked={_delete} />
                        </BtnWrapper>
                    </Inner>
                    : null
                }
            </Content>
        </Modal>
    )
}