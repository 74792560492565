import React from 'react';

export interface IUIContext {
    menuVisible: boolean,
    regularScheduleVisible: boolean,
    isMobile: boolean
}

export const UICONTEXT_DEFAULT = {
    menuVisible: false,
    regularScheduleVisible: false,
    isMobile: false
};

enum UIContextTypes {
    SetMenuVisible = 'SetMenuVisible',
    SetRegularScheduleVisible = 'SetRegularScheduleVisible',
    SetIsMobile = 'SetIsMobile'
}

type SetMenuVisibleAction = {
    type: typeof UIContextTypes.SetMenuVisible
    visible: boolean
}

type SetRegularScheduleVisible = {
    type: typeof UIContextTypes.SetRegularScheduleVisible
    visible: boolean
}

type SetIsMobileAction = {
    type: typeof UIContextTypes.SetIsMobile
    is: boolean
}

type UIContextActionTypes = SetMenuVisibleAction | SetRegularScheduleVisible | SetIsMobileAction


export const setMenuVisible = (visible: boolean): UIContextActionTypes => ({
    type: UIContextTypes.SetMenuVisible,
    visible,
});

export const setRegularScheduleVisible = (visible: boolean): UIContextActionTypes => ({
    type: UIContextTypes.SetRegularScheduleVisible,
    visible,
});

export const setIsMobile = (is: boolean): UIContextActionTypes => ({
    type: UIContextTypes.SetIsMobile,
    is,
});

const UIContext = React.createContext<[IUIContext, React.Dispatch<UIContextActionTypes>]>([
        UICONTEXT_DEFAULT,
        ()=>{}
    ]
);
export default UIContext;

export const reducer = (state: IUIContext, action: UIContextActionTypes): IUIContext => {
    switch (action.type) {
        case UIContextTypes.SetMenuVisible:
            return {...state, menuVisible: action.visible};
        case UIContextTypes.SetRegularScheduleVisible:
            return {...state, regularScheduleVisible: action.visible};
        case UIContextTypes.SetIsMobile:
            return {...state, isMobile: action.is};
        default:
            // @ts-ignore
            throw new Error(`Unhandled action type: ${action.type}`)
    }
};
