import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import { getOrder, WeekDay } from "../../../types/enums/weekday";
import SelectHours from "./ContainerSelects";
import MainContext from "../../../context/main";
import { WeekPointsEventListener } from "../../../services/weekPoints/weekPoints";

const Container = styled.div`
    height: 100%;
    margin-top: 16px;
    color: ${props => props.theme.fontColors.primary};
`;

const ContainerWeekDay = styled.div`
    height: 100%;
    margin-top: 16px;
`;

const Day = styled.div`
    font-size:16px;
    display: inline-grid;
    cursor:pointer;
    border-radius: 4px;
    color: ${props => props.theme.regularschedule.day.color};
    padding: 18px 8px;
    line-height: 0;
    border: 1px solid #D3D9DE;
    &.selected{
        background-color: ${props => props.theme.regularschedule.day.selected.background};
         color: ${props => props.theme.regularschedule.day.selected.color};
        border: 1px solid ${props => props.theme.fontColors.accent};
        font-weight:bold;
    }
    :last-child{
        margin-right: 0;
    }
`;

const Tip = styled.div`
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top:28px;
`;

const ContainerLetters = styled.div`
    margin-top:20px;
    display: flex;
    gap: 6px;
`;

const ContainerActiveDays = styled.div`
    margin-top:5px;
    margin-left: 0.5em;   
`;
const TitleDay = styled.div`
    color: ${props =>  props.theme.fontColors.primary};

`;

type RegularScheduleProps = {}

RegularSchedule.defaultProps = {};

class WeekDays {
    public day:WeekDay;
    public firstLetter:String;
    public name:String;
    constructor() {
        this.day = WeekDay.MONDAY;
        this.firstLetter = '';
        this.name = '';
    }
}

class PointsByDay {
    public day:WeekDay;
    public points : number[];
    constructor() {
        this.day = WeekDay.MONDAY;
        this.points = [];
    }
}

export default function RegularSchedule(props: RegularScheduleProps){
    const { t } = useTranslation();
    const [activeDays, setActiveDays] = useState<WeekDay[]>([]);
    const [timeZoneOffset] = useState<Number>(new Date().getTimezoneOffset());
    const [pointsDic, setPointsDic] = useState<PointsByDay[]>([
        {day : WeekDay.MONDAY, points : []},
        {day : WeekDay.TUESDAY, points : []},
        {day : WeekDay.WEDNESDAY, points : []},
        {day : WeekDay.THURSDAY, points : []},
        {day : WeekDay.FRIDAY, points : []},
        {day : WeekDay.SATURDAY, points : []},
        {day : WeekDay.SUNDAY, points : []}]);

    const [allDays] = useState<WeekDays[]>([
        {day : WeekDay.MONDAY, firstLetter : t("days_monday_min","Lu"), name : t("days_monday","Lunes")},
        {day : WeekDay.TUESDAY, firstLetter : t("days_tuesday_min","Ma"), name : t("days_tuesday","Martes")},
        {day : WeekDay.WEDNESDAY, firstLetter : t("days_wednesday_min","Mi"), name : t("days_wednesday","Miércoles")},
        {day : WeekDay.THURSDAY, firstLetter : t("days_thursday_min","Ju"), name : t("days_thursday","Jueves")},
        {day : WeekDay.FRIDAY, firstLetter : t("days_friday_min","Vi"), name : t("days_friday","Viernes")},
        {day : WeekDay.SATURDAY, firstLetter : t("days_saturday_min","Sa"), name : t("days_saturday","Sábado")},
        {day : WeekDay.SUNDAY, firstLetter : t("days_sunday_min","Do"), name : t("days_sunday","Domingo")}]);
    //por cada dia necesitamos saber cuantos selects hemos abierto
    const main = useContext(MainContext)!;

    const _load = useCallback(() => {
        main.weekPointService.getWeekPoints(timeZoneOffset);
    }, [main.weekPointService, timeZoneOffset]);

    useEffect(() => {
        let listener = new class implements WeekPointsEventListener {
            onWeekPointsGet(_points : PointsByDay[]): void {
                setPointsDic(_points);
                allDays.forEach(element => {

                    if(_points.find(x => x.day === element.day)?.points.length){

                        activeDays.push(element.day);
                        setActiveDays([...activeDays.sort(compare)]);
                    }
                });
            }
        }();
        main.weekPointService.subscribeToEvents(listener);
        _load();
        return () => {
            main.weekPointService.unSubscribeToEvents(listener);
        }

        //Ignorar el warning de activeDays, pues sino entra en bucle.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [main.weekPointService, _load, allDays]);

    const toggleSelected = (weekday: WeekDay) =>{
        if(activeDays.indexOf(weekday) === -1){
            activeDays.push(weekday);
            let a = activeDays.sort(compare);
            setActiveDays([...a])
        } else {
            setActiveDays(activeDays.filter(item => item !== weekday));
            updatePoints([], weekday);
        }
    };

    const updatePoints = function (points: number[], weekday: WeekDay) {
        main.setNeedRefresh(true); 

        let a = main.weekPointService.setPoints(points, weekday);
        setPointsDic([...a!]);

        let allPoints : Number[] = [];

        pointsDic.forEach(function(curr){
        allPoints = allPoints.concat(curr.points);
        });

        main.weekPointService.setWeekPoints(allPoints, timeZoneOffset);
    };

    const printLettersWeek = function () {
        return allDays.map(element => {
            let classActive = activeDays.indexOf(element.day) !== -1 ? "selected" : "";
            return (<Day key={element.day} onClick={()=>{toggleSelected(element.day)}} className={classActive}>{element.firstLetter}</Day>)
        });
    };

    function compare( a:any, b:any ) {
        a = getOrder(a);
        b = getOrder(b);

        if ( a > b )
            return 1;

        if ( a < b )
            return -1;

        return 0;
    }

    const renderActiveDays = function () {
        return activeDays.map(element => {
            return (
                <ContainerWeekDay key={element} >
                    <TitleDay>{allDays.filter(x=> x.day === element)[0].name}</TitleDay>
                    <SelectHours addMoreRanges={true}  day={element}
                                 points={pointsDic.filter(x=>x.day === element)[0].points}

                                 emptyHandler = {(day: WeekDay)=>{
                                     setActiveDays(activeDays.filter(item => item !== day));
                                 }}

                                 editHandler ={(points: number[], day: WeekDay)=>{
                                     updatePoints(points, day);
                                 }}/>
                </ContainerWeekDay>
            )
        });
    };

    return (
        <Container>
            <Tip dangerouslySetInnerHTML={{__html:t("calendar_regular_schedule_tip","<b>Selecciona día y luego rango de horas, se repetirán semana a semana</b> en tu calendario.")}}></Tip>
            <ContainerLetters>
                {printLettersWeek()}
            </ContainerLetters>
            <ContainerActiveDays>
                {renderActiveDays()}
            </ContainerActiveDays>
        </Container>
    )
}