import { styled, Theme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const IOSSwitch = styled((props: any) => (    
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }: { theme: Theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'inherit',
    '& .MuiSwitch-switchBase': {
        padding: 1,
        left: '1px',
        top: '1px',
        color: '#999',
        '& + .MuiSwitch-track': {
            border: '1px solid #999',
        },
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            left: '0',
            top: '0',
            '& + .MuiSwitch-track': {
                backgroundColor: '#3AAC8B',
                opacity: 1,
                border: 'none',
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#3AAC8B',
            border: '6px solid #fff',
        },
    },
    '& .MuiSwitch-thumb': {
        width: 24,
        height: 24,
    },
    '& .MuiSwitch-track': {
        borderRadius: 13,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
}));
