import ParentService, {ParentServiceListener} from "../parent";
import 'moment/locale/es';
import API from "../api/api";
import { getOrder, WeekDay } from "../../types/enums/weekday";
import { SelectHour } from "../../types/select_hour";

class PointsByDay {
    public day:WeekDay;
    public points : number[];
    constructor() {
      this.day = WeekDay.MONDAY;
      this.points = [];
    }
}

export class WeekPointsService extends ParentService{

    private pointsDic : PointsByDay[] = ([
        {day : WeekDay.MONDAY, points : []},
        {day : WeekDay.TUESDAY, points : []},
        {day : WeekDay.WEDNESDAY, points : []},
        {day : WeekDay.THURSDAY, points : []},
        {day : WeekDay.FRIDAY, points : []},
        {day : WeekDay.SATURDAY, points : []},
        {day : WeekDay.SUNDAY, points : []}]);

    private hours : SelectHour[] = [];
    
    compare( a:any, b:any ) {
        a = getOrder(a);
        b = getOrder(b);

        if ( a > b )
            return 1;

        if ( a < b )
            return -1;
       
        return 0;
    }

    getHourFromPoint(point : number, day: WeekDay){
        return  (point - (288 * day)) / 12;
    };

    setPoints(points : number[], day: WeekDay) {
        let temp = this.pointsDic;
        let a = this.pointsDic.find(x=>x.day === day);

        if(a == null) return;

        a.points = points;

        temp = temp.filter(x=>x.day!== day);
        temp.push(a);

        this.pointsDic = temp.sort(this.compare);

        return this.pointsDic;
    };

    //PointsByDay[]
    getWeekPoints(timeZoneOffset : Number){
        let that = this;

        if(this.pointsDic !==null)
            return that.publishOnWeekPointsGet(this.pointsDic);

        API.getWeekPoints(timeZoneOffset).then((json: any) => {
            let points = json["Points"];
            for (let item in WeekDay) {
                let points2 = points.filter((x: number)=>x < 288 * (+item + 1) && x >= 288 * + item);
                
                if(points.length > 0)
                    this.setPoints(points2, +item);
            }
            that.publishOnWeekPointsGet(this.pointsDic);
        }).catch(error => {
            //todo
        })
    }

    // SelectHour[]
    getWeekPointsSelectHour(timeZoneOffset : Number, forceCharge : boolean){
        let that = this;
        if(this.hours !==null && this.hours.length && !forceCharge)
            return that.publishOnWeekPointsGetSelectHour(that.hours);

        that.hours=[];

        API.getWeekPoints(timeZoneOffset).then((json: any) => {
            //Alomejor antes de publicar los puntos podriamos sacar las horas
            let points = json["Points"];
            for (let item in WeekDay) {
                let points2 = points.filter((x: number)=>x < 288 * (+item + 1) && x >= 288 * + item);
                
                if(points.length > 0)
                    this.setPoints(points2, +item);
            }

            for (let item in WeekDay) {
                let pointsArr = that.pointsDic.find(x => x.day === +item )?.points;
                if(pointsArr == null)
                    continue;

                let a = (that.getSelectHourFromWeekPoints(pointsArr, +item));
                if(a == null)
                    continue;

                that.hours = that.hours.concat(a);
            }
            that.publishOnWeekPointsGetSelectHour(that.hours);
        }).catch(error => {
            //todo
        })
    }

    add(val1 : number, val2:number, points:number[], day: WeekDay){
        let hour1 = this.getHourFromPoint(val1, day);
        let hour2 = this.getHourFromPoint(val2, day) + 0.5;
        let minutes1= hour1 - Math.floor(hour1) > 0 ? 30 : 0;
        let minutes2= hour2 - Math.floor(hour1) > 0 ? 30 : 0;
        let date1 = new Date(2021,12,undefined,Math.trunc(hour1),minutes1, 0);
        let date2 = new Date(2021,12,undefined,Math.trunc(hour2),minutes2, 0);
        return {hour1: hour1, hour2: hour2, error: false, id: val1, points: points, date1: date1, date2: date2, day: day}
    };

    getSelectHourFromWeekPoints(points : number[], day: WeekDay){
      if(points === null || points.length === 0)
            return;

      let pointsDay : number[] = [];
      let result : SelectHour[] = [];
      
      // To order and remove duplicates
      points.sort((a,b) => {return a - b});
      points = points.filter((value, index, array) => array.indexOf(value) === index);

      // To group points into ranges hours
      pointsDay.push(points[0]);
      for (let i = 0; i < points.length - 1; ++i) {
        let currentPoint = points[i], nextPoint = points[i+1];
        if (nextPoint - currentPoint === 6) {
          pointsDay.push(nextPoint);
        } else {
          result.push(this.add(pointsDay[0], pointsDay[pointsDay.length-1], pointsDay, day));
          pointsDay = [nextPoint];
        }
      }

      result.push(this.add(pointsDay[0], pointsDay[pointsDay.length-1], pointsDay, day));
      return result;
    }

    setWeekPoints( points : Number[], timeZoneOffset : Number){
        let that = this;
        API.setWeekPoints(points, timeZoneOffset).then(() => {
            that.publishOnWeekPointsSet();
        }).catch(error => {
            console.log(error);
        })
    }
    
    /** @ignore Publish week points loaded event to all elements subscribed. */
    protected publishOnWeekPointsGet(points : PointsByDay[]){
        this.subscribers.forEach(function (listener) { (listener as WeekPointsEventListener).onWeekPointsGet?.(points); })
    }

    /** @ignore Publish week points loaded event to all elements subscribed. */
    protected publishOnWeekPointsGetSelectHour(points : SelectHour[]){
        this.subscribers.forEach(function (listener) { (listener as WeekPointsEventListener).onWeekPointsGetSelectHour?.(points); })
    }

    /** @ignore Publish documents loaded event to all elements subscribed. */
    protected publishOnWeekPointsSet(){
        this.subscribers.forEach(function (listener) { (listener as WeekPointsEventListener).onWeekPointsSet?.(); })
    }
}

export interface WeekPointsEventListener extends ParentServiceListener{
    /** Notifies when week points are loaded. */
    onWeekPointsGet?(points : PointsByDay[]): void;
    /** Notifies when week points are loaded. */
    onWeekPointsGetSelectHour?(points : SelectHour[]): void;
    /** Notifies when absences has been loaded. */
    onWeekPointsSet?(): void;
}
