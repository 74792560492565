import React, {useContext, useState} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import UIContext, {setMenuVisible} from "../../../context/ui";
import MainContext from "../../../context/main";
import CalendarGrid from "../calendar_grid/GridTable";
import SmallCalendar from "../small_calendar/SmallCalendar";
import LegendHeader from "../legend/Legend";
import Icon from "../icons/Icon";

const MonthText = styled.p`
  width: auto;
  height: auto;
  margin: 0;
  color: ${props => props.theme.fontColors.accent};
  cursor: pointer;
  font-weight: bold;
  display: inline-flex;
  padding: 0 !important;
  :hover{
    color: ${props => props.theme.fontColors.accentDark};
  }
`;

const LeftContainer = styled.div<{isMobile:boolean}>`
  width: auto;
  height: auto;
  flex: ${props => props.isMobile ? "2" : "1"};;
  display: flex;
  flex-direction: row;
  align-items: center;

  text-align: left;
  justify-content: flex-start;
  padding: ${props => props.isMobile ? "0.75em" : "1em"};
`;

const RightContainer = styled.div<{isMobile:boolean}>`
  width: 100%;
  height: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  text-align: right;
  justify-content: flex-end;
  padding: ${props => props.isMobile ? "0.75em 0.75em 0.75em 0" : "1em"};
  align-items: baseline;
`;

const IconWrapper = styled.div<{isMobile:boolean}>`
  width: 24px;
  height: 24px;
  margin-left: 1.5em;
  cursor:pointer;
  :hover path{
    fill: ${props => props.theme.fontColors.accent + " !important"};
  }
  > svg{
    fill: ${props => props.theme.iconWrapper.calendar.svg.color + " !important"};
    stroke: ${props => props.theme.iconWrapper.calendar.svg.color  + " !important"};
  }

  :hover > svg{
    fill: ${props => props.theme.iconWrapper.calendar.svg.hoverColor + " !important"};
    stroke: ${props => props.theme.iconWrapper.calendar.svg.hoverColor  + " !important"};
  }
`;

const IconWrapperArrow = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 0.75em;
  cursor:pointer;
  display: inline-flex;
  > svg{
    fill: ${props => props.theme.fontColors.accent + " !important"};
    stroke: ${props => props.theme.fontColors.accent  + " !important"};
  }
`;

const SubHeader = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const CalendarWrapper = styled.div`
  width: 100%;
  height: 800px;
`;

const SmallCalendarWrapper = styled.div<{show:boolean, isMobile:boolean}>`
  width: calc(100% - 2em);
  padding: 1em;
  display:${props => props.show ? "initial" : "none"};
`;

const RightContainerWeek = styled.div`
  width: 100%;
  height: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  text-align: right;
  justify-content: flex-end;
  padding: 1em;
  align-items: center;
`;

const IconWrapperWeek = styled.div`
  width: 16px;
  height: 16px;
  cursor:pointer;
  display: inline-flex;
  > svg{
    fill: ${props => props.theme.arrow.color + " !important"};
    stroke: ${props => props.theme.arrow.color + " !important"};
  }
`;

const Week = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  color: ${props => props.theme.fontColors.accent};
  margin: 0 1em;  
`;

const LegendContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const IconTooltip = styled.div`
    width: 16px;
    height: 16px;
    margin-right: 6px;

    :hover > p {
        visibility: visible;
        opacity: 1;
    }
`;

const TooltipText = styled.p`
  left: 0%;
  padding: 2em 1em;
  position: absolute;
  visibility: hidden;
  z-index: 1;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  background-color: #2A3F55;
  border-radius: 6px;
  margin: 4px 14px 0;

  ::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 95%;
      margin-left: -5px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent #2A3F55 transparent;
  }
`;

type CalendarComponentProps = {
    tutorId?: number,
    dispatch: any
}

CalendarComponent.defaultProps = {};

export default function CalendarComponent(props: CalendarComponentProps){
    const main = useContext(MainContext)!;
    const [isTutor] = useState(!props.tutorId || props.tutorId === 0);
    const [state] = useContext(UIContext)!;
    const [date, setDate] = useState(main.calendarService.correctDate(new Date()));
    const {t} = useTranslation();
    const [showSmallCalendar, setShowSmallCalendar] = useState(false);       

    const _goToCurrDate = () =>{
        let newDate = main.calendarService.correctDate(new Date());
        setDate(newDate);
        main.calendarService.goToDate(newDate);
    };

    const _addWeek = () =>{
        let newDate = new Date(date.setHours(date.getHours() + 168));
        setDate(newDate);
        main.calendarService.goToDate(newDate);
    };

    const _removeWeek = () =>{
        let newDate = new Date(date.setHours(date.getHours() - 168));
        setDate(newDate);
        main.calendarService.goToDate(date);
    };

    const _openSmallCalendar = () =>{
        setShowSmallCalendar(!showSmallCalendar);
    };

    const _openConfiguration = () =>{
      props.dispatch(setMenuVisible(true));
    };
    
    return (
        <>
            <LegendContainer>
                <LegendHeader isTutor={isTutor}/>

                {!state.isMobile ?
                    <RightContainer isMobile={state.isMobile}>
                        <IconWrapper isMobile={state.isMobile} title={t("calendar_go_current_day","Volver al día de hoy")} onClick={()=>{_goToCurrDate()}}>
                            <Icon name={'today_calendar'}/>
                        </IconWrapper>
                    </RightContainer>
                    :
                    <IconTooltip>
                        <Icon name={'info_circle'} disableFill={false}/>
                        <TooltipText dangerouslySetInnerHTML={{__html: t("info_tooltip", "<b>Horario frecuente</b><br/>Define las horas habituales en las que das clases.<br/>Se repetirá semana a semana.<br/><br/><b>Disponible / No disponible</b><br/>Crea, edita o elimina horas de tu día a día. Disponibilidad en días sueltos, no se repetirán.<br/>También puedes añadir un no disponible dentro de un horario frecuente para una semana específica, sin que modifique tu horario habitual.<br/><br/><b>Ausencias</b><br/>Añade vacaciones o días en los que no estarás.<br />Uno o más días.<br/>No te servirá para horas.")}}/>
                    </IconTooltip>
                }
            </LegendContainer>

            <SubHeader>
                <LeftContainer isMobile={state.isMobile} onClick={_openSmallCalendar}>

                    <MonthText>{main.calendarService.getDateFormatted(date,"MMMM YYYY")}</MonthText>
                    {showSmallCalendar ?
                        <IconWrapperArrow><Icon name={'chevon-blue-up'} color={"green !important"}/> </IconWrapperArrow>
                        :
                        <IconWrapperArrow><Icon name={'chevon-blue-down'} color={"green !important"}/> </IconWrapperArrow>
                    }
                </LeftContainer>

                {!state.isMobile ?
                    <RightContainerWeek>
                        <IconWrapperWeek onClick={()=>{_removeWeek()}}><Icon name={'chevron-left-green'} disableFill={true}/> </IconWrapperWeek>
                        <Week>{t("calendar_week","Semana")} {main.calendarService.getWeekNumber(date)}</Week>
                        <IconWrapperWeek onClick={()=>{_addWeek()}}><Icon name={'chevron-right-green'} disableFill={true}/> </IconWrapperWeek>
                    </RightContainerWeek>
                  :
                  <RightContainerWeek>
                    <IconWrapper isMobile={state.isMobile} title={t("calendar_go_current_day","Volver al día de hoy")} onClick={()=>{_goToCurrDate()}}>
                      <Icon name={'today_calendar'}  disableFill={false}/>
                    </IconWrapper>
                    <IconWrapper isMobile={state.isMobile} onClick={_openConfiguration}>
                      <Icon name={'cog'}/>
                    </IconWrapper>
                  </RightContainerWeek>
                }
            </SubHeader>

            {showSmallCalendar &&
                <SmallCalendarWrapper show = {showSmallCalendar} isMobile={state.isMobile}>
                    <SmallCalendar
                        doubleCalendar={!state.isMobile}
                        selectRange={false}
                        startDate={date}
                        handleSetDate={(date: Date)=>{
                            setDate(date);
                            main.calendarService.goToDate(date);
                            setShowSmallCalendar(false);
                        }}
                    />
                </SmallCalendarWrapper>
            }

            <CalendarWrapper>
                <CalendarGrid isTutor={isTutor} tutorId={props.tutorId}/>
            </CalendarWrapper>
        </>
    );
}