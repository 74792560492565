import MainProvider from "../providers/main";
import styled, {ThemeProvider} from "styled-components";
import React, {useContext, useEffect, useState} from "react";
import MainContext from "../context/main";
import UIProvider from "../providers/ui";
import UIContext, {setMenuVisible} from "../context/ui";
import {MainEventsListener} from "../services/main";
import Icon from "../components/common/icons/Icon";
import ModalEventDelete from "../components/common/modals/delete/EventDeleteModal";
import { useTranslation } from "react-i18next";
import ConfigurationWrapper from "../components/common/menu/Configuration";
import ModalDaySelect from "../components/common/modals/day_select/DaySelectModal";
import ModalOnBoarding from "../components/common/modals/onboarding/OnboardingModal";
import MenuAbsence from "../components/common/menu/Absence";
import MenuSchedule from "../components/common/menu/Schedule";
import CalendarComponent from "../components/common/calendar/Calendar";
import { CalendarTabs } from "../types/enums/menu_tabs";
import {Config} from "../constants/config";

const Container = styled.div<{isMobile:boolean, menuVisible:boolean}>`
  padding: 14px;
  width: calc(100% - 28px);
  height: calc(100% - 28px);
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.fontColors.primary};
  position: ${props => props.isMobile ? "inherit" : "relative"};
  overflow-y: ${props => props.isMobile ? "inherit" : "auto"};
  position:relative;

  > div:not([open]) {
    display: ${props => props.menuVisible ? "none" : "" }
  }
`;

const Header = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const LeftContainer = styled.div`
  width: auto;
  height: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em;
`;

const RightContainer = styled.div`
  width: 100%;
  height: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  text-align: right;
  justify-content: flex-end;
  padding: 1em;
  align-items: baseline;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 1.5em;
  cursor:pointer;
  :hover path{
    fill: ${props => props.theme.fontColors.accent + " !important"};
  }
  > svg{
    fill: ${props => props.theme.fontColors.primary + " !important"};
    stroke: ${props => props.theme.fontColors.primary  + " !important"};
  }

  :hover > svg{
    fill: ${props => props.theme.fontColors.accent + " !important"};
    stroke: ${props => props.theme.fontColors.accent  + " !important"};
  }
`;

const IconTooltip = styled.div`
    width: 16px;
    height: 16px;
    margin-left: 8px;

    :hover > p {
        visibility: visible;
        opacity: 1;
    }
`;

const TooltipText = styled.p`
  left: 8%;
  padding: 3em 2em;
  position: absolute;
  visibility: hidden;
  width: 496px;
  z-index: 1; 
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background-color: #2A3F55;
  border-radius: 6px;
  margin-top: 4px;

  ::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 44.5%;
      margin-left: -5px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent #2A3F55 transparent;
  }

  @media (max-width: 800px) {
      width: 336px;
      font-size: 14px;
  }
`;

const Title = styled.div`
  font-size: ${props=>props.theme.fontSizes.xl};
`;

const SubTitle = styled.div`
  font-size: ${props=>props.theme.fontSizes.s};
  margin-top: 8px;
`;

const Tabs = styled.div`
  margin-bottom: 8px;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
`;

const Tab = styled.div<{isMobile: boolean}>`
  flex: 1;
  display: inline-block;
  text-align: center;
  font-size: ${props => props.isMobile ? "14px" : "18px"};
  cursor: pointer;
  padding-top: 8px;
  line-height: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${props => props.theme.fontColors.soft};

  &.selected{
    border-bottom: 2px solid;
    color:  ${props => props.theme.fontColors.accent};
    font-weight: bold;
  }
`;

type _CalendarProps = {
    menuVisible: boolean
    isMobile: boolean
    dispatch: any
    tutorId?: number
}

interface TabObject{
    title: string,
    type: CalendarTabs
}

function _Calendar (props: _CalendarProps) {
    const [theme] = useState(Config.get().THEME);
    const main = useContext(MainContext)!;
    const [ready, setReady] = useState(false);
    const [isTutor] = useState(!props.tutorId || props.tutorId === 0);
    const [state] = useContext(UIContext)!;
    const {t} = useTranslation();

    // Menu tabs
    const [currenTab, setCurrentTab] = useState<CalendarTabs>(CalendarTabs.CALENDAR);
    const [tabs, setTabs] = useState<TabObject[]>([])

    useEffect(()=>{
        let _tabs = [
            {title: t('calendar_menu_disponibilidad', 'Disponibilidad'), type: CalendarTabs.CALENDAR},
            {title: t('calendar_menu_schedule', 'Horario frecuente'), type: CalendarTabs.SCHEDULE},
            {title: t('calendar_menu_ausencias', 'Ausencias'), type: CalendarTabs.ABSENCE},
        ]
        setTabs(_tabs);
    }, [t])

    useEffect(()=>{
        let listener = new class implements MainEventsListener{
            onReady(): void {
                setReady(true);
            }
        }();
        main.subscribeToEvents(listener);
        return () => {
            main.unSubscribeToEvents(listener);
        }
    }, [main]);

    useEffect(()=>{
        if (main.needRefresh) {
            main.calendarService.refresh();
            main.setNeedRefresh(false);
            main.weekPointService.getWeekPointsSelectHour(new Date().getTimezoneOffset(), true);
        }
    }, [currenTab, main.calendarService, main])

    const _openConfiguration = () =>{
        props.dispatch(setMenuVisible(true));
    };

    const handleTabChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    const renderContentTab = (index: number) => {
        switch (index){
            case  CalendarTabs.CALENDAR:
                main.setNeedRefresh(true);
                return <CalendarComponent tutorId={props.tutorId} dispatch={props.dispatch}/>
            case  CalendarTabs.ABSENCE:
                return <MenuAbsence/>
            case  CalendarTabs.SCHEDULE:
                return <MenuSchedule showFrecuent={true}/>
            default:
                return null;
        }
    }

    return(
        <ThemeProvider theme={theme}>
            <Container isMobile={state.isMobile} menuVisible={state.menuVisible}>
                {ready &&
                    <>
                        {!state.isMobile && isTutor &&
                            <Header>
                                <LeftContainer>
                                  <FlexContainer>
                                    <Title>{t("calendar_title","Tu disponibilidad horaria")}</Title>
                                    <IconTooltip>
                                        <Icon name={'info_circle'} disableFill={false}/>
                                        <TooltipText dangerouslySetInnerHTML={{__html: t("info_tooltip", "<b>Horario frecuente</b><br/>Define las horas habituales en las que das clases.<br/>Se repetirá semana a semana.<br/><br/><b>Disponible / No disponible</b><br/>Crea, edita o elimina horas de tu día a día. Disponibilidad en días sueltos, no se repetirán.<br/>También puedes añadir un no disponible dentro de un horario frecuente para una semana específica, sin que modifique tu horario habitual.<br/><br/><b>Ausencias</b><br/>Añade vacaciones o días en los que no estarás.<br />Uno o más días.<br/>No te servirá para horas.")}}/>
                                    </IconTooltip>
                                  </FlexContainer>

                                    <SubTitle>{t("calendar_subtitle", "Elige día y crea, edita o elimina horas")}</SubTitle>
                                </LeftContainer>

                                <RightContainer>
                                    <IconWrapper onClick={_openConfiguration}><Icon name={'cog'}/> </IconWrapper>
                                </RightContainer>
                            </Header>
                        }

                        <Tabs>
                            {tabs.map((tab)=>{
                                return <Tab key={tab.type} isMobile={state.isMobile}  onClick={() => handleTabChange(tab.type)} className={currenTab === tab.type ? 'selected' : ''}>
                                    {tab.title}
                                </Tab>
                            })}
                        </Tabs>
                        {renderContentTab(currenTab)}

                        <ModalDaySelect open={false} handleClose={()=>{}}/>
                        <ModalEventDelete open={false} handleClose={()=>{}}/>
                        <ModalOnBoarding open={false} handleClose={()=>{}} changeTab={() => handleTabChange(CalendarTabs.SCHEDULE)}/>

                        {isTutor && <ConfigurationWrapper/>}
                    </>
                }
            </Container>
        </ThemeProvider>
    )
}

type CalendarPageProps = {
    tutorId?: number
}

export default function CalendarPage(props: CalendarPageProps) {
    return <MainProvider>
        <UIProvider>
            <Calendar tutorId={props.tutorId}/>
        </UIProvider>
    </MainProvider>
}

type CalendarProps = {
    tutorId?: number
}

const CalendarMemo = React.memo(_Calendar);

function Calendar(props: CalendarProps){
    const [state, dispatch] = useContext(UIContext)!;
    return (
        <CalendarMemo menuVisible={state.menuVisible} isMobile={state.isMobile} dispatch={dispatch} tutorId={props.tutorId}/>
    )
}
