import styled from 'styled-components';
import UIContext from "../../../context/ui";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const Legend = styled.div`
    padding: 1em;
    align-items: center;
    display: inline-flex;
`;

const TextLegend = styled.span`
    margin-left: 0.5em;
`;

const WrapperLegend = styled.div<{isMobile:boolean}>`
    display: flex;
    align-items: center;
    margin-top: ${props => props.isMobile ? "0.2em" : "0"};
    font-size: ${props => props.isMobile ? "12px" : "15px"};
`;

const SquareAvailable = styled.div`
    width: 16px;
    height: 16px;
    background-color: ${props => props.theme.squareAvailable.backgroundColor};
    border: 1px solid ${props => props.theme.squareAvailable.borderColor};
    border-radius:${props => props.theme.squareAvailable.borderRadius};
`;

const SquareNotAvailable = styled.div`
    margin-left: 1em;
    border: 1px solid ${props => props.theme.squareNotAvailable.borderColor};
    width: 16px;
    height: 16px;
    background-color:${props => props.theme.squareNotAvailable.backgroundColor};
    border-radius:${props => props.theme.squareNotAvailable.borderRadius};
`;

type LegendProps = {
    isTutor?: boolean
}

Legend.defaultProps = {};

export default function LegendHeader(props: LegendProps){
    const {t} = useTranslation();
    const [state] = useContext(UIContext)!;

    return (
        <Legend>
            <WrapperLegend isMobile ={state.isMobile}>
                <SquareAvailable />
                <TextLegend>{t("legend_available","Disponible")}</TextLegend>
            </WrapperLegend>

            <WrapperLegend isMobile ={state.isMobile}>
                <SquareNotAvailable /> 
                <TextLegend>{t("legend_not_available","No disponible")}</TextLegend>
            </WrapperLegend>
        </Legend>
    )
}