import styled from 'styled-components';

import Popup from "reactjs-popup";
import Icon from "../icons/Icon";
import { useTranslation } from 'react-i18next';
import React from "react";

const Content = styled.div`
border-radius: 5px;
.popup-content{
    border-radius: 5px;
    padding: 0.5rem;
  }
  
  html[data-theme='dark'] .popup-content {
    background-color: rgb(41, 45, 62);
    color: #fff;
    border: 1px solid #9a9595;
  }
  
  html[data-theme='light'] .popup-content {
    background-color: #fff;
    color: #000;
    border: 1px solid #d7d7d7;
  }
  
  html[data-theme='dark'] .popup-arrow > svg {
    color: rgb(41, 45, 62);
    stroke-width: 2px;
    stroke: #9a9595;
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
  }
  
  html[data-theme='light'] .popup-arrow > svg {
    color: #fff;
    stroke-width: 2px;
    stroke: #d7d7d7;
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
  }
`;

const IconWrapperActiveAbsence = styled.div`
    position:relative;
    width: 16px;
    cursor:pointer;
`;
type DialogProps = {open: boolean, text: string, handleClose?: any}

DialogInfo.defaultProps = {open: false, handleClose: null, text: 'string'};

export default function DialogInfo(props: DialogProps){

    const {t} = useTranslation();

    return (
        <Content>
            <Popup
                trigger={open => (
                    <IconWrapperActiveAbsence><Icon name={'info_circle'} disableFill={false} /></IconWrapperActiveAbsence>
                )}
                position="bottom right"
                closeOnDocumentClick
                contentStyle={{background: "#2A3F55", color: "white", 'padding':"1.2em",'right': '1em', borderRadius: "5px 5px 5px 5px", fontSize:'14px'}}
                arrowStyle={{ color: "#2A3F55"}}
                on={'hover'}>
                <div>
                    <div>{t("calendar_absence_dialog_1","Añade ausencias en tu calendario y aparecerá ocupado durante el tiempo indicado.")}</div>
                    <div>{t("calendar_absence_dialog_2","Recuerda que durante esos días tu perfil no aparecerá en el listado de profesores.")}</div>
                </div>
            </Popup>
        </Content>
    )
}