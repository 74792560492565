import React, {useContext, useEffect, useState} from "react";
import styled from 'styled-components';
import Icon from "../../../common/icons/Icon";
import {CalendarListener} from "../../../../services/calendar/calendar";
import MainContext from "../../../../context/main";
import {useTranslation} from "react-i18next";
import SelectHours from "../../../common/menu/ContainerSelects";
import { IOSSwitch } from "../../../common/buttons/IOSSwitch";
import UIContext from "../../../../context/ui";
import SimpleBtn from "../../../common/buttons/SimpleBtn";
import { DayEventType } from "../../../../types/enums/day_event";
import { DayEvent } from "../../../../types/day_event";
import {FormControlLabel, Modal} from "@mui/material";
import {Config} from "../../../../constants/config";

const Content = styled.div<{visible: boolean, isMobile: boolean}>`
  bottom: ${props => props.visible ? "0" : "-500px"};
  left: ${props => props.isMobile ? "0" : "50%"};
  width: ${props => props.isMobile ? "100%" : "auto"};
  top: ${props => props.isMobile ? "initial" : "50%"};
  transform: ${props => props.isMobile ? "initial" : " translate(-50%, -50%)    "};
  height: ${props => props.isMobile ? "initial" : "fit-content"};
  position: absolute;
  border: none !important;
  background-color: white;
  border-radius:${props => props.isMobile ? "5px 5px 0 0" : "5px 5px 5px 5px"};

  outline: none;
  padding:${props => props.isMobile ? "0 0 1em" : "0 0 2em"};
  transition: bottom 250ms;
  color: ${props => props.theme.fontColors.primary};
  min-width:${props => props.isMobile ? "initial" : "360px"};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const LeftContainer = styled.div<{isMobile: boolean}> `
    width: 100%;
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    text-align: left;
    justify-content:  ${props => props.isMobile ? "flex-start" : "flex-end"};
    padding: 1em 1em 0;
`;

const RightContainer = styled.div<{isMobile: boolean}>`
    display:${props=>props.isMobile?"flex":"none"};
    width: 100%;
    height: auto;
    flex: 1;
    flex-direction: row;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
    padding: 1em 1em 0;
`;

const BtnWrapper = styled.div`
    height: 2em;
    width: min-content;
    display: inline-block;
    margin-right:2em;
    >div{
        padding: 0.25em 1.25em;
    }

`;

const IconWrapper = styled.div`
    width: 22px;
    height: 22px;
    cursor:pointer;
    
     > svg{
         fill: ${props => props.theme.fontColors.primary + " !important"};
         stroke: ${props => props.theme.fontColors.primary  + " !important"};
     }
    
     :hover > svg{
         fill: ${props => props.theme.fontColors.accent + " !important"};
         stroke: ${props => props.theme.fontColors.accent  + " !important"};
      }
`;

const IconWrapperDelete = styled.div`
    margin-left:10px;
    width: 24px;
    height: 24px;
    cursor:pointer;
  > svg{ path{fill: #B91313; }}
`;

const SaveText = styled.button`
    font-size: ${props => props.theme.fontSizes.s};
    margin: 0;
    padding: 0;
    color: ${props => props.theme.fontColors.accent};
    cursor: pointer;
    font-weight: bold;
    border:none;
    background:#fff;
    :hover{
      color: ${props => props.theme.fontColors.accentDark};
    }
`;

const DeleteText = styled.p`
    display: inline-block;
    margin: 0;
    padding: 0;
    color: #B91313;
    cursor: pointer;
    font-weight: bold;
    :hover{
        color: #6B0A0A;
    }
`;

const Inner = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  padding-left: 3em;
  padding-right: 3em;
`;

const Available = styled.div`
  margin-top:1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .MuiFormControlLabel-root{
      margin-right:0;
      .MuiSwitch-switchBase.Mui-checked {
      & + .MuiSwitch-track {
        background-color: ${(props) =>
          props.theme.toggle.active.background};
        }
      }
  }
`;

const AvailableText = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  color:  ${props => props.theme.fontColors.primary};
  margin: auto 0;
`;

const DateP = styled.p`
  font-size: ${props => props.theme.fontSizes.s};
  color:  ${props => props.theme.fontColors.primary};
  margin: 1em 0 0;
  
  :first-letter {
    text-transform: uppercase;
  }
`;

const Footer = styled.div`
    margin-top: 2em;
`;

const ContainerSelect = styled.div`
  margin-top:0.75em;
  display: flex;
  align-items: center;
`;

const Select = styled.div`
  flex:1;
`;

type ModalDaySelectProps = {open: boolean, handleClose?: any}

ModalDaySelect.defaultProps = {open: false, handleClose: null};

export default function ModalDaySelect(props: ModalDaySelectProps){
    const {t} = useTranslation();
    const main = useContext(MainContext)!;
    const [open, setOpen] = useState(props.open);
    const [contentVisible, setContentVisible] = useState(false);
    const [date, setDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [event, setEvent] = useState<DayEvent | null>(null);
    const [oldTicksInit, setOldTicksInit] = useState<number>(0);
    const [ticksInit, setTicksInit] = useState<number>(0);
    const [ticksEnd, setTicksEnd] = useState<number>(0);
    const [nextEventHour, setNextEventHour] = useState<number | undefined>(undefined);
    const [prevEventHour, setPrevEventHour] = useState<number | undefined>(undefined);
    const [diff, setDiff] = useState<number>(0.5);
    const [available, setAvailable] = useState(true);
    const [showDelete, setShowDelete] = useState(false);
    const [state] = useContext(UIContext)!;
    const [theme] = useState(Config.get().THEME);

    const save = function () {

        if(date){
            let tzo = new Date().getTimezoneOffset();
            let type = available ? DayEventType.AVAILABLE : DayEventType.NOT_AVAILABLE;
            let e = new DayEvent(type, event?.initTimestamp!, event?.endTimestamp!, event?.id);

            if(event){
                main.calendarService.updateEvent(oldTicksInit, ticksInit, e, date, diff);
            } else {
                main.calendarService.addEvents(tzo, ticksInit, ticksEnd, type, diff, date);
            }
        }
    };

    const _delete = function () {
        if(date && event){
            main.calendarService.selectDateDelete(date, diff, event)
        }
        _close();
    };

    const _close = function () {
        setContentVisible(false);
        if(state.isMobile)
            setTimeout(()=>{
                setOpen(false);
            }, 250);
        else
            setOpen(false);

        props.handleClose();
    };

    useEffect(()=>{
        if(!open) {return}
        setTimeout(()=>{
            setContentVisible(open);
        }, 10)
    }, [open]);

    useEffect(()=>{
        let listener = new class implements CalendarListener{
            onEventSet() {
                _close();
            }

            onEventsAdded(){
                _close();
            }

            onSelectDate(date: Date): void {
                date.setSeconds(0,0);
                setDate(date);
                var defaultDiff = main.calendarService.calculateDefaultDiff(date);
                var endDate = new Date(date.getTime());
                endDate.setMinutes(date.getMinutes() + defaultDiff * 60);
                if (endDate.getHours() === 0 && endDate.getMinutes() === 30) {
                  endDate.setMinutes(0);
                  defaultDiff = 0.5;
                }
                setEndDate(endDate);
                setDiff(defaultDiff);
                setTicksInit(main.calendarService.dateToTicks(date));
                setTicksEnd(main.calendarService.dateToTicks(endDate));
                setOpen(true);
                setNextEventHour(main.calendarService.getNextEventHour(date));
                setPrevEventHour(main.calendarService.getBeforeEventHour(date));
                setShowDelete(false);
                setAvailable(true);
                setEvent(null); 
            }

            onSelectEvent(date: Date, dayEvent: DayEvent): void {

                if(!dayEvent || dayEvent.initTimestamp === null || dayEvent.endTimestamp  === null)
                    return;

                date.setMinutes(0,0,0);

                if((dayEvent.initTimestamp % 1).toFixed(1) === '0.5'){
                    date.setMinutes(30);
                }
                date.setHours(dayEvent?.initTimestamp);
                setDate(date);
                let endDate = new Date(date);
                endDate.setHours(dayEvent?.endTimestamp);
                endDate.setMinutes(0);
                if((dayEvent.endTimestamp % 1).toFixed(1) === '0.5'){
                    endDate.setMinutes(30);
                }
                setAvailable(dayEvent.eventType !== DayEventType.NOT_AVAILABLE);
                setEvent(dayEvent);
                setEndDate(endDate);
                setTicksInit(main.calendarService.dateToTicks(date));
                setOldTicksInit(main.calendarService.dateToTicks(date));
                setTicksEnd(main.calendarService.dateToTicks(endDate));
                setShowDelete(true);
                setNextEventHour(main.calendarService.getNextEventHour(date));
                setPrevEventHour(main.calendarService.getBeforeEventHour(date));
                setDiff(dayEvent.endTimestamp - dayEvent.initTimestamp);
                setOpen(true);

            }
        }();
        main.calendarService.subscribeToEvents(listener);
        return () => {
            main.calendarService.unSubscribeToEvents(listener);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [main.calendarService]);

    return (
        <Modal open={open} onClose={_close}>
            <Content visible={contentVisible} isMobile={state.isMobile}>
                <Header>
                    <LeftContainer isMobile={state.isMobile}>
                        <IconWrapper onClick={_close}>
                            <Icon name={'close'} disableFill={false}/>
                        </IconWrapper>
                    </LeftContainer>
                    <RightContainer isMobile={state.isMobile}>
                        <SaveText onClick={save}>{t('calendar_guardar', 'Guardar')}</SaveText>
                    </RightContainer>
                </Header>
                {date ?
                    <Inner isMobile={state.isMobile}>
                        <DateP>{main.calendarService.getDateFormatted(date, t('calendar_select_date_format', 'dddd DD [de] MMMM'))}</DateP>
                        <ContainerSelect>
                            <Select>
                                <SelectHours addMoreRanges={false} day={date.getDay()} hour1={main.calendarService.getTimeStampByDate(date)}
                                             hour2={endDate ? main.calendarService.getTimeStampByDate(endDate) : undefined}
                                             editHandler={(hour1: number, hours: number)=>{
                                                 let date1 = new Date(date);
                                                 date1.setHours(hour1, hour1 % 1 === 0.5 ? 30 : 0);
                                                 setDate(date1);
                                                 let date2 = new Date(date1);
                                                 date2.setHours(date2.getHours() + hours, date2.getMinutes() + (hours % 1 === 0.5 ? 30 : 0));
                                                 setTicksInit(main.calendarService.dateToTicks(date1));
                                                 setTicksEnd(main.calendarService.dateToTicks(date2));
                                                 setDiff(hours);
                                             }}
                                             hourLimitBefore = {prevEventHour}
                                             hourLimit = {nextEventHour}/>
                            </Select>
                            {state.isMobile && showDelete ?
                                <IconWrapperDelete onClick={_delete}>
                                    <Icon name={'delete'} disableFill={false}/>
                                </IconWrapperDelete>
                                : null
                            }
                        </ContainerSelect>
                        <Available>
                            <AvailableText>{t('calendar_estaras_disponible', '¿Estarás disponible?')}</AvailableText>
                            <FormControlLabel
                                control={<IOSSwitch checked={available} onChange={()=>{setAvailable(!available)}} name="checkedB" />}
                                label={available ? t('calendar_si', 'Si') : t('calendar_no', 'No')}
                            />
                        </Available>

                        {!state.isMobile ?
                            <Footer>
                                <BtnWrapper>
                                    <SimpleBtn style={theme.buttonColors.accent.selected}
                                               title={t('calendar_select_save', 'Guardar')}
                                               collapsible={false}
                                               smallIcon={false}
                                               clicked={save}
                                                />
                                </BtnWrapper>
                                {showDelete ?
                                    <DeleteText onClick={_delete}>{t('calendar_delete', 'Eliminar')}</DeleteText>
                                    : null
                                }
                            </Footer>
                            : null
                        }
                    </Inner>
                    : null
                }
            </Content>
        </Modal>
    )
}