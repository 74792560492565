import ParentService, {ParentServiceListener} from "../parent";
import 'moment/locale/es';
import API from "../api/api";
import { TimeZone } from "../../types/timezone";

export class TutorService extends ParentService{

    getTutorMinTime(){
        let that = this;

        API.getTutorMinTime().then((json: any) => {
        that.publishOnTutorMinTimeGet(json["Minutes"]);
        }).catch(error => {
            //todo
        })
    }

    setTutorMinTime(minutes: number){
        let that = this;

        API.setTutorMinTime(minutes).then(() => {
        that.publishOnTutorMinTimeSet();
        }).catch(error => {
            //todo
        })
    }

    getTimeZones(){
        let that = this;

        API.getTimeZones().then((json: any) => {
        that.publishOnTimeZonesLoad(json["TimeZones"]);
        }).catch(error => {
            //todo
        })
    }

    getTimeZone(){
        let that = this;

        API.getTimeZone().then((json: any) => {
            that.publishOnTimeZoneLoad(json["TimeZone"]);
        }).catch(error => {
            //todo
        })
    }
    
    setTimeZone(timeZone: string){
        let that = this;

        API.setTimeZone(timeZone).then((json: any) => {
            that.publishOnTimeZoneSet();
        }).catch(error => {
            //todo
        })
    }

    getTutor(){
        API.getTutor().then((tutor: any) => {
            this.publishNoNewStudents(tutor.Tutor.NoNewStudents)
        }).catch(error => {
            //todo
        })
    }

    setNoNewStudents(noNewStudents: boolean) {
        API.setNoNewStudents(noNewStudents).then((json: any) => {
            this.publishNoNewStudents(noNewStudents)
        }).catch(error => {
            //todo
        })
    }
    
    /** @ignore Publish week points loaded event to all elements subscribed. */
    protected publishOnTutorMinTimeGet(minutes : number){
        this.subscribers.forEach(function (listener) { (listener as TutorEventListener).onTutorMinTimeGet?.(minutes); })
    }

    /** @ignore Publish documents loaded event to all elements subscribed. */
    protected publishOnTutorMinTimeSet(){
        this.subscribers.forEach(function (listener) { (listener as TutorEventListener).onTutorMinTimeSet?.(); })
    }

    /** @ignore Publish TimeZones Load event to all elements subscribed.*/
    protected publishOnTimeZoneLoad(timeZone : string){
        this.subscribers.forEach(function (listener) { (listener as TutorEventListener).onTimeZoneLoad?.(timeZone); })
    }

    /** @ignore Publish TimeZones Load event to all elements subscribed.*/
    protected publishOnTimeZonesLoad(timeZones : TimeZone[]){
        this.subscribers.forEach(function (listener) { (listener as TutorEventListener).onTimeZonesLoad?.(timeZones); })
    }

    /** @ignore Publish TimeZones set event to all elements subscribed.*/
    protected publishOnTimeZoneSet(){
        this.subscribers.forEach(function (listener) { (listener as TutorEventListener).onTimeZonesSet?.(); })
    }
    /** @ignore Publish NoNewStudents.*/
    protected publishNoNewStudents(noNewStudents: boolean){
        this.subscribers.forEach(function (listener) { (listener as TutorEventListener).onNoNewStudents?.(noNewStudents); })
    }
}

export interface TutorEventListener extends ParentServiceListener{

    /** Notifies when tutor minimum minutes are loaded. */
    onTutorMinTimeGet?(minutes : number): void;

    /** Notifies when absences has been loaded. */
    onTutorMinTimeSet?(): void;

    /** Notifies when tutor timeZoneId is loaded. */
    onTimeZoneLoad?(timeZone : string): void;

    /** Notifies when timeZone List are loaded. */
    onTimeZonesLoad?(timeZones : TimeZone[]): void;

    /** Notifies when tutor timeZoneId is set. */
    onTimeZonesSet?(): void;
    
    onNoNewStudents?(noNewStudents: boolean) : void;
}
