import ParentService from "./parent";
import {CalendarService} from "./calendar/calendar";
import {ClassroomError} from "../types/error";
import { AbsenceService } from "./absence/absence";
import { WeekPointsService } from "./weekPoints/weekPoints";
import { TutorService } from "./tutor/tutor";
import API from "./api/api";
import { OnBoardingService } from "./onboarding/onboarding";
import { BookingService } from "./booking/booking";


export class MainService extends ParentService{
    private readonly instanceId: number;
    public needRefresh: boolean = false;
    public calendarService: CalendarService = new CalendarService();
    public absenceService: AbsenceService = new AbsenceService();
    public weekPointService: WeekPointsService = new WeekPointsService();
    public tutorService: TutorService = new TutorService();
    public onboardingService: OnBoardingService = new OnBoardingService();
    public bookingService: BookingService = new BookingService();

    constructor() {
        super();
        this.instanceId = Math.random();
        console.log(this.instanceId);

    }

    initialize(){
        this.publishOnReady();
        this.getLanguage();
    }
 
    getLanguage(){
        const calendar_parent = document.getElementById("calendar1");
        const language = calendar_parent != null ? calendar_parent.getAttribute("data-language") : "en";
        
        let languageIetf = "";
        switch (language) {
            case "es-ar":
            case "es-mx":
            case "es-cl":
            case "es-co":
            case "es-pe":
            case "es":
                languageIetf = "es-ES";
                break;
            case "fr":
                languageIetf = "fr-FR";
                break;
            case "it":
                languageIetf = "it-IT";
                break;
            case "de":
                languageIetf = "de-DE";
                break;
            default:
                languageIetf = "en-EN";
                break;
                
        }

        return languageIetf;
    }
    
    login(tutorId: number){
        API.login(tutorId).then(()=>{
            this.publishOnLogin(true);
        }).catch(()=>{
            this.publishOnLogin(false);
        })
    }

    setNeedRefresh(value: boolean){
        this.needRefresh = value;
    }

    /** @ignore Publish error event_delete to all elements subscribed. */
    protected publishOnReady(){
        this.subscribers.forEach(function (listener) { (listener as MainEventsListener).onReady?.(); })
    }

    /** @ignore Publish error event to all elements subscribed. */
    protected publishOnLogin(done: boolean){
        this.subscribers.forEach(function (listener) { (listener as MainEventsListener).onLogin?.(done); })
    }
}

export interface MainEventsListener {
    /** Notifies login result to all subscribers */
    onLogin?(done: boolean): void;
    /** Notifies when controller is ready */
    onReady?(): void;
    /** Notifies when error */
    onError?(error: ClassroomError): void;
}