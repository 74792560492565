import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from 'styled-components';
import Icon from "../../icons/Icon";
import MainContext from "../../../../context/main";
import {useTranslation} from "react-i18next";
import UIContext from "../../../../context/ui";
import SmallCalendar from "../../small_calendar/SmallCalendar";
import SimpleBtn from "../../buttons/SimpleBtn";
import { AbsencesEventListener } from "../../../../services/absence/absence";
import { Modal } from "@mui/material";
import {Config} from "../../../../constants/config";

const Content = styled.div<{visible: boolean, isMobile: boolean}>`
    bottom: ${props => props.visible ? "0" : "-1000px"};
    left: ${props => props.isMobile ? "0" : "50%"};
    width: ${props => props.isMobile ? "100%" : "auto"};
    top: ${props => props.isMobile ? "initial" : "50%"};
    transform: ${props => props.isMobile ? "initial" : " translate(-50%, -50%)    "};
    height: ${props => props.isMobile ? "calc(100% - 2em)" : "fit-content"};
    width: ${props => props.isMobile ? "100%" : "auto"};
    position: absolute;
    border: none !important;
    background-color: white;
    border-radius:${props => props.isMobile ? "0" : "5px 5px 5px 5px"};
    outline: none;
    padding:${props => props.isMobile ? "1em 0" : "0 0 2em"};
    transition: bottom 250ms;
    overflow-y: auto;
    min-width: ${props => props.isMobile ? "initial" : "810px"};
    color: ${props => props.theme.fontColors.primary};
`;

const Header = styled.div<{isMobile: boolean}>`
    padding-top: ${props => props.isMobile ? "0" : "1em"};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 1em;
    border-bottom: 1px solid ${props => props.theme.fontColors.soft};
`;

const Body = styled.div`
    margin: 1.5em 1em 0em 1em;
`;

const Title = styled.div <{isMobile: boolean}>`
    font-weight:${props=>props.isMobile?'initial':'bold'};
    font-size: ${props=>props.isMobile?'16px':'20px'};
    letter-spacing: 0;
    line-height: 24px;
    color: ${props =>  props.theme.fontColors.primary};    
`;

const TitleSection = styled.div`
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
`;

const IconWrapper = styled.div`
    width: 22px;
    height: 22px;
    position:absolute;
    right:18px;
    cursor:pointer;
`;

const IconWrapperTip = styled.div`
    width: 18px;
    height: 18px;

`;

const InputText = styled.input<{error: boolean}>`
    width: calc(100% - 22px);
    font-size: 16px;
    margin-top: 0.75em;
    border: 1px solid ${props=>props.error ? '#ED4747' : '#D3D9DE' };
    background-color: ${props=>props.error ? '#FDF1F1' : 'initial' };
    border-radius: 4px;
    padding: 0.6em;

    ::placeholder{
        color: #A8B3BD;
    }
    :focus-visible{
        border: 1px solid ${props=>props.error ? '#ED4747' : props.theme.fontColors.primary };
        outline: none;
    }
`;

const Tip = styled.div`
    display:flex;
    font-size: 14px;
    letter-spacing: 0;
    margin-top: 1em;
`;

const TipText = styled.span<{error: boolean}>`
    margin-left:0.5em;
    color: ${props=>props.error ? '#ED4747' : '#49545F' };
`;

const SelectDate = styled.div`
    margin-top: 1.75em;
`;

const CalendarWrapper = styled.div `
    margin: 1.5em auto 0;
    width: min-content;
    max-width:320px;
`;

const SelectedDateWrapper = styled.div<{isMobile: boolean}> `
    margin-top: ${props=>props.isMobile?'initial':'2em'};
    float:${props=>props.isMobile?'none':'right'};
    text-align: ${props=>props.isMobile?'center':'left'};
    width:${props=>props.isMobile?'initial':'calc(50% - 3em)'};
    padding:${props=>props.isMobile?'initial':'0 1.5em'};
`;

const SelectedDate = styled.div `
    font-weight: bold;
    margin-top: 0.5em;
`;

const BtnWrapper = styled.div<{isMobile: boolean}>`
    height: 2em;
    margin: 0 auto;
    width: 100%;
    > div{
        padding: ${props=>props.isMobile?'0.2em 0':'0.2em 2.5em'};
    }
`;

const Footer = styled.div<{isMobile: boolean}> `
    margin-top: 1.5em;
    position: ${props=>props.isMobile?'initial':'absolute'};
    right: ${props=>props.isMobile?'initial':'2em'};
    bottom: ${props=>props.isMobile?'initial':'2em'};
`;

const ContainerSelectRange = styled.div <{isMobile: boolean}>`
    float:${props=>props.isMobile?'none':'left'};
    width:${props=>props.isMobile?'initial':'calc(50% - 1px - 3em)'};
    padding:${props=>props.isMobile?'initial':'0 1.5em'};
    border-right: ${props=>props.isMobile?'initial':'1px solid #D3D9De'};
`;

const ContainerSelectTitle = styled.div <{isMobile: boolean}>`
    float:${props=>props.isMobile?'none':'right'};
    width:${props=>props.isMobile?'initial':'calc(50% - 3em)'};
    padding:${props=>props.isMobile?'initial':'0 1.5em'};
`;

type ModalAddAbsenceProps = {open: boolean, handleClose?: any, handleAdded?: any, title?: string, date?: Date[], id?:string }

ModalAddAbsence.defaultProps = {open: false, handleClose: null, handleAdded: null, title: null, date: null, id: null};

const toTicks = function (date : Date) {
    return (new Date(date).getTime() * 10000) + 621355968000000000;
};

export default function ModalAddAbsence(props: ModalAddAbsenceProps){
    const {t} = useTranslation();
    const main = useContext(MainContext)!;
    const [open, setOpen] = useState(props.open);
    const [contentVisible, setContentVisible] = useState(false);
    const [state] = useContext(UIContext)!;
    const [theme] = useState(Config.get().THEME);
    const [datesStr, setDatesStr] = useState<string[]>(props.date!= null && props.date.length > 1 ? main.calendarService.getDatesFormatted(props.date, 'DD MMMM YY') : []);
    const [btnReady, setbtnReady] = useState(false);
    const [error, setError] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(props.title!= null ? props.title : "");
    const [initTicks, setInitTicks] = useState<number>(props.date!= null && props.date.length > 1 ? toTicks(props.date[0]) : 0);
    const [endTicks, setEndTicks] = useState<number>(props.date!= null && props.date.length ? toTicks(props.date[1]) : 0);
    const [timeZoneOffset] = useState<number>(new Date().getTimezoneOffset());
    const _close = useCallback(() => {
        setContentVisible(false);

        if(state.isMobile)
            setTimeout(()=>{
                setOpen(false);
            }, 250);
        else
            setOpen(false);

        props.handleClose();
    }, [state.isMobile, props]);

    useEffect(() => {
        setbtnReady(datesStr !== null && datesStr?.length === 2)
    }, [datesStr]);

    useEffect(() => {
        let listener = new class implements AbsencesEventListener {
            onAbsenceAdded(_id: string): void {
                props.handleAdded();
                _close();
                setbtnReady(true);
            }

            onAbsenceEdited(): void {
                props.handleAdded();
                _close();
                setbtnReady(true);

            }
        }();

        main.absenceService.subscribeToEvents(listener);
        // Unbind the listener when the component unmounts
        return () => {
            main.absenceService.unSubscribeToEvents(listener);
        }
    }, [main.absenceService, props, _close]);

    const _save = function () {
        if(!btnReady)
            return;

        if(title === ""){
            setError(true);
            return;
        }

        setbtnReady(false);

        if(props.id)
            main.absenceService.editAbsence(props.id, title, initTicks, endTicks);
        else
            main.absenceService.addAbsence(title, initTicks, endTicks, timeZoneOffset);
    };

    useEffect(()=>{
        if(title!=="")
            setError(false);
    }, [title]);

    useEffect(()=>{
        if(!open) {return}
        setTimeout(()=>{
            setContentVisible(open);
        }, 10)
    }, [open]);

    return (
        <Modal open={open} onClose={_close}>
            <Content visible={contentVisible} isMobile={state.isMobile}>
                <Header isMobile={state.isMobile}>
                    <Title  isMobile={state.isMobile} >
                        {t("calendar_add_absence_title","Añadir nueva ausencia")}
                    </Title>
                    <IconWrapper onClick={_close}>
                        <Icon  name={'close'} disableFill={true}/>
                    </IconWrapper>
                </Header>
                <Body>
                    <ContainerSelectTitle isMobile={state.isMobile}>

                        <TitleSection>
                            {t("calendar_add_absence_input_title","Título de la ausencia")}
                        </TitleSection>
                        <InputText error={error} type={"text"}
                                   placeholder={t("placeholder_absence","Ej. Vacaciones de verano…")}
                                   value={title}
                                   onChange={text => setTitle(text.target.value) } />
                        <Tip>
                            <IconWrapperTip>
                                {error ?
                                    <Icon  name={'validation_exclamation'} disableFill={false} />:
                                    <Icon  name={'info_circle'} disableFill={false} />}
                            </IconWrapperTip>
                            <TipText error={error}>{error?
                                t("calendar_add_absence_error","Añade un título a tu ausencia")
                                :
                                t("calendar_add_absence_tip","No será público, solo podrás verlo tu")
                            }</TipText>
                        </Tip>
                    </ContainerSelectTitle>
                    <SelectDate>
                        <ContainerSelectRange isMobile={state.isMobile}>

                            <TitleSection>
                                {t("calendar_add_absence_select_range","Selecciona el primer y último día que estarás ausente.")}
                            </TitleSection>
                            <CalendarWrapper>

                                <SmallCalendar
                                    doubleCalendar={false}
                                    selectRange={true}
                                    handleSetDate={(date: Date[])=>{
                                        //convertimos las fechas a ticks de .net
                                        setInitTicks(toTicks(date[0]));
                                        setEndTicks(toTicks(date[1]));
                                        setDatesStr(main.calendarService.getDatesFormatted(date, 'DD MMMM YY'));
                                    }}
                                    rangeDate ={props.date ? props.date : undefined}
                                />
                            </CalendarWrapper>
                        </ContainerSelectRange>

                        {datesStr !== null && datesStr?.length === 2
                            ?
                            <SelectedDateWrapper isMobile={state.isMobile}>
                                <TitleSection>
                                    {t("calendar_add_absence_selected_date","Fecha seleccionada")}
                                </TitleSection>
                                <SelectedDate>{datesStr[0]} - {datesStr[1]}</SelectedDate>
                            </SelectedDateWrapper>
                            :
                            null}

                    </SelectDate>
                    <Footer isMobile={state.isMobile}>
                        <BtnWrapper isMobile={state.isMobile}>
                            <SimpleBtn style={btnReady ? theme.buttonColors.accent.selected : theme.buttonColors.accent.disabled}
                                       title={t("calendar_add_absence_save","Guardar")}
                                       collapsible={false}
                                       smallIcon={true}
                                       clicked={_save}
                            />
                        </BtnWrapper>
                    </Footer>
                </Body>
            </Content>
        </Modal>
    )
}