import * as React from "react";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MainContext from "../../../context/main";
import { default as ReactCalendar } from "react-calendar";
import UIContext from "../../../context/ui";
import Icon from "../icons/Icon";

import moment from "moment";
import { CalendarListener } from "../../../services/calendar/calendar";

const Container = styled.div<{
  isMobile: boolean;
  selectRange: boolean;
  doubleCalendar: boolean;
}>`
  width: ${(props) => (props.selectRange ? "100%" : "calc(100% - 2em)")};
  padding: ${(props) => (props.selectRange ? "1em 0" : "0em")};
  margin: 0 auto;

  .react-calendar__tile--active,
  .react-calendar__tile--rangeEnd.react-calendar__tile--rangeStart {
    text-align: center;
    border-radius: 100px;
    color: ${(props) => props.theme.fontColors.primary};
    letter-spacing: 0;
    background: ${(props) => props.theme.rangeStart.background} !important;
    padding: 0;
    abbr {
      border-radius: 15px;
      background: ${(props) => props.theme.rangeStart.abbr.backgroundColor};
    }
  }

  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }

  .react-calendar--doubleView {
    width: 700px;
  }

  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }

  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }

  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }

  .react-calendar button:enabled:hover {
    cursor: pointer;
  }

  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }

  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }

  .react-calendar__month-view__days__day--neighboringMonth,
  .react-calendar__decade-view__years__year--neighboringDecade,
  .react-calendar__century-view__decades__decade--neighboringCentury {
    color: #757575;
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
  }

  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: #ababab;
  }

  .react-calendar__month-view__days__day--neighboringMonth:disabled,
  .react-calendar__decade-view__years__year--neighboringDecade:disabled,
  .react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }

  .react-calendar__tile--now {
    background: #ffff76;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }

  .react-calendar__tile--hasActive {
    background: #76baff;
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }

  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }

  .react-calendar__tile--now {
    background: none;
    abbr {
      color: ${(props) => props.theme.fontColors.accent};
    }
  }

  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #a8b3bd;
    abbr {
      color: #a8b3bd;
    }
  }

  .react-calendar {
    border: none;
    margin: 0 auto;
  }

  .react-calendar__tile:disabled {
    background: none;
    abbr {
      color: ${(props) => props.theme.fontColors.disabled};
    }
  }

  .react-calendar__month-view__weekdays {
    font-weight: unset;
    text-transform: unset;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .react-calendar__navigation__label__divider {
    display: none;
  }

  .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from,
  .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--to {
    width: ${(props) => (props.doubleCalendar ? "50%" : "100%")};
    display: inline-block;

    color: ${(props) =>
      props.doubleCalendar
        ? props.theme.fontColors.primary
        : props.theme.fontColors.accent};
    font-size: ${(props) =>
      props.doubleCalendar ? "16px" : props.theme.fontSizes.s};
    font-weight: bold;
    line-height: 27.52px;
  }

  abbr[title] {
    text-decoration: unset;
  }

  .react-calendar__navigation button[disabled] {
    visibility: hidden;
  }

  .react-calendar__tile--rangeEnd.react-calendar__tile--rangeStart {
    background: none !important;
  }

  abbr {
    display: flex;
    width: 29px;
    text-align: center;
    margin: auto;
    height: 28px;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    color: ${(props) => props.theme.fontColors.primary};
  }

  .react-calendar__tile,
  .react-calendar__tile--range {
    padding: 0;
    margin: 1px 0;
  }

  .react-calendar__tile--range {
    max-width: initial !important;
    border-radius: 0;
  }

  .react-calendar__tile--rangeStart {
    background: linear-gradient(
      90deg,
      ${(props) => props.theme.rangeStart.background} 50%,
      ${(props) => props.theme.rangeStart.background} 50%
    ) !important;
    border-radius: 153px 0px 0px 166px;
    font-weight: bold;
  }

  .react-calendar__tile--rangeEnd {
    background: linear-gradient(
      90deg,
      ${(props) => props.theme.rangeEnd.background} 50%,
      ${(props) => props.theme.rangeEnd.background} 50%
    ) !important;
    border-radius: 0px 150px 150px 0px;
    font-weight: bold;
  }

  .react-calendar__tile--rangeEnd.react-calendar__tile--rangeStart {
    font-weight: bold;
  }
  .react-calendar__navigation {
    height: auto;
    margin-bottom: 0.75em;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus,
  .react-calendar__tile--hover {
    background: ${(props) => props.theme.rangeStart.background};
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background: none;
  }

  .react-calendar {
    width: ${(props) =>
      props.isMobile
        ? "288px"
        : (props) => (props.doubleCalendar ? "700px" : "350px")};
  }
`;

const IconWrapperCalendar = styled.div<{
  isMobile: boolean;
  doubleCalendar: boolean;
}>`
  width: ${(props) => (props.doubleCalendar ? "25px" : "16px")};
  height: ${(props) => (props.doubleCalendar ? "25px" : "16px")};
  cursor: pointer;
  margin: auto;
  fill: ${(props) => props.theme.fontColors.primary};
  :hover {
    fill: ${(props) => props.theme.fontColors.accent};
  }
`;

type _SmallCalendarProps = {
  doubleCalendar: boolean;
  selectRange: boolean;
  handleSetDate?: any;
  startDate?: Date;
  rangeDate?: Date[];
};

function _SmallCalendar(props: _SmallCalendarProps) {
  const main = useContext(MainContext)!;
  const [state] = useContext(UIContext)!;
  const [date, setDate] = useState(new Date());
  const [dates, setDates] = useState(
    props.rangeDate ? props.rangeDate : new Date()
  );
  const [minDate] = useState(new Date());
  const [maxDate] = useState(
    new Date(minDate.getFullYear() + 1, minDate.getMonth(), minDate.getDay())
  );
  const [hasClicked, setHasClicked] = useState(false);

  useEffect(() => {
    setHasClicked(
      props.startDate !== undefined || props.rangeDate ? true : false
    );
    setDate(props.startDate !== undefined ? props.startDate : new Date());
    setDates(props.rangeDate ? props.rangeDate : new Date());
  }, [props.startDate, props.rangeDate]);

  useEffect(() => {
    if (!props.selectRange && date) {
      let listener = new (class implements CalendarListener {
        onGoToDate(date: Date): void {
          setDate(date);
        }
      })();
      main.calendarService.subscribeToEvents(listener);
      return () => {
        main.calendarService.unSubscribeToEvents(listener);
      };
    }
  }, [main.calendarService, date, props.selectRange]);

  const clickDate = function (date: Date) {
    setDate(date);
    setDates(date);
    setHasClicked(true);
    props.handleSetDate(date);
  };

  return (
    <Container
      isMobile={state.isMobile}
      selectRange={props.selectRange}
      doubleCalendar={props.doubleCalendar}
    >
      <ReactCalendar
        minDate={minDate}
        maxDate={maxDate}
        //@ts-ignore
        defaultValue={props.rangeDate ? props.rangeDate : undefined}
        //@ts-ignore
        value={hasClicked ? (props.rangeDate ? dates : date) : null}
        //@ts-ignore
        onChange={clickDate}
        returnValue={"start"}
        minDetail={"decade"}
        maxDetail={"month"}
        showNavigation={true}
        showDoubleView={props.doubleCalendar}
        next2Label={null}
        prev2Label={null}
        showNeighboringMonth={false}
        locale={main.getLanguage()}
        showFixedNumberOfWeeks={false}
        nextLabel={
          <IconWrapperCalendar
            isMobile={state.isMobile}
            doubleCalendar={props.doubleCalendar}
          >
            <Icon
              name={
                !props.doubleCalendar
                  ? "chevron-right-green"
                  : "chevron-circle-right"
              }
            />
          </IconWrapperCalendar>
        }
        prevLabel={
          <IconWrapperCalendar
            isMobile={state.isMobile}
            doubleCalendar={props.doubleCalendar}
          >
            <Icon
              name={
                !props.doubleCalendar
                  ? "chevron-left-green"
                  : "chevron-circle-left"
              }
            />
          </IconWrapperCalendar>
        }
        selectRange={props.selectRange}
        formatShortWeekday={(lang, date) => {
          return moment(date).format("dd");
        }}
      />
    </Container>
  );
}

type SmallCalendarProps = {
  doubleCalendar: boolean;
  selectRange: boolean;
  handleSetDate?: any;
  startDate?: Date;
  rangeDate?: Date[];
};

SmallCalendar.defaultProps = {};

const SmallCalendarMemo = React.memo(_SmallCalendar);

export default function SmallCalendar(props: SmallCalendarProps) {
  return (
    <SmallCalendarMemo
      doubleCalendar={props.doubleCalendar}
      selectRange={props.selectRange}
      startDate={props.startDate}
      handleSetDate={(date: any) => {
        props.handleSetDate(date);
      }}
      rangeDate={props.rangeDate}
    />
  );
}
