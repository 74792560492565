import ParentService, {ParentServiceListener} from "../parent";
import {DayEvents} from "../../types/day_events";
import {DayEvent} from "../../types/day_event";
import {DayEventType} from "../../types/enums/day_event";
import {WeekdayEvents} from "../../types/weekday_events";
import {WeekDay} from "../../types/enums/weekday";
import moment from "moment";
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/de';
import API from "../api/api";
import { isMobile } from "react-device-detect";

function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export class CalendarService extends ParentService{

    private data: DayEvents[] = [];
    private weekdayEvents: { [weekday: number]: WeekdayEvents; } = {};
    private hourIntervalInMinutes: number = 30;
    private startHour: number = 0;
    private endHour: number = 24;
    private hours: number = this.endHour - this.startHour;
    private rowsCount = (this.hours * 60 / this.hourIntervalInMinutes) + 1; // El +1 representa la row del header
    private useEventHours: boolean = false;

    constructor(useEventHours: boolean = false) {
        super();
        this.useEventHours = useEventHours;
    }

    dateToTicks(d : Date) {
        let epochTicks = 621355968000000000;
        let ticksPerMillisecond = 10000;
        return epochTicks + (d.getTime() * ticksPerMillisecond);
    };

    initializeCalendar(){
        this.lastDate = this.correctDate(new Date());
        this._createDays(this.lastDate, -15, 15);
        this._loadEvents(this.lastDate, -15, 15);
        this.publishOnReady();
    }

    goToDate(date: Date){
        this.data = [];
        this.lastDate = date;
        this._createDays(date, -15, 15);
        this._loadEvents(date, -15, 15);
        this.publishOnReady();
        this.publishOnGoToDate(date);
    }

    refresh() {
      var date = this.lastDate != null ? this.lastDate : new Date();
      this.goToDate(this.correctDate(date));
    }

    correctDate(date: Date) { // Si no es mobile ni lunes, ajustamos la fecha para que la semana empiece por lunes
      if (isMobile || date.getUTCDay() === 1) {
        return date;
      }
      var correctionDays = (date.getUTCDay() + 6) % 7;
      var newDate = addDays(date, -correctionDays);
      return newDate;
    }

    _createDays(initialDate: Date | null = null, countBefore: number, countAfter: number){
        let baseDate = initialDate ? initialDate : new Date();
        let newDays = [];
        for(let d = countBefore; d < countAfter; d ++){
            let result = new Date(baseDate.getTime());
            result.setDate(result.getDate() + d);

            let day = new DayEvents(result, []);
            if(this.datesAreOnSameDay(result, new Date()))
                day.setCurrent(true);
            if(this.datesAreOnSameDay(result, baseDate))
                day.setSelected(true);
            newDays.push(day);
        }

        if(newDays.length > 0){
            // @ts-ignore
            this.data = this.data.concat(newDays);
            this.data.sort(function(a,b){
                if(a.date === null || b.date === null) return 0;
                if(a.date < b.date) return -1;
                if(a.date > b.date) return 1;
                return 0;
            });
        }
    }

    _loadEvents(initialDate: Date | null = null, countBefore: number, countAfter: number){
        let baseDate = initialDate ? initialDate : new Date();
        //aqui llamaremos a la api par obtener eventos de la fecha inicial 15 dias atras a 15 dias futuro
        let first = new Date(baseDate);
        first.setHours(baseDate.getHours() + (countBefore * 24));
        let last = new Date(baseDate);
        last.setHours(baseDate.getHours() + (countAfter * 24));
        let ticksInit= this.dateToTicks(first);
        let ticksEnd= this.dateToTicks(last);
        this.getEvents(ticksInit, ticksEnd);
    }

    removeWeekEvents(){
        this.weekdayEvents = {};
    }

    addWeekEvent(weekday: WeekDay, initHour: number, endHour: number){
        if(this.weekdayEvents[weekday]){
            this.weekdayEvents[weekday].addEvent(this.weekdayEvents[weekday].events.length, new DayEvent(DayEventType.AVAILABLE, initHour, endHour));
        } else {
            let events: DayEvent[] = [];
            events.push(new DayEvent(DayEventType.AVAILABLE, initHour, endHour));
            this.weekdayEvents[weekday] = new WeekdayEvents(weekday, events);
        }
        this.publishOnRefreshNeeded();
    }

    setRangeHours(start: number = 0, end: number = 24){
        this.startHour = start;
        this.endHour = end;
        this.hours = this.endHour - this.startHour;
        this.rowsCount = (this.hours * 60 / this.hourIntervalInMinutes) + 1;
    }

    setUseEventHours(useEventHours: boolean = false){
        this.useEventHours = useEventHours;
        this.calculateHours();
    }

    switchUseEventHours(){
        this.useEventHours = !this.useEventHours;
        this.calculateHours();
    }

    calculateHours(){
        if(!this.useEventHours){
            this.setRangeHours(0, 24);
            return
        }

        let minHour = null;
        let maxHour = null;
        for(let d=0; d<this.data.length; d++){
            let events = this.data[d].events;
            for(let e=0; e<events.length; e++){
                if(minHour === null || events[e].initTimestamp! < minHour){
                    minHour = events[e].initTimestamp!;
                }
                if(maxHour === null || events[e].endTimestamp! > maxHour){
                    maxHour = events[e].endTimestamp!;
                }
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [_, value] of Object.entries(this.weekdayEvents)) {
            let events = value.events;
            for(let e=0; e<events.length; e++){
                if(minHour === null || events[e].initTimestamp! < minHour){
                    minHour = events[e].initTimestamp!;
                }
                if(maxHour === null || events[e].endTimestamp! > maxHour){
                    maxHour = events[e].endTimestamp!;
                }
            }
        }

        minHour = minHour ? minHour - 0.5 : 0;
        maxHour = maxHour ? maxHour + 0.5 : 24;
        this.setRangeHours(minHour, maxHour);
    }

    getStartHour(){
        return this.startHour;
    }

    getMultiplier(){
        return 60 / this.hourIntervalInMinutes;
    }

    getRowsCount(){
        return this.rowsCount;
    }

    getWeekDayEvents(columnIndex: number){
        let day = this.getDayByIndex(columnIndex);
        if(day){
            return this.weekdayEvents[day.date!.getDay()];
        }
        return null;
    }

    getDaysCount(){
        return this.data.length + 1;
    }

    getIndexCurrentDay(){
        for(let d=0; d < this.data.length; d++){
            if(this.data[d].current){
                return d;
            }
        }
        return 0;
    }

    getIndexTargetDay(){
        let selectedDay = this.getIndexSelectedDay();
        if(selectedDay)
            return selectedDay;
        let currentDay = this.getIndexCurrentDay();
        if(currentDay)
            return currentDay;
        return null;
    }

    getIndexSelectedDay(){
        for(let d=0; d<this.data.length; d++){
            if(this.data[d].selected){
                return d;
            }
        }
        return null;
    }

    getDayByIndex(columnIndex: number){
        return this.data[columnIndex];
    }

    getBeforeEventHour(date: Date){
        let index = this.getIndexByDate(date);
        let hourFilter = this.getTimeStampByDate(date);
        if(!index)
            return;
        let a = this.getDayByIndex(index);
        let lastHour = 0;
        a.events.forEach(function(curr){
            if(!curr.endTimestamp)
                return;

            if(curr.endTimestamp <= hourFilter && curr.endTimestamp > lastHour){
                lastHour = curr.endTimestamp;
            }
        });

        return lastHour;
    }

    getNextEventHour(date: Date){
        let index = this.getIndexByDate(date);
        let hourFilter = this.getTimeStampByDate(date);
        if(!index)
            return;
        let a = this.getDayByIndex(index);
        let lastHour = 25;//TODO Spike, why return 25?
        a.events.forEach(function(curr){
            if(!curr.initTimestamp)
                return;

            if(curr.initTimestamp > hourFilter && curr.initTimestamp < lastHour){
                lastHour = curr.initTimestamp;
            }
        });
        return lastHour;
    }

    getTimeStampByDate(date: Date){
        return date.getHours() + (date.getMinutes() === 30 ? 0.5 : 0);
    }

    getHourByIndex(rowIndex: number){
        let index = (rowIndex - 1) / (this.getMultiplier()) + this.startHour;
        let date = new Date(0);
        date.setSeconds(index * 60 * 60);
        return date.toISOString().substr(11, 5);
    }

    getHourDateByIndex(rowIndex: number){
        let index = (rowIndex - 1) / (this.getMultiplier()) + this.startHour;
        let date = new Date(0);
        date.setHours(0);
        date.setSeconds(index * 60 * 60);
        return date;
    }

    deleteEvent(dateTime: Date, id: string){
        let dayIndex = this.getIndexByDate(dateTime)!;
        let day = this.getDayByIndex(dayIndex);
        day.deleteEvent(id);
    }

    // todo hacer privado
    createEventByDate(dateTime: Date, duration: number = 0.5, eventType : DayEventType, title: string){
        let finish = false;
        let date = new Date(dateTime);
        while(!finish){
            let dayIndex = this.getIndexByDateNoLoad(date)!;

            if(dayIndex){
                let day = this.getDayByIndex(dayIndex);
                let hourDecimal = date.getHours() + date.getMinutes() / 60 + date.getSeconds() / 3600;
                let index = hourDecimal - this.startHour;
                let dur = (index + duration) > 24 ? 24 : (index + duration);
                if (!day.existsEvent(eventType, index, dur))
                    day.addEvent(index, new DayEvent(eventType, index, dur , "", duration/24, title));
            }
            duration -= 24 - date.getHours() - (date.getMinutes() === 30 ? 0.5 : 0);
            finish = duration <= 0 /*|| dayIndex === 29*/;

            if(!finish){
                date.setDate(date.getDate() + 1);
                date.setHours(0, 0, 0, 0);
            }
                
        }
    }

    // Duration in hours
    createEvent(columnIndex: number, rowIndex: number, duration: number = 0.5){
        let finish = false;
        while(!finish){
            let day = this.getDayByIndex(columnIndex);
            let index = (rowIndex - 1) / (this.getMultiplier()) + this.startHour;
            day.addEvent(index, new DayEvent(DayEventType.BOOKING, index, (index + duration) > 24 ? 24 : (index + duration)));
            duration -= 24;
            columnIndex++;
            finish = duration <= 0;
        }
        console.log('create event')
        // this.publishOnRefreshNeeded();
    }

    loadDataAfter(){
        let lastDate = this.data[this.data.length - 1].date!;
        setTimeout(()=>{
            this._createDays(lastDate, 1, 15);
            this.publishOnDateLoadedAfter(15);
            this._loadEvents(lastDate, 1, 15);
        }, 1000);
    }

    loadDataBefore(){
        let firstDate = this.data[0].date!;
        setTimeout(()=>{
            this._createDays(firstDate, -15, -1);
            this.publishOnDateLoadedBefore(15);
            this._loadEvents(firstDate, -15, -1);
        }, 1000);
    }

    goToCurrentDate(){
        this.publishOnGoToDate(new Date());
    }

    getIndexByDate(date: Date){
        //TODO ACTIVARLO EN UN FUTURO PARA OPTIMIZAR
        // Buscamos si tenemos el dia ya cargado
        let index = this.getIndexByDateNoLoad(date);

        if(index)
            return index;
        // Si no tenemos el dia cargado hay que cargarlo
        // this.loadData(date);
        return null;
    }

    getIndexByDateNoLoad(date: Date){
        let index = null;
        for(let d=0; d<this.data.length; d++){
            let dayDate = this.data[d].date!;
            if(this.datesAreOnSameDay(date, dayDate)){
                index = d;
            }
        }
        return index;
    }

    datesAreOnSameDay(first: Date, second: Date){
        return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
    }

    selectedDate(columnIndex: number, rowIndex: number){
        let day = this.getDayByIndex(columnIndex);
        let index = (rowIndex - 1) / (this.getMultiplier()) + this.startHour;

        let date = new Date(day.date!)
        date.setHours(Math.trunc(index));
        date.setMinutes((index % 1).toFixed(1) === (0.5).toString() ? 30 : 0);
        //date.setSeconds((index * 60 * 60));
        date.setSeconds(0);

        this.publishOnSelectDate(date);
    }

    selectedDateByDate(date: Date){
        this.publishOnSelectDate(date);
    }

    selectEvent(date: Date, event: DayEvent){
        this.publishOnSelectEvent(date, event);
    }

    selectDateDelete(date: Date, duration: number, event: DayEvent){
        this.publishOnSelectDateDelete(date, duration, event);
    }

    getHalfHours(){
        let hours: { text : string, value : number }[] = [];
        for(let x=0;x<24;x++) {
            hours.push({text : x + ":00", value: x});
            hours.push({text : x + ":30", value: x + 0.5});
        }
        return hours;
    }

    lastDate: Date | null = null;
    setVisibleDate(date: Date){
        if(this.lastDate?.toDateString() === date.toDateString())
            return;
        this.lastDate = date;
        this.publishOnChangedVisibleDate(date);
    }

    getWeekNumber(date: Date){
        return moment(date).week();
    }

    getDateFormatted(date: Date, format: string){
        const calendar_parent = document.getElementById("calendar1");
        let language = calendar_parent != null ? calendar_parent.getAttribute("data-language") : "en";
        
        moment.locale(language!);
        
        return moment(date).format(format);
    }

    getDatesFormatted(dates: Date[], format: string){
        let datesStr: string[] = [];
        for(let x=0;x < dates.length;x++) {
            datesStr.push(this.getDateFormatted(dates[x], format));
        }

        return datesStr;
    }

    addEvents(tzo: number, ticksInit : number, ticksEnd: number, type: DayEventType, diff: number, date: Date){
        let that = this;
        API.addPoints(tzo, ticksInit, ticksEnd, type).then(() => {
            that.publishOnEventsAdded();
            this.createEventByDate(date!, diff, type, "");
            this.publishOnRefreshNeeded();
        }).catch(error => {
            //todo
        })
    }

    ticksToDate(ticks: number){
        return new Date((ticks - 621355968000000000) / 10000);
    }

    dateToPoint(hours:number, minutes:number, day: number){
        return (hours * 12) + (minutes / 5) + (288 * day);
    }

    getEvents(ticksInit : number, ticksEnd: number){
        let that = this;
        API.getPoints(ticksInit, ticksEnd).then((json: any) => {
            let list = json["Point"];
            list.forEach(function (c: any) {
                let date = that.ticksToDate(c.Ticks);
                that.createEventByDate(date, c.Duration / 60, c.Type, c.Title);
            });
            this.calculateHours();
            this.publishOnRefreshNeeded();
            // this.publishOnReady();
        }).catch(error => {
            //todo
        })
    }

    deleteEvents(ticksInit : number, ticksEnd: number){
        let that = this;
        API.deletePoints(ticksInit, ticksEnd).then(() => {
            that.publishOnEventsDelete();
        }).catch(error => {
            //todo
        })
    }

    updateEvent(ticksInit : number, newTicks: number, event: DayEvent, date: Date, diff: number){
        let that = this;
        let durationMins = diff * 60;
        API.setPoint(ticksInit, event.eventType, durationMins, newTicks).then((json : any) => {
            that.deleteEvent(date!, event!.id);
            that.createEventByDate(date!, diff, event.eventType, "");
            that.publishOnRefreshNeeded();
            that.publishOnEventSet();
        }).catch(error => {
            //todo
        })
    }

    getHourFormatted(date: Date){
        return  date.toISOString().substr(11, 5);
    }

    calculateDefaultDiff(date: Date){
        var currentTimeStampDate = this.getTimeStampByDate(date);
        var nextHourTimeStampAvaiable = this.getNextEventHour(date);
        var maxDiff = nextHourTimeStampAvaiable! - currentTimeStampDate;
        return maxDiff < 1 ? maxDiff : 1;
    }

    protected publishOnDateLoadedAfter(daysAdded: number | null = null){
        this.subscribers.forEach(function (listener) { (listener as CalendarListener).onDataLoadedAfter?.(daysAdded); })
    }

    protected publishOnEventsAdded (){
        this.subscribers.forEach(function (listener) { (listener as CalendarListener).onEventsAdded?.(); })
    }

    protected publishOnEventsDelete (){
        this.subscribers.forEach(function (listener) { (listener as CalendarListener).onEventsDeleted?.(); })
    }

    protected publishOnEventSet (){
        this.subscribers.forEach(function (listener) { (listener as CalendarListener).onEventSet?.(); })
    }

    protected publishOnDateLoadedBefore(daysAdded: number | null = null){
        this.subscribers.forEach(function (listener) { (listener as CalendarListener).onDataLoadedBefore?.(daysAdded); })
    }

    protected publishOnGoToDate(date: Date){
        this.subscribers.forEach(function (listener) { (listener as CalendarListener).onGoToDate?.(date); })
    }

    protected publishOnSelectDate(date: Date){
        this.subscribers.forEach(function (listener) { (listener as CalendarListener).onSelectDate?.(date); })
    }

    protected publishOnSelectEvent(date: Date, event: DayEvent){
        this.subscribers.forEach(function (listener) { (listener as CalendarListener).onSelectEvent?.(date, event); })
    }

    protected publishOnSelectDateDelete(date: Date, duration: number, event: DayEvent){
        this.subscribers.forEach(function (listener) { (listener as CalendarListener).onSelectDateDelete?.(date, duration, event); })
    }

    protected publishOnChangedVisibleDate(date: Date){
        this.subscribers.forEach(function (listener) { (listener as CalendarListener).onChangedVisibleDate?.(date); })
    }

    protected publishOnReady(){
        this.subscribers.forEach(function (listener) { (listener as CalendarListener).onReady?.(); })
    }

    public publishOnRefreshNeeded(){
        this.subscribers.forEach(function (listener) { (listener as CalendarListener).onRefreshNeeded?.(); })
    }
}

export interface CalendarListener extends ParentServiceListener{
    onDataLoadedAfter?(daysAdded: number | null): void;
    onEventsAdded?(): void;
    onEventsDeleted?(): void;
    onEventSet?(): void;
    onDataLoadedBefore?(daysAdded: number | null): void;
    onGoToDate?(date: Date): void;
    onSelectDate?(date: Date): void;
    onSelectEvent?(date: Date, dayEvent: DayEvent): void;
    onSelectDateDelete?(date: Date, duration: number, dayEvent: DayEvent): void;
    onChangedVisibleDate?(date: Date): void;
    onReady?():void;
    onRefreshNeeded?():void;
}