export const classgapTheme = {
  name: "classgap",
  fonts: ["sans-serif", "Roboto"],
  backgroundColors: {
    primary: "#FFFFFF", // Navbar - Bottombar
    accent: "#3AAC8B",
    secondary: "#F5F6FA", // Background content
    tertiary: "#222", // Backgroung video
    quaternary: "#E3E3E3", // tabs
    alphaSheet: "rgba(59,50,43,0.6)",
    danger: "#DE4D4D",
  },
  calendar: {
    cellColors: {
      hour: {
        background: "white",
        text: "#2A3E55",
        border: "1px solid transparent",
      },
      day: {
        background: "",
        text: "#2A3E55",
        border: "1px solid transparent",
      },
      base: {
        background: "white",
        text: "",
        border: "",
      },
      available: {
        background: "#D7F4E2",
        text: "#2A3E55",
        border: "1px solid #3AAC8B",
      },
      availableHover: {
        background: "#3AAC8B",
        text: "#fff",
        border: "none",
      },
      notAvailable: {
        background: "#D3D9DE",
        text: "#2A3E55",
        border: "1px solid rgba(42, 62, 85, 0.2)",
      },
      blocked: {
        background: "white",
        text: "#2A3E55",
        border: "1px solid #EDEFF1",
      },
      booking: {
        background: "#D3D9DE",
        text: "#2A3E55",
        border: "1px solid #D3D9DE",
      },
    },
    currentDayColors: {
      cell_background: "#EFFAF3",
      cell_text: "#2A3E55",
      title_text: "#3AAC8B",
      circle_background: "#3AAC8B",
      circle_text: "white",
    },
    dayColors: {
      background: "",
      text: "",
      circle_background: "",
      circle_text: "",
    },
  },
  smallCalendar: {
    dayColors: {
      hover: "#eef2f7",
    },
  },
  buttonColors: {
    primary: {
      selected: {
        main: {
          text: "#3AAC8B",
          icon: "#3AAC8B",
          background: "#FFFFFF",
          border: "#3AAC8B",
        },
        hover: {
          text: "#3AAC8B",
          icon: "#3AAC8B",
          background: "rgba(58, 172, 139, 0.1)",
          border: "#3AAC8B",
        },
      },
      unselected: {
        main: {
          text: "#FFFFFF",
          icon: "#FFFFFF",
          background: "#DE4D4D",
          border: "#DE4D4D",
        },
        hover: {
          text: "#FFFFFF",
          icon: "#FFFFFF",
          background: "#A42929",
          border: "#A42929",
        },
      },
    },
    secondary: {
      selected: {
        main: {
          text: "#2A3E55",
          icon: "#3AAC8B",
          background: "#FFFFFF",
          border: "#FFFFFF",
        },
        hover: {
          text: "#2A3E55",
          icon: "#3AAC8B",
          background: "#FFFFFF",
          border: "#3AAC8B",
        },
      },
      unselected: {
        main: {
          text: "#2A3E55",
          icon: "#2A3E55",
          background: "#FFFFFF",
          border: "#2A3E55",
        },
        hover: {
          text: "#2A3E55",
          icon: "#3AAC8B",
          background: "#FFFFFF",
          border: "#3AAC8B",
        },
      },
    },
    accent: {
      selected: {
        main: {
          text: "#FFFFFF",
          icon: "#FFFFFF",
          background: "#3AAC8B",
          border: "#3AAC8B",
        },
        hover: {
          text: "#FFFFFF",
          icon: "#FFFFFF",
          background: "#27725C",
          border: "#27725C",
        },
      },
      unselected: {
        main: {
          text: "#FFFFFF",
          icon: "#FFFFFF",
          background: "#3AAC8B",
          border: "#3AAC8B",
        },
        hover: {
          text: "#FFFFFF",
          icon: "#FFFFFF",
          background: "#2B8068",
          border: "#2B8068",
        },
      },
      disabled: {
        main: {
          text: "#FFF",
          icon: "#FFF",
          background: "#A8B3BD",
          border: "#A8B3BD",
        },
        hover: {
          text: "#FFF",
          icon: "#FFF",
          background: "#A8B3BD",
          border: "#A8B3BD",
        },
      },
    },
  },
  // El color de la fuente también se usa para el color de los iconos
  fontColors: {
    primary: "#2A3E55",
    accent: "#3AAC8B",
    accentDark: "#2B8068",
    secondary: "#58595B",
    tertiary: "#979797",
    quaternary: "#FFF",
    danger: "#DE4D4D",
    disabled: "#A8B3BD",
    soft: "#ECECEC",
  },
  fontSizes: {
    xxs: "0.625rem",
    xs: "0.75rem",
    s: "1rem",
    m: "1.125rem",
    l: "1.25rem",
    xl: "1.5rem",
    xxl: "2rem",
  },
  toolTip: {
    background: "#2A3E55",
    text: "#FFFFFF",
  },
  regularschedule: {
    day: {
      color: "#2A3F55",
      selected: {
        color: "#2A3F55",
        background: "#ECF8F5",
      },
    },
  },
  squareAvailable: {
    backgroundColor: "#D7F4E2",
    borderRadius: "0px",
    borderColor: "#3AAC8B",
  },
  squareNotAvailable: {
    backgroundColor: "#D3D9DE",
    borderRadius: "0px",
    borderColor: "#2A3E55",
  },
  rangeStart: {
    background: "#D7F4E2",
    abbr: {
      backgroundColor: "#D7F4E2",
    },
  },
  rangeEnd: {
    background: "#D7F4E2",
  },
  MuiSwitchTrack: {
    color: "#3AAC8B",
  },
  borderRadius: {
    div: "1.875em",
  },
  toggle: {
    active: {
      background: "#3AAC8B",
    },
  },
  arrow: {
    color: "rgb(58, 172, 139)",
  },
  iconWrapper: {
    calendar: {
      svg: {
        color: "#2A3E55",
        hoverColor: "#3AAC8B",
      },
    },
    absence: {
      svg: {
        color: "rgb(215, 244, 226)",
        hoverColor: "rgb(215, 244, 226)",
      },
    },
  },
  iconAdd: {
    color: "rgb(58, 172, 139)",
  },
  activeAbsencesHeader: {
    background: "#ECF8F5",
  },
};

export const tusTheme = {
  name: "tus",
  fonts: ["sans-serif", "Roboto"],
  backgroundColors: {
    primary: "#FFFFFF", // Navbar - Bottombar
    accent: "#0B2FAC",
    secondary: "#F5F6FA", // Background content
    tertiary: "#222", // Backgroung video
    quaternary: "#E3E3E3", // tabs
    alphaSheet: "rgba(59,50,43,0.6)",
    danger: "#DE4D4D",
  },
  calendar: {
    cellColors: {
      hour: {
        background: "white",
        text: "#2A3E55",
        border: "1px solid transparent",
      },
      day: {
        background: "",
        text: "#2A3E55",
        border: "1px solid transparent",
      },
      base: {
        background: "white",
        text: "",
        border: "",
      },
      available: {
        background: "#C5E9D5",
        text: "#2A3E55",
        border: "1px solid #4A9A6F",
      },
      availableHover: {
        background: "#9EDABA",
        text: "#fff",
        border: "none",
      },
      notAvailable: {
        background: "#D3D9DE",
        text: "#2A3E55",
        border: "1px solid rgba(42, 62, 85, 0.2)",
      },
      blocked: {
        background: "white",
        text: "#2A3E55",
        border: "1px solid #EDEFF1",
      },
      booking: {
        background: "#D3D9DE",
        text: "#2A3E55",
        border: "1px solid #D3D9DE",
      },
    },
    currentDayColors: {
      cell_background: "#EFFAF3",
      cell_text: "#2A3E55",
      title_text: "#0B2FAC",
      circle_background: "#0B2FAC",
      circle_text: "white",
    },
    dayColors: {
      background: "",
      text: "",
      circle_background: "",
      circle_text: "",
    },
  },
  smallCalendar: {
    dayColors: {
      hover: "#eef2f7",
    },
  },
  buttonColors: {
    primary: {
      selected: {
        main: {
          text: "#0B2FAC",
          icon: "#0B2FAC",
          background: "#FFFFFF",
          border: "#0B2FAC",
        },
        hover: {
          text: "#0B2FAC",
          icon: "#0B2FAC",               
        },
      },
      unselected: {
        main: {
          text: "#FFFFFF",
          icon: "#FFFFFF",
          background: "#DE4D4D",
          border: "#DE4D4D",
        },
        hover: {
          text: "#FFFFFF",
          icon: "#FFFFFF",
          background: "#A42929",
          border: "#A42929",
        },
      },
    },
    secondary: {
      selected: {
        main: {
          text: "#2A3E55",
          icon: "#0B2FAC",
          background: "#FFFFFF",
          border: "#FFFFFF",
        },
        hover: {
          text: "#2A3E55",
          icon: "#0B2FAC",
          background: "#FFFFFF",
          border: "#0B2FAC",
        },
      },
      unselected: {
        main: {
          text: "#2A3E55",
          icon: "#2A3E55",
          background: "#FFFFFF",
          border: "#2A3E55",
        },
        hover: {
          text: "#2A3E55",
          icon: "#0B2FAC",
          background: "#FFFFFF",
          border: "#0B2FAC",
        },
      },
    },
    accent: {
      selected: {
        main: {
          text: "#FFFFFF",
          icon: "#FFFFFF",
          background: "#0B2FAC",
          border: "#0B2FAC",
        },
        hover: {
          text: "#FFFFFF",
          icon: "#FFFFFF",       
        },
      },
      unselected: {
        main: {
          text: "#FFFFFF",
          icon: "#FFFFFF",
          background: "#0B2FAC",
          border: "#0B2FAC",
        },
        hover: {
          text: "#FFFFFF",
          icon: "#FFFFFF",                    
        },
      },
      disabled: {
        main: {
          text: "#FFF",
          icon: "#FFF",
          background: "#A8B3BD",
          border: "#A8B3BD",
        },
        hover: {
          text: "#FFF",
          icon: "#FFF",
          background: "#A8B3BD",
          border: "#A8B3BD",
        },
      },
    },
  },
  // El color de la fuente también se usa para el color de los iconos
  fontColors: {
    primary: "#020922",
    accent: "#0B2FAC",
    accentDark: "#2B8068",
    secondary: "#58595B",
    tertiary: "#979797",
    quaternary: "#FFF",
    danger: "#DE4D4D",
    disabled: "#A8B3BD",
    soft: "#ECECEC",
  },
  fontSizes: {
    xxs: "0.625rem",
    xs: "0.75rem",
    s: "1rem",
    m: "1.125rem",
    l: "1.25rem",
    xl: "1.5rem",
    xxl: "2rem",
  },
  toolTip: {
    background: "#2A3E55",
    text: "#FFFFFF",
  },
  regularschedule: {
    day: {
      color: "#2A3F55",
      selected: {
        color: "#F4F6FB",
        background: "#0B2FAC",
      },
    },
  },
  squareAvailable: {
    backgroundColor: "#C5E9D5",
    borderRadius: "5px",
    borderColor: "#4A9A6F",
  },
  squareNotAvailable: {
    backgroundColor: "#DADBE5",
    borderRadius: "5px",
    borderColor: "#555978",
  },
  rangeStart: {
    background: "#E3E8F6",
    abbr: {
      backgroundColor: "#E3E8F6",
    },
  },
  rangeEnd: {
    background: "#E3E8F6",
  },
  MuiSwitchTrack: {
    color: "#0B2FAC",
  },
  borderRadius: {
    div: "8px",
  },
  toggle: {
    active: {
      background: "#0B2FAC",
    },
  },
  arrow: {
    color: "#0B2FAC",
  },
  iconWrapper: {
    calendar: {
      svg: {
        color: "#0B2FAC",
        hoverColor: "#0B2FAC",
      },
    },
    absence: {
      svg: {
        color: "#0B2FAC",
        hoverColor: "#0B2FAC",
      },
    },
  },
  iconAdd: {
    color: "#0B2FAC",
  },
  activeAbsencesHeader: {
    background: "#E3E8F6",
  },
};
