import ParentService, {ParentServiceListener} from "../parent";
import 'moment/locale/es';
import API from "../api/api";
import { Absence } from "../../types/absence";
import { AbsenceStatus } from "../../types/enums/absence";

export class AbsenceService extends ParentService{

    private absences : Absence[] = [];

    getAbsences(force : boolean){
        let that = this;
        if(this.absences.length && force)
        {
            that.publishOnAbsencesLoaded(this.absences);
            return this.absences;
        }
        else{
            this.absences=[];
        }

        API.getAbsences().then((json: any) => {
            let list: Absence[] = [];
            let absences = json["Absences"];
            absences.forEach(function(obj: any) {
                list.push(Absence.parse(obj)!);
            });
            that.publishOnAbsencesLoaded(list);
        }).catch(error => {
            //todo
        })
    }

    ticksToTdate(ticks: number){
        return new Date((ticks - 621355968000000000) / 10000);
    }

    addAbsence(title: string, startTicks : number, endTicks : number, tzo: number ){
        let that = this;

        API.addAbsence(title, startTicks, endTicks, tzo).then((json: any) => {
            let id: string = json["data"];
            
            let initDate = this.ticksToTdate(startTicks);
            let endDate = this.ticksToTdate(endTicks);
            let abs: Absence = new Absence(id, AbsenceStatus.ACTIVE, new Date(), 0, title, initDate, endDate);
            this.absences.push(abs);
            that.publishOnAbsenceAdded(id);
        }).catch(error => {
            //todo
        })
    }

    editAbsence(id: string, title: string, startTicks : number, endTicks : number ){
        let that = this;

        API.editAbsence(id, title, startTicks, endTicks).then((json: any) => {
            that.publishOnAbsenceEdited();
            this.absences = this.absences.filter(x=> x.id!== id);
        }).catch(error => {
            //todo
        })
    }

    deleteAbsence(id: string){
        let that = this;

        API.deleteAbsence(id).then((json: any) => {
            that.publishOnAbsenceDeleted();
        }).catch(error => {
            //todo
        })
    }

    /** @ignore Publish documents loaded event to all elements subscribed. */
    protected publishOnAbsencesLoaded(documents: Absence[]){
        this.subscribers.forEach(function (listener) { (listener as AbsencesEventListener).onAbsencesLoaded?.(documents); })
    }

    /** @ignore Publish documents loaded event to all elements subscribed. */
    protected publishOnAbsenceAdded(id: string){
        this.subscribers.forEach(function (listener) { (listener as AbsencesEventListener).onAbsenceAdded?.(id); })
    }

    /** @ignore Publish absence edit event to all elements subscribed. */
    protected publishOnAbsenceEdited(){
        this.subscribers.forEach(function (listener) { (listener as AbsencesEventListener).onAbsenceEdited?.(); })
    }
    
    /** @ignore Publish documents loaded event to all elements subscribed. */
    protected publishOnAbsenceDeleted(){
        this.subscribers.forEach(function (listener) { (listener as AbsencesEventListener).onAbsenceDeleted?.(); })
    }
}

export interface AbsencesEventListener extends ParentServiceListener{
    
    /** Notifies when absences has been loaded. */
    onAbsencesLoaded?(documents: Absence[]): void;

    /** Notifies when absence has been added. */
    onAbsenceAdded?(id: string): void;

    /** Notifies when absence has been edited. */
    onAbsenceEdited?(): void;

    /** Notifies when absence has been deleteds. */
    onAbsenceDeleted?(): void;
}
